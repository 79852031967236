<template>
  <Bar id="my-chart-id" :options="chartOptions" :data="chartData" />
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import autocolors from "chartjs-plugin-autocolors";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: { Bar },
  computed: {
    chartData() {
      let data = [];
      if (this.$store.state.selectedFeaturedProject && this.$store.state.selectedFeaturedProject.stats) {
         data = Object.values(this.$store.state.selectedFeaturedProject.stats.plantationDate);
         console.log(data);
      } else {
         data = this.$store.state.fetchedSpecimensByPlantationDate;
      }
      const labelsSpecimensByPlantationDate = [];
      const dataSpecimensByPlantationDate = [];
      console.log(data);
      if (!data || !data.length){
        data = [{
        "amount": 12345,
        "year": "année inconnue"
    }]
      }
      data.forEach((item) => {
        if (item.year != "année inconnue") {
          labelsSpecimensByPlantationDate.push(item.year);
          dataSpecimensByPlantationDate.push(item.amount);
        }
      });
      return /* mutable chart data */ {
        labels: labelsSpecimensByPlantationDate,
        datasets: [
          {
            data: dataSpecimensByPlantationDate,
            label: "Quantité",
            backgroundColor: "#00967d",
          },
        ],
      };
    },
    chartOptions() {
      // Use matchMedia to check the user preference
      const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
      ChartJS.defaults.color = prefersDark.matches ? "white" : "black";
      return {
        responsive: true,
      };
    },
  }
};
</script>
