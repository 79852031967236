<template>
  <!--   <ion-toolbar v-if="$store.state.formattedSpecimen" class="specimen-details-border-right specimen-details-border-top"
    style="height:100px">
   
    <ion-button fill="clear" @click="closeSpecimenDetails()" class="side-menu-close-button">
      <ion-icon :icon="closeCircle"></ion-icon>
    </ion-button>
  </ion-toolbar> -->

  <ion-content>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="closeModal">FERMER</ion-button>
        </ion-buttons>
        <ion-title>{{ $store.state.formattedSpecimen._id }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-card>
      <ion-item v-if="currentReport.author_uid == $store.state.user.uid">
        <!-- <ion-button @click="setOpen(true)" fill="outline" slot="start">
          <ion-icon :icon="shareSocialOutline"></ion-icon>
        </ion-button> -->
        <ion-button fill="outline" @click="confirmRemoveReport(currentReport)" slot="start" color="danger">
          <ion-icon :icon="trash"></ion-icon>
        </ion-button>
      </ion-item>
      <div v-for="(photo, i) in currentReport.photos" :key="photo">
        <ion-button v-if="currentReport.photos.length > 0 && currentReport.author_uid == $store.state.user.uid"
          @click="confirmDeletePicture(photo, currentReport, i)" class="remove-picture-icon" fill="clear"
          color="danger">
          <ion-icon :icon="removeCircle"></ion-icon>
        </ion-button>
        <img :src="'https://firebasestorage.googleapis.com/v0/b/soverdiwebsite.appspot.com/o/specimens%2F' +
            photo._id +
            '?alt=media'
            " />
      </div>

      <ion-icon :icon="warning" size="large" color="warning" style="
          float: left;
          height: 48px;
          width: 48px;
          padding: 8px;
          padding-top: 16px;
        "></ion-icon>

      <ion-card-header>
        <ion-card-title> {{ currentReport.report }} </ion-card-title>
        <ion-card-subtitle>par {{ currentReport.author_name }}</ion-card-subtitle>
      </ion-card-header>

      <ion-card-content class="ion-text-wrap">Signalement effectué le
        <b>{{
            new Date(currentReport.dateDone.seconds * 1000).toLocaleString('fr-CA', {
              year: "numeric",
              month: "long",
              day: "numeric",
            }).slice(0, 25)
          }}</b>
      </ion-card-content>
      <ion-action-sheet :is-open="isOpenRef" header="Partager" css-class="my-custom-class" :buttons="buttons"
        @didDismiss="setOpen(false)">
      </ion-action-sheet>
    </ion-card>
    <ion-item>
      <ion-label>Ajoutez une photo</ion-label>
      <ion-icon class="ion-padding" :icon="camera"
        @click="takePhoto('reports', 'Reports', currentReport.report_id)"></ion-icon>
    </ion-item>
    <!--  <ion-img v-for="photo in currentReport.photos" :key="photo" :src="'https://firebasestorage.googleapis.com/v0/b/soverdiwebsite.appspot.com/o/specimens%2F' +
    photo._id +
    '?alt=media'" @click="showImgModal = !showImgModal; chosenPhoto = photo;">
    </ion-img> -->
  </ion-content>
</template>

<script>
import firebaseService from "@/services/firebaseService";
import mapService from "@/services/mapService";
import {
  IonContent,
  IonIcon,
  IonButton,
  IonToolbar,
  IonHeader,
  IonItem,
  IonLabel,
  IonTitle,
  IonActionSheet,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  modalController,
  alertController,
} from "@ionic/vue";
import { defineComponent, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { usePhoto } from "@/composables/usePhoto";
import {
  closeCircle,
  camera,
  warning,
  shareSocialOutline,
  logoFacebook,
  logoInstagram,
  removeCircle,
  trash,
  leaf,
} from "ionicons/icons";
export default defineComponent({
  name: "ReportDetails",
  props: ["addOrEdit"],
  setup(props) {
    //console.log("ReportDetails");

    const { errors, loading } = firebaseService();
    const store = useStore();
    const specimen = ref();
    const firestoreId = ref();
    const labelPositioning = ref("floating");
    const editCurrentReport = ref(false);
    const modifyLike = () => {
      // copy current like
      /*    tempLike.title = JSON.parse(JSON.stringify(currentReport.value.title));
      tempLike.description = JSON.parse(JSON.stringify(currentReport.value.description));
    //console.log(tempLike); */
      editCurrentReport.value = true;
    };
    const cancelModifyLike = () => {
      /*       currentReport.value.title = tempLike.title;
      currentReport.value.description = tempLike.description; */
      editCurrentReport.value = false;
    };
    const { takePhoto, photos, uploading, deletePicture, savePicture } =
      usePhoto();

    const testBreakPoint = () => {
      const modalRef = document.querySelector(
        ".modal-content-specimen-details"
      );
      //console.log(modalRef);
      modalRef.setCurrentBreakpoint(0.9);
    };
    const closeModal = async () => {
      if (editCurrentReport.value) {
        //console.log("cancelling modify");
        cancelModifyLike();
      }
      await modalController.dismiss();
    };
    const confirmDeletePicture = async (chosenPhoto, currentReport, i) => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Souhaitez-vous vraiment effacer cette image?",
        message: "Cette action est irréversible!!!",
        buttons: [
          {
            text: "Annuler",
            role: "cancel",
            cssClass: "secondary",
            id: "cancel-button",
            handler: () => {
              //console.log("Confirm Cancel:", blah);
            },
          },
          {
            text: "Effacer",
            id: "confirm-button",
            handler: () => {
              //console.log("Confirm Okay");
              deletePicture(chosenPhoto);
              // updating data
              currentReport.photos.splice(i, 1);
              // save on remote
              finishSaving();
              //  showImgModal.value = false;
            },
          },
        ],
      });
      return alert.present();
    };
    const confirmRemoveReport = async (report) => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Souhaitez-vous vraiment effacer ce signalement?",
        message: "Cette action est irréversible!!!",
        buttons: [
          {
            text: "Annuler",
            role: "cancel",
            cssClass: "secondary",
            id: "cancel-button",
            handler: () => {
              //console.log("Confirm Cancel:", blah);
            },
          },
          {
            text: "Effacer",
            id: "confirm-button",
            handler: () => {
              //console.log("Confirm Okay");
              if (
                store.state.formattedSpecimen.groupSpecific.collaborative
                  .reports[report.report_id].photos.length > 0
              ) {
                // remove pictures from storage
                store.state.formattedSpecimen.groupSpecific.collaborative.reports[
                  report.report_id
                ].photos.forEach((picture) => {
                  deletePicture(picture);
                });
              }
              // updating data
              delete store.state.formattedSpecimen.groupSpecific.collaborative
                .reports[report.report_id];
              // check if we need to remove a collaborative icon from the marker
              /*    for (let index = 0; index < store.state.reportsInSelection.length; index++) {
                    const element = store.state.reportsInSelection[index];
                    if (element.properties.specimen_id == store.state.formattedSpecimen._id) {
                      store.state.reportsInSelection.splice(index,1);
                      break;
                    }
                  } */

              if (
                Object.keys(
                  store.state.formattedSpecimen.groupSpecific.collaborative
                    .reports
                ).length < 1
              ) {
                // remove collaborative icon from the marker
                for (
                  let index = 0;
                  index < store.state.collaborativeDataQueryObj.length;
                  index++
                ) {
                  const collaborativeData =
                    store.state.collaborativeDataQueryObj[index];
                  if (
                    collaborativeData.specimen_id ==
                    store.state.fetchedSpecimen.geojsonFeature.properties
                      ._id &&
                    collaborativeData.has_reports
                  ) {
                    if (!collaborativeData.reports) {
                      collaborativeData.has_reports = false;
                      break;
                    } else if (
                      Object.keys(collaborativeData.reports).length < 2
                    ) {
                      collaborativeData.has_reports = false;
                      break;
                    }
                  }
                }
              }
              /*             let collaborativeTypeCounter = 0;
              store.state.collaborativeDataQueryObj.forEach(
                (collaborativeData, i) => {
                  if (
                    collaborativeData.specimen_id ==
                      store.state.fetchedSpecimen.geojsonFeature.properties
                        ._id &&
                    collaborativeData.has_reports
                  ) {
                    collaborativeTypeCounter++;
                    if (collaborativeTypeCounter < 2) {
                      // there was no or only one like. Set has_likes to false
                      collaborativeData.has_reports = false;
                    }
                  }
                }
              ); */
              mapService.addCollaborativeIcons();
              // save specimen on remote
              finishSaving();
              //  showImgModal.value = false;
            },
          },
        ],
      });
      return alert.present();
    };
    const isOpenRef = ref(false);
    const setOpen = (state) => (isOpenRef.value = state);
    const buttons = [
      {
        text: "Facebook",
        icon: logoFacebook,
        data: {
          type: "delete",
        },
        handler: () => {
          //console.log('Delete clicked')
        },
      },
      {
        text: "Instagram",
        icon: logoInstagram,
        data: 10,
        handler: () => {
          //console.log('Share clicked')
        },
      },
      {
        text: "Cancel",
        icon: closeCircle,
        role: "cancel",
        handler: () => {
          //console.log('Cancel clicked')
        },
      },
    ];

    const currentReport = ref({
      author_email: store.state.user.email,
      author_uid: store.state.user.uid,
      author_name: store.state.user.displayName,
      dateDone: new Date(),
      description: "",
      title: "",
      photos: [],
      like_id: store.state.user.uid + "_" + new Date().getMilliseconds(),
    });

    function finishSaving() {
      store
        .dispatch("formatSpecimenForServer", {
          specimen: store.state.formattedSpecimen,
          collaborativeForm: true,
        })
        .then(function () {
          //console.log("specimen  saved on server");
          //console.log(result);
          //    loader.hide();
          store.commit("setRepositionSpecimen", false);
          closeModal();
        })
        .catch(function (error) {
          alert("La sauvegarde du spécimen a échouée.  Veuillez réessayer plus tard.");
          //     loader.hide();
          //console.error("Error writing firestore document: ", error);
        });
    }

    /*     const addReport = () => {
    //console.log("adding report");
      const newLikeKeyname = currentReport.value.like_id;
      if (!store.state.formattedSpecimen.groupSpecific.collaborative.likes) {
        store.state.formattedSpecimen.groupSpecific.collaborative.likes = {};
        store.state.formattedSpecimen.groupSpecific.collaborative.likes[newLikeKeyname] = currentReport.value;
      } else {
        store.state.formattedSpecimen.groupSpecific.collaborative.likes[newLikeKeyname] = currentReport.value;
      }
      finishSaving();
    };
 */

    onMounted(() => {
      //console.log("onMounted");
      const addOrEdit = props.addOrEdit;
      //console.log(addOrEdit);
      if (addOrEdit == "edit") {
        //console.log('salut');
        //console.log('allo' + store.state.reportDetails);
        currentReport.value = store.state.reportDetails;
        labelPositioning.value = "stacked";
      } else {
        editCurrentReport.value = true;
      }
      /*   setTimeout(()=>{specimenDetailsContentElement =
          document.getElementById("sideMenuSpecimen");
        //console.log(specimenDetailsContentElement.offsetHeight);
          store.commit("setSpecimenDetailsContentElementHeight", specimenDetailsContentElement.offsetHeight);
  },500) */
    });
    // //console.log(route.params.firestoreId);
    /* watch(
      () => store.state.chosenSpecimenMarkerFirestoreId,
      () => {
        //console.log(
          "watch store.state.chosenSpecimenMarkerFirestoreId changed"
        );
        firestoreId.value = store.state.chosenSpecimenMarkerFirestoreId;
        specimen.value = computed(
          () =>
            store.state.fetchedSpecimensObj[
              store.state.chosenSpecimenMarkerFirestoreId
            ]
        );
        store.commit(
          "setFetchedSpecimen",
          store.state.fetchedSpecimensObj[
            store.state.chosenSpecimenMarkerFirestoreId
          ]
        );
        store
          .dispatch(
            "formatFetchedSpecimen",
            store.state.fetchedSpecimensObj[
              store.state.chosenSpecimenMarkerFirestoreId
            ].geojsonFeature.properties
          )
          .then(function () {
            // get specimen object model
            //console.log(store.state.formattedSpecimen);
             //console.log(
              store.state.formattedSpecimen.groupSpecific.omhm.observations
                .healthStatus.current.value
            );
            //console.log(
              store.state.formattedSpecimen.groupSpecific.omhm.observations.dhp
                .current.dhpTotal
            );
            //console.log(
              store.state.formattedSpecimen.groupSpecific.omhm.observations
                .leafVolume.current.value
            );
            //console.log(
              store.state.formattedSpecimen.groupSpecific.omhm.observations
                .mauvaisEmplacement.current.value
            );
            //console.log(store.state.chosenSpecimenMarkerFirestoreId);

            //   formattedSpecimen = store.state.formattedSpecimen;
          });
      }
    ); */

    return {
      specimen,
      errors,
      loading,
      firestoreId,
      closeCircle,
      camera,
      leaf,
      testBreakPoint,
      takePhoto,
      photos,
      uploading,
      deletePicture,
      savePicture,
      /* addReport, */ currentReport,
      labelPositioning,
      closeModal,
      warning,
      setOpen,
      buttons,
      isOpenRef,
      shareSocialOutline,
      logoFacebook,
      logoInstagram,
      editCurrentReport,
      removeCircle,
      confirmDeletePicture,
      confirmRemoveReport,
      modifyLike,
      cancelModifyLike,
      trash,
    };
  },
  components: {
    IonContent,
    IonIcon,
    IonButton,
    IonToolbar,
    IonHeader,
    IonItem,
    IonLabel,
    IonTitle,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonActionSheet,
  },
});
</script>
