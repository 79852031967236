/* eslint-disable no-prototype-builtins */
<template>
  <div>
    <ion-grid v-if="container &&
      (container == 'CleAddNewSpecimen' || container == 'VisualRecognition')
      ">
      <ion-row>
        <ion-col size="4" style="text-align: center">
          <span v-if="$store.state.cleIdentificationChosenSpecies" @click="$store.state.openPicturesSlider(0)">

            <img :src="'../assets/arbres/' +
              $store.state.cleIdentificationChosenSpecies.imageName +
              '_feuille.png'
              " class="species-details-pictures" @error="$store.state.imageLoadOnError" />
            <div class="ion-text-center">Feuille</div>
          </span>
        </ion-col>
        <ion-col size="4" style="text-align: center">
          <span v-if="$store.state.cleIdentificationChosenSpecies" @click="$store.state.openPicturesSlider(1)">
            <img :src="'../assets/arbres/' +
              $store.state.cleIdentificationChosenSpecies.imageName +
              '_fleur.png'
              " class="species-details-pictures" @error="$store.state.imageLoadOnError" />
            <div class="ion-text-center">Fleur</div>
          </span>
        </ion-col>
        <ion-col size="4" style="text-align: center">
          <span v-if="$store.state.cleIdentificationChosenSpecies" @click="$store.state.openPicturesSlider(2)">

            <img :src="'../assets/arbres/' +
              $store.state.cleIdentificationChosenSpecies.imageName +
              '_fruit.png'
              " class="species-details-pictures" @error="$store.state.imageLoadOnError" />
            <div class="ion-text-center">Fruit</div>
          </span>
        </ion-col>
      </ion-row>
      <div class="ion-padding" v-if="!(
        ($store.state.pictureSet.fullTree ||
          $store.state.pictureSet.flower ||
          $store.state.pictureSet.fruit ||
          $store.state.pictureSet.leaf) &&
        ($store.state.pictureSet.fullTree ||
          $store.state.pictureSet.flower ||
          $store.state.pictureSet.fruit ||
          $store.state.pictureSet.leaf)
      ) && container != 'VisualRecognition'
        ">
        <i>Vous devez ajouter au moins une photographie de l'arbre en entier. Vous pouvez ajouter plus d'une photo
          (feuilles, fleurs, fruits et écorce, etc).</i>
      </div>
      <ion-row>
        <ion-col size="4" style="text-align: center">
          <div v-if="!$store.state.pictureSet.leaf" class="tree-picture-placeholder">
            <!-- <ion-icon :icon="leaf" class="icon-in-background"></ion-icon> -->

            <ion-loading :isOpen="uploading" @click="uploading = false"></ion-loading>
            <ion-button :disabled="!$store.state.user" @click="takePhoto('leaf', container)" fill="clear">
              <ion-icon :icon="camera" class="icon-in-foreground"></ion-icon>
            </ion-button>
          </div>

          <div v-if="$store.state.pictureSet.leaf">
            <ion-button @click="confirmDelete($store.state.pictureSet.leaf)" class="remove-picture-icon" fill="clear"
              color="danger">
              <ion-icon :icon="removeCircle"></ion-icon>
            </ion-button>
            <ion-img :src="$store.state.pictureSet.leaf.pictureForUI" class="tree-picture"></ion-img>
          </div>
        </ion-col>
        <ion-col size="4" style="text-align: center">
          <div v-if="!$store.state.pictureSet.flower" class="tree-picture-placeholder">
            <!-- <ion-icon :icon="flower" class="icon-in-background"></ion-icon> -->
            <ion-loading :isOpen="uploading" @click="uploading = false"></ion-loading>
            <ion-button :disabled="!$store.state.user" @click="takePhoto('flower', container)" fill="clear">
              <ion-icon :icon="camera" class="icon-in-foreground"></ion-icon>
            </ion-button>
          </div>
          <div v-if="$store.state.pictureSet.flower">
            <ion-button @click="confirmDelete($store.state.pictureSet.flower)" class="remove-picture-icon" fill="clear"
              color="danger">
              <ion-icon :icon="removeCircle"></ion-icon>
            </ion-button>
            <ion-img :src="$store.state.pictureSet.flower.pictureForUI" class="tree-picture"></ion-img>
          </div>
        </ion-col>
        <ion-col size="4" style="text-align: center">
          <div v-if="!$store.state.pictureSet.fruit" class="tree-picture-placeholder">
            <!-- <ion-icon :icon="nutrition" class="icon-in-background"></ion-icon> -->
            <ion-loading :isOpen="uploading" @click="uploading = false"></ion-loading>
            <ion-button :disabled="!$store.state.user" @click="takePhoto('fruit', container)" fill="clear">
              <ion-icon :icon="camera" class="icon-in-foreground"></ion-icon>
            </ion-button>
          </div>
          <div v-if="$store.state.pictureSet.fruit">
            <ion-button @click="confirmDelete($store.state.pictureSet.fruit)" class="remove-picture-icon" fill="clear"
              color="danger">
              <ion-icon :icon="removeCircle"></ion-icon>
            </ion-button>
            <ion-img :src="$store.state.pictureSet.fruit.pictureForUI" class="tree-picture"></ion-img>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <div v-if="$store.state.formattedSpecimen &&
      $store.state.formattedSpecimen.photos &&
      $store.state.formattedSpecimen.photos.length
      ">
      <ion-grid v-if="container != 'VisualRecognition' && container != 'CleAddNewSpecimen'">
        <ion-row>
          <ion-col size="4" :key="photo" v-for="(photo, index) in $store.state.formattedSpecimen.photos">
            <ion-img :src="$store.state.returnImgUrl(photo._id)"
              @click="$store.state.openPicturesSlider(index, $store.state.formattedSpecimen.photos)">
            </ion-img>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <ion-item-divider v-if="!container">
      <ion-label class="form-divider-title">Ajouter une photo</ion-label>
      <ion-loading :isOpen="uploading" @click="uploading = false"></ion-loading>
      <ion-button :disabled="!$store.state.user" slot="end" fill="outline" @click="takePhoto('wholeTree')">
        <ion-icon :icon="camera"></ion-icon>
      </ion-button>
    </ion-item-divider>

    <!--  <ion-item-divider>
      <ion-label class="form-divider-title">Photo feuille</ion-label>
      <ion-loading :isOpen="uploading" @click="uploading = false"></ion-loading>
      <ion-button :disabled="!$store.state.user" slot="end" fill="outline" @click="takePhoto('leaf')">
        <ion-icon :icon="camera"></ion-icon>
        <ion-icon :icon="leaf"></ion-icon>
      </ion-button>
    </ion-item-divider>
        {{container}} -->
  </div>
  <ion-modal :is-open="$store.state.showPicturesSlider" @didDismiss="
    $store.state.showPicturesSlider = false;" cssClass="img-modal">
    <PicturesSlider :embedded="false"></PicturesSlider>
  </ion-modal>
 <!--  <ion-modal :is-open="$store.state.showImgModal" >
    <ion-content v-if="chosenPhoto">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="end" fill="clear">
            <ion-button @click="$store.state.showImgModal = false">OK</ion-button></ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-img :src="$store.state.returnImgUrl(chosenPhoto._id)"></ion-img>
      <ion-button v-if="$store.state.formattedSpecimen.author_uid == $store.state.user.uid
        " fill="outline" @click="confirmDelete(chosenPhoto)">Effacer</ion-button>
    </ion-content>
  </ion-modal> -->
</template>

<script>
import {
  IonLabel,
  IonItemDivider,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonButton,
  IonModal,
 /*  IonHeader,
  IonToolbar,
  IonContent, */
  IonIcon,
  IonLoading,
  alertController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { usePhoto } from "@/composables/usePhoto";
import firebaseService from "@/services/firebaseService";
import {
  camera,
  trash,
  close,
  image,
  leaf,
  removeCircle,
  flower,
  nutrition,
} from "ionicons/icons";
import store from "@/store";
import PicturesSlider from "@/components/PicturesSlider.vue"
//console.log("specimenPage omhm loaded");
export default defineComponent({
  name: "PhotosComponent",
  props: {
    container: String,
  },
  setup() {
    const {
      takePhoto,
      photos,
      uploading,
      deletePicture,
      plantNetScientificNameWithoutAuthor,
      plantNetScore,
    } = usePhoto();
    // reset pictureSet
    store.state.pictureSet = {};
    store.state.showImgModal = false;
    const chosenPhoto = ref();
    //console.log(props.container);
    const confirmDelete = async (chosenPhoto) => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Souhaitez-vous vraiment effacer cette image?",
        message: "Cette action est irréversible!!!",
        buttons: [
          {
            text: "Annuler",
            role: "cancel",
            cssClass: "secondary",
            id: "cancel-button",
            handler: () => {
              //console.log("Confirm Cancel:");
            },
          },
          {
            text: "Effacer",
            id: "confirm-button",
            handler: () => {
              //console.log("Confirm Okay");
              deletePicture(chosenPhoto);
              store.state.showPicturesSlider = false;
            },
          },
        ],
      });
      return alert.present();
    };
    const { errors, loading } =
      firebaseService();

    return {
      errors,
      loading,
      uploading,
      takePhoto,
      camera,
      trash,
      close,
      image,
      leaf,
      flower,
      nutrition,
      removeCircle,
      photos,
      chosenPhoto,
      deletePicture,
      confirmDelete,
      plantNetScientificNameWithoutAuthor,
      plantNetScore,
    };
  },
  components: {
    IonLabel,
    IonItemDivider,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    IonButton,
    IonModal,
   /*  IonHeader,
    IonToolbar,
    IonContent, */
    IonIcon,
    IonLoading,
    PicturesSlider
  },
});
</script>
