/* eslint-disable no-prototype-builtins */
<template>
  <div v-if="$store.state.formattedSpecimen.essence != 'deleted'">
    <!-- <ion-label>singleUser form</ion-label>
    <div>    <ion-label>{{$store.state.createOrModifySpecimen}}</ion-label>
</div>
    <div>
    <ion-label>chosenSpecimenMarkerFirestoreId</ion-label>
    </div>
    <div>
    <ion-label>{{$store.state.chosenSpecimenMarkerFirestoreId}}</ion-label>
    </div> -->
    <photos-component></photos-component>
    <!--     <ion-item-divider>
      <ion-label class="form-divider-title">Classification</ion-label>
    </ion-item-divider> -->
    <search-species-component usedIn="specimenForm"></search-species-component>
    <ion-item :lines="$store.state.formattedSpecimen.new_tree ? 'none' : undefined">
<!--       <ion-label class="form-divider-title">J'ai planté cet arbre moi-même</ion-label>
 -->      
 <ion-toggle name="observations.plantation" @ionChange="setPlantation"
        v-model="$store.state.formattedSpecimen.new_tree">J'ai planté cet arbre moi-même</ion-toggle>
    </ion-item>
    <ion-item v-if="$store.state.formattedSpecimen.new_tree">
      <!--       <ion-label slot="start">Entrez l'année de plantation svp</ion-label> -->
      <ion-input label="Entrez l'année de plantation svp" label-placement="floating" inputmode="numeric" type="number"
        min="1950" :max="new Date().getFullYear()" :value="new Date().getFullYear()"
        v-model="$store.state.formattedSpecimen.new_tree_plantationYear"></ion-input>
    </ion-item>

    <!--  <habitations-component></habitations-component> -->
    <observations-component :title="'test-title'" groupSpecific="singleUser"></observations-component>
    <!--     <defauts-component></defauts-component>
    <tasks-component :title="'test-title'"></tasks-component> -->
    <ion-item>
      <ion-button v-if="$store.state.formattedSpecimen._id != 0" style="margin-bottom:150px;margin-top:36px"
        @click="confirmDelete()" fill="outline" color="danger">Effacer</ion-button>
      <ion-button @click="saveSpecimen()" style="margin-bottom:150px;margin-top:36px" slot="end">Sauvegarder</ion-button>
    </ion-item>
  </div>

  <ion-modal :is-open="$store.state.showDateTime" @didDismiss="dateTimeModalDismissed">
    <ion-content>
      <ion-header>
        <ion-toolbar>
          <ion-title>{{ $store.state.editingTask.name }}
            <b>{{ $store.state.editingTask.prop }} </b></ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-datetime @ionChange="setDateTime" size="cover" showDefaultButtons="true" showClearButton="true"
        presentation="date" locale="fr-CA">
      </ion-datetime>
    </ion-content>
  </ion-modal>
</template>

<script>
import firebaseService from "@/services/firebaseService";
import mapService from "@/services/mapService";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
/*   IonLabel,
 */  IonItem,
  IonList,
  IonModal,
  IonDatetime,
  alertController,
  IonToggle,
  IonButton,
  IonInput
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import ObservationsComponent from "@/components/ObservationsComponent";
import { useStore } from "vuex";
import PhotosComponent from "./PhotosComponent.vue";
import searchSpeciesComponent from "./searchSpeciesComponent.vue";
//console.log("specimenPage omhm loaded");
let handleInput;
export default defineComponent({
  name: "singleUserForm",
  props: {
    name: String,
    mapTypeId: String,
    zoom: Number,
    createOrModify: String,
  },
  setup() {
    const store = useStore();
    const { errors, loading } =
      firebaseService();
    const specimen = ref();
    const formType = ref();

    let formattedSpecimen;

    const setPlantation = (ev) => {
      //console.log(ev.detail.checked);
      store.state.formattedSpecimen.new_tree = ev.detail.checked;
      //    store.state.formattedSpecimen.new_tree_plantationYear = new Date().getFullYear();
      // store.commit("setFormattedSpecimen", formattedSpecimen);
    };

    /*     onMounted(() => {
      
      watch(
        () => store.state.formattedSpecimen,
        () => {
          //console.log("store.state.formattedSpecimen changed");
        }
      );
    }); */

    /*     onMounted(() => {
           let singleUserFormHeight = document.getElementById("singleUserForm");
          //console.log("singleUserFormHeight " + singleUserFormHeight.offsetHeight);
        });
     */
    const dateTimeModalDismissed = () => {
      //console.log("dateTimeModalDismissed");
      store.commit("setShowDateTime", false);
    };
    const setDateTime = (ev) => {
      //console.log(ev.detail.value);
      // set on task
      const array =
        store.state.formattedSpecimen.groupSpecific[
          store.state.userGroupMembership
        ].tasks;
      array.forEach((task) => {
        if (task.name === store.state.editingTask.name) {
          const prop = store.state.editingTask.prop;
          // only one can be set at a time
          if (prop == "dateToDo") {
            task.suivi.dateDone = null;
            task.suivi[prop] = ev.detail.value;
          } else {
            task.suivi.dateToDo = null;
            task.suivi[prop] = ev.detail.value;
          }
        }
      });
    };
    const checkForm = (e) => {
      //console.log("checking form");
      const errors = [];

      // eslint-disable-next-line no-prototype-builtins
      if (!formattedSpecimen.groupSpecific.hasOwnProperty("omhm")) {
        if (formattedSpecimen.essence) {
          return true;
        } else {
          if (!formattedSpecimen.essence) {
            errors.push("Espèce");
          }
        }
      } else {
        if (
          formattedSpecimen.essence &&
          formattedSpecimen.photos.length > 0 &&
          formattedSpecimen.photos[0]._id &&
          formattedSpecimen.groupSpecific[store.state.userGroupMembership[0]]
            .observations.healthStatus.current.value &&
          formattedSpecimen.groupSpecific[store.state.userGroupMembership[0]]
            .observations.leafVolume.current.value &&
          formattedSpecimen.groupSpecific[store.state.userGroupMembership[0]]
            .observations.dhp.current.value.length > 0
        ) {
          return true;
        }

        if (!formattedSpecimen.essence) {
          errors.push("Espèce");
        }
        if (
          !formattedSpecimen.groupSpecific[store.state.userGroupMembership[0]]
            .observations.healthStatus.current.value
        ) {
          errors.push("État de santé");
        }
        if (
          !formattedSpecimen.groupSpecific[store.state.userGroupMembership[0]]
            .observations.leafVolume.current.value
        ) {
          errors.push("Défoliation");
        }
        if (
          formattedSpecimen.groupSpecific[store.state.userGroupMembership[0]]
            .observations.dhp.current.value.length === 0
        ) {
          errors.push("DHP");
        }
        if (!formattedSpecimen.photos.length > 0) {
          errors.push("Photo");
        }
      }
    };
    const saveSpecimen = () => {
      //in case we updated profile pic and returned to this page
      // formattedSpecimen.specimen_pic = store.state.profile.specimen_pic;
      // if (checkForm()) {
      if (store.state.formattedSpecimen.new_tree_plantationYear && (store.state.formattedSpecimen.new_tree_plantationYear > new Date().getFullYear() || store.state.formattedSpecimen.new_tree_plantationYear < 1900)) {
        alert("La date de plantation ne peut pas être antérieure à 1900 ou postérieure à l'année actuelle.");
        return
      }
      const tre = true;
      if (tre) {
        //console.log("checkForm done, tryin to save");

        /*   // check if the specimen was set to dead
        if (
          formattedSpecimen.groupSpecific.hasOwnProperty(
            store.state.userGroupMembership[0]
          ) &&
          formattedSpecimen.groupSpecific[
            store.state.userGroupMembership[0]
          ].observations.hasOwnProperty("healthStatus") &&
          formattedSpecimen.groupSpecific[
            store.state.userGroupMembership[0]
          ].observations.healthStatus.current.value === "Mort" &&
          !formattedSpecimen.groupSpecific[
            store.state.userGroupMembership[0]
          ].observations.healthStatus.current.cod
        ) {
          presentAlertPrompt();
          dialogs
            .action({
              message:
                store.state.userGroupMembership[0] === "soverdi"
                  ? "Si vous poursuivez, ce spécimen sera considéré comme mort et pourra seulement être affiché sur la carte si vous ajoutez le filtre 'État de santé: Mort' à vos requêtes. \n Veuillez préciser la cause de la mortalité svp."
                  : "Veuillez préciser la cause de la mortalité svp.",
              cancelButtonText: "Annuler",
              actions: [
                "sécheresse",
                "déneigement",
                "tondeuse ou coupe bordure",
                "vandalisme ou vol",
                "animaux",
                "inconnue",
              ],
            })
            .then((result) => {
              //console.log(result);
              if (result !== "Annuler") {
                if (isIOS) {
                  loader.show();
                }
                store.commit(
                  "setDeletedSpecimen",
                  store.state.fetchedSpecimen
                );
                // add cause of death to healthStatus property
                formattedSpecimen.groupSpecific[
                  store.state.userGroupMembership[0]
                ].observations.healthStatus.current.cod = result;

                store.commit("setSavedSpecimen", formattedSpecimen);

                store
                  .dispatch("formatSpecimenForServer", formattedSpecimen)
                  .then(function (result) {
                    //console.log("specimen  saved on server");
                    //console.log(result);
                    loader.hide();
                    vm.editable = false;
                    vm.$navigateBack();

                    
                  })
                  .catch(function (error) {
                    alert("Unable to save specimen, try again later!");
                    loader.hide();
                    console.error("Error writing firestore document: ", error);
                  });
              } else {
              }
            });
        } else if (
          // check if specimen was abattu
          store.state.userGroupMembership[0] === "omhm" &&
          formattedSpecimen.groupSpecific.hasOwnProperty(
            store.state.userGroupMembership[0]
          ) &&
          formattedSpecimen.groupSpecific[
            store.state.userGroupMembership[0]
          ].tasks[0].suivi.hasOwnProperty("dateDone") &&
          formattedSpecimen.groupSpecific[
            store.state.userGroupMembership[0]
          ].tasks[0].suivi.dateDone
        ) {
          dialogs
            .confirm({
              title: "Ce spécimen sera considéré comme abattu",
              message:
                "Vous avez déclaré que l'abattage de l'arbre a été complété.  Si vous poursuivez, ce spécimen ne sera plus affiché sur la carte",
              okButtonText: "OK",
              cancelButtonText: "Annuler",
            })
            .then((result) => {
              //console.log(result);
              if (result) {
                if (isIOS) {
                  loader.show();
                }
                store.commit(
                  "setDeletedSpecimen",
                  store.state.fetchedSpecimen
                );
                store.commit("setSavedSpecimen", formattedSpecimen);

                store
                  .dispatch("formatSpecimenForServer", formattedSpecimen)
                  .then(function (result) {
                    //console.log("specimen  saved on server");
                    //console.log(result);
                    loader.hide();
                    vm.editable = false;
                    vm.$navigateBack();
                  })
                  .catch(function (error) {
                    alert("Unable to save specimen, try again later!");
                    loader.hide();
                    console.error("Error writing firestore document: ", error);
                  });
              }
            });
        } else { */

        store.commit("setSavedSpecimen", store.state.formattedSpecimen);

        store
          .dispatch("formatSpecimenForServer", store.state.formattedSpecimen)
          .then(function (result) {
            //console.log("specimen  saved on server");
            //console.log(result);
            //    loader.hide();
            store.commit("setRepositionSpecimen", false);
            // close specimen details
            mapService.deselectMarker();
            const modalRef = document.querySelector(
              ".modal-content-specimen-details"
            );
            modalRef.setCurrentBreakpoint(0.001);
          })
          .catch(function (error) {
            alert("La sauvegarde du spécimen a échouée.  Veuillez réessayer plus tard.");
            //     loader.hide();
            console.error("Error writing firestore document: ", error);
          });
      }
      // }
    };
    const confirmDelete = async () => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Souhaitez-vous vraiment effacer ce spécimen?",
        message: "Cette action est irréversible!!!",
        buttons: [
          {
            text: "Annuler",
            role: "cancel",
            cssClass: "secondary",
            id: "cancel-button",
            handler: () => {
              //console.log("Confirm Cancel:");
            },
          },
          {
            text: "Effacer",
            id: "confirm-button",
            handler: () => {
              //console.log("Confirm Okay");
              deleteSpecimen();
            },
          },
        ],
      });
      return alert.present();
    };
    const deleteSpecimen = () => {
      store
        .dispatch(
          "batchDeleteSpecimenInfoOnRemote",
          store.state.formattedSpecimen
        )
        .then(function (result) {
          //console.log("specimen archived on server");
          //console.log(result);
          // loader.hide();
        })
        .catch(function (error) {
          alert("Le spécimen n'a pas été effacé.  Veuillez recommencer plus tard svp.");
          console.error(" Error writing firestore document: ", error);
        });
    };

    return {
      specimen,
      errors,
      loading,
      formType,
      handleInput,
      IonList,
      dateTimeModalDismissed,
      setDateTime,
      setPlantation,
      formattedSpecimen,
      saveSpecimen,
      confirmDelete,
    };
  },
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
/*     IonLabel,
 */    IonItem,
    ObservationsComponent,
    IonModal,
    IonDatetime,
    IonContent,
    IonToggle,
    IonButton,
    PhotosComponent,
    searchSpeciesComponent,
    IonInput
  },
});
</script>