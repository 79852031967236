<template>
  <!--   <ion-item-divider>
    <ion-label class="form-divider-title">J'aime cet arbre</ion-label>
    <ion-button
      :disabled="!$store.state.user"
      slot="end"
      fill="outline"
      @click="$store.state.showLikeDetails = !$store.state.showLikeDetails"
      ><ion-icon :icon="heart"></ion-icon
    ></ion-button>
  </ion-item-divider> -->
  <ion-list>
    <ion-item 
      v-for="(like, key) in $store.state.formattedSpecimen.groupSpecific
        .collaborative.likes"
      :key="like"
      class="ion-no-padding"
      @click="
        $store.state.likeDetails = like;
        $store.state.likeDetails.key = key;
        $store.state.showLikeDetails = true;
      "
    >
      <ion-thumbnail class="thumbnail-in-collaborative-form">
        <ion-img
          v-if="like.photos && like.photos[0]"
          :src="
            'https://firebasestorage.googleapis.com/v0/b/soverdiwebsite.appspot.com/o/specimens%2F' +
            like.photos[0]._id +
            '?alt=media'
          "
        ></ion-img>
      </ion-thumbnail>
    <!--   <ion-icon
        :icon="cameraOutline"
        class="icon-in-collaborative-form"
      ></ion-icon> -->

      <ion-label
        :class="{
          userownershiponitem: like.author_uid == $store.state.user.uid,
        }"
      >
        <b>{{ like.description }} </b>
        <br />
        par: {{ like.author_name ? like.author_name : "anonyme" }}
        <!--  défaut: {{ like.name }} <br />
        localisation: {{ like.localisation }} <br />
        type: {{ option.report.type }} <br />
        par: {{ option.author_name ? option.author_name : "anonyme" }}
        {{ option.author_email }}<br /> -->
      </ion-label>
      <!--      <ion-img v-for="photo in like.photos" :key="photo" slot="end" :src="'https://firebasestorage.googleapis.com/v0/b/soverdiwebsite.appspot.com/o/specimens%2F' +
        photo._id +
        '?alt=media'"
        @click="showImgModal = !showImgModal; chosenPhoto = photo;">
      </ion-img> -->
      <ion-button v-if="like.author_uid == $store.state.user.uid" fill="clear">
        <ion-icon :icon="create"></ion-icon>
      </ion-button>
    </ion-item>
    <!-- <ion-item>
      <label>Ajouter un signalement</label>
      <ion-button
        fill="outline"
        slot="end"
        @click="
          openPicker(
            2,
            structure.options[1].length,
            structure.options,
            structure.prefix
          )
        "
        >Structure</ion-button
      >

    </ion-item> -->
  </ion-list>
  <ion-modal
    :is-open="$store.state.showLikeDetails"
    @didDismiss="$store.state.showLikeDetails = false;"
    class="favorite-details-modal"
  >
    <favorite-details addOrEdit="edit"></favorite-details>
  </ion-modal>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonList,
  IonIcon,
  IonImg,
  IonThumbnail,
  IonModal,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";

import {
  heart,
  closeCircle,
  create,
  /* cameraOutline, */
} from "ionicons/icons";
import FavoriteDetails from "./FavoriteDetails.vue";

export default defineComponent({
  name: "FavoriteComponent",
  props: {
    title: { type: String, default: "Défauts" },
  },
  setup() {

    return {
      // store,
      heart,
      closeCircle,
      create,
/*       cameraOutline,
 */    };
  },
  components: {
    IonLabel,
    IonItem,
    IonList,
    IonIcon,
    IonImg,
    IonThumbnail,
    FavoriteDetails,
    IonModal,
  },
});
</script>
