import store from "../store";
import {
  getDatabase,
  ref,
  query,
  orderByChild,
  onValue,
  equalTo,
} from "firebase/database";
import firebaseService from "../services/firebaseService";
const { getCollectionDoc, listenToCollectionDoc } = firebaseService();
console.log("loading profileService");
export default {
  groupRelatedSetup(userGroup) {
    //console.log("setting user group  ");
    //console.log(userGroup);
    //set a wider radius for registred groups since all queries start with the filter groupe de travail applied, which will return less trees
    // store.commit("setRadius", userGroup == 'anonyme' || userGroup == 'singleUser' ? 125 : 125);
    // get groupSpecific lists for dropdowns
    /*  const getGroupInfosDocOptions = {
       collectionName: "treeModels",
       docName: "entretienAppMode",
     };
     const groupInfos = getCollectionDoc(getGroupInfosDocOptions);
     groupInfos.then((res)=>{
       //console.log(res)
       store.commit("setGroupInfos", res);
     }) */

    const getGroupSpecificTreeModelOptions = {
      collectionName: "treeModels",
    };
    // get collaborative form obj model
    getGroupSpecificTreeModelOptions.docName = "collaborative";
    const collaborativeFormObjModel = getCollectionDoc(
      getGroupSpecificTreeModelOptions
    );
    collaborativeFormObjModel.then((res) => {
      store.commit("setCollaborativeFormObjModel", res);
    });
    // reset userStats
   // store.commit("setUserStats", null);
    // get group specific treeModel
    if (userGroup === "anonyme") {
      // do nothing the collaborative form will be used instead
    } else if (userGroup === "singleUser") {
      getGroupSpecificTreeModelOptions.docName = "singleUser";
    } else {
      getGroupSpecificTreeModelOptions.docName = userGroup;
    }
    const groupSpecificTreeModel = getCollectionDoc(
      getGroupSpecificTreeModelOptions
    );
    groupSpecificTreeModel.then((res) => {
      store.commit("setGroupSpecificTreeModel", res);
    });

    if (userGroup === "anonyme" || userGroup === "singleUser") {
      store.commit("setTreeIconsOptions", { trees: true });

      // fetch featured soverdi projetcs
      //console.log("trying to get featuredProjects");

      /*   store.commit("setGroupQueryFilters", [
            {
             fieldName: "geojsonFeature.properties.owning_group." + userGroup,
             operator: "==",
             enteredValue: true,
             type: "boolean",
             displayName: "groupe de travail",
             displayValue: userGroup,
           } 
         ])*/
      /* store.commit("setFiltersListArrayInQueryOptions", [{
        fieldName: "geojsonFeature.properties.type",
        operator: "==",
        enteredValue: "tree",
        type: "string",
        displayName: "type",
        displayValue: "arbre"
      }
      ]); */
    } else if (userGroup === "omhm") {
      // set groupRelated query filters
      // add group filters
      store.commit("setGroupQueryFilters", [
        {
          fieldName: "geojsonFeature.properties._id",
          operator: "==",
          enteredValue: null,
          type: "string",
          inputType: "text",
          displayName: "ID",
          placeholder: "Entrez le ID",
        },
        {
          fieldName:
            "geojsonFeature.properties.groupSpecific." +
            userGroup +
            ".comments",
          operator: "==",
          enteredValue: null,
          type: "string",
          displayName: "commentaires groupe de travail",
        },
        {
          fieldName:
            "geojsonFeature.properties.groupSpecific." +
            userGroup +
            ".observations.dhp.current.dhpTotal",
          operator: "==",
          enteredValue: null,
          type: "number",
          displayName: "dhp",
        },
        {
          fieldName:
            "geojsonFeature.properties.groupSpecific." +
            userGroup +
            ".observations.healthStatus.current.value",
          operator: "==",
          enteredValue: null,
          type: "string",
          displayName: "état de santé",
        },
        {
          fieldName:
            "geojsonFeature.properties.groupSpecific." +
            userGroup +
            "observations.leafVolume.current.searchValue",
          operator: "==",
          enteredValue: null,
          type: "number",
          displayName: "masse foliaire",
        },
        {
          fieldName:
            "geojsonFeature.properties.groupSpecific." +
            userGroup +
            "place.secteur",
          operator: "==",
          enteredValue: null,
          type: "string",
          displayName: "secteur",
        },
        {
          fieldName:
            "geojsonFeature.properties.groupSpecific." +
            userGroup +
            ".place.name",
          operator: "==",
          enteredValue: null,
          type: "string",
          displayName: "habitation",
        },
        {
          fieldName:
            "geojsonFeature.properties.groupSpecific." +
            userGroup +
            ".place.habitationNumber",
          operator: "==",
          enteredValue: null,
          type: "number",
          displayName: "numéro d'habitation",
        },
        {
          fieldName: "geojsonFeature.properties.owning_group",
          operator: "==",
          enteredValue: userGroup,
          type: "string",
          displayName: "groupe de travail",
          displayValue: userGroup,
        },
        {
          fieldName: "geojsonFeature.properties.new_tree",
          operator: "==",
          enteredValue: false,
          type: "boolean",
          displayName: "plantation",
        },
        {
          fieldName:
            "geojsonFeature.properties.groupSpecific." +
            userGroup +
            ".tasks.abattage.suivi.dateDoneSegmented.year",
          operator: "==",
          enteredValue: null,
          type: "number",
          displayName: "année de l'abattage",
        },
      ]);
      // add default filters for this usergroup
      store.commit("setFiltersListArrayInQueryOptions", [
        {
          fieldName: "geojsonFeature.properties.owning_group",
          operator: "==",
          enteredValue: userGroup,
          type: "boolean",
          displayName: "groupe de travail",
          displayValue: userGroup,
        },
        {
          fieldName: "geojsonFeature.properties.type",
          operator: "==",
          enteredValue: "tree",
          type: "string",
          displayName: "type",
          displayValue: "arbre",
        },
      ]);
    } 
   /*  else if (userGroup === "soverdi") {
      // set groupRelated query filters
      // add group filters
      store.commit("setGroupQueryFilters", [
        {
          fieldName: "geojsonFeature.properties._id",
          operator: "==",
          enteredValue: null,
          type: "string",
          inputType: "text",
          displayName: "ID",
          placeholder: "Entrez le ID",
        },
        {
          fieldName:
            "geojsonFeature.properties.groupSpecific." +
            userGroup +
            ".comments",
          operator: "==",
          enteredValue: null,
          type: "string",
          displayName: "commentaires groupe de travail",
        },
        {
          fieldName:
            "geojsonFeature.properties.groupSpecific." +
            userGroup +
            ".observations.dhp.current.dhpTotal",
          operator: "==",
          enteredValue: null,
          type: "number",
          displayName: "dhp",
        },
        {
          fieldName:
            "geojsonFeature.properties.groupSpecific." +
            userGroup +
            ".observations.healthStatus.current.value",
          operator: "==",
          enteredValue: null,
          type: "string",
          displayName: "état de santé",
        },
        {
          fieldName:
            "geojsonFeature.properties.groupSpecific." +
            userGroup +
            "observations.leafVolume.current.searchValue",
          operator: "==",
          enteredValue: null,
          type: "number",
          displayName: "masse foliaire",
        },
        {
          fieldName: "geojsonFeature.properties.owning_group",
          operator: "==",
          enteredValue: userGroup,
          type: "boolean",
          displayName: "groupe de travail",
          displayValue: userGroup,
        },
        {
          fieldName: "geojsonFeature.properties.type",
          operator: "==",
          enteredValue: null,
          type: "string",
          displayName: "type",
          displayValue: "arbre",
        },
      ]);
      // add default filters for this user group
      store.commit("setFiltersListArrayInQueryOptions", [
        {
          fieldName: "geojsonFeature.properties.owning_group",
          operator: "==",
          enteredValue: userGroup,
          type: "boolean",
          displayName: "groupe de travail",
          displayValue: userGroup,
        },
      ]);
    } */

    store.commit("setHabitationsOMHMQueryEnabled", userGroup == "omhm");
/*     store.commit("setProjectsQueryEnabled", userGroup == "soverdi");
 */
    if (userGroup == "omhm") {
      store.state.treeIconsOptions.trees = false;
      store.state.treeIconsOptions.showSpeciesCode = true;
    }

   /*  if (userGroup === "soverdi") {
      const firebasedb = getDatabase();
      store.commit("setTreeIconsOptions", { trees: false });

      let projectIDsRef;
      if (store.state.userEditOnly) {
        //console.log("store.state.userEditOnly editonly");
        //console.log(store.state.userEditOnly);
        projectIDsRef = query(
          ref(firebasedb, "projectIDs"),
          orderByChild("tags/" + store.state.userEditOnly[0]),
          equalTo(store.state.userEditOnly[0])
        );
      } else {
        // fetch all projects
        projectIDsRef = query(ref(firebasedb, "projectIDs"));
      }
      //console.log("starting to  fetch projectids");
      onValue(projectIDsRef, function (snapshot) {
        //console.log(snapshot.val());
        store.commit("setProjectsList", {
          allProjects: snapshot.val(),
        });
      });
    } else {
      // usergroup isnt soverdi.  Set empty array in projects list
      //console.log("empty projects  list array");
      store.commit("setProjectsList", {
        allProjects: {},
      });
      store.commit("setProjectsListAsArray", null);
    } */
    if (userGroup != "anonyme") {
      // user is logged in
      // fetch userStats
      const getUserStats = {};
      getUserStats.collectionName = "usersStats";
      //console.log(store.state.user);
      getUserStats.docName = store.state.user.uid;
      const userStats = listenToCollectionDoc(getUserStats);
      userStats
        .then((res) => {
          console.log("getUserStats promise resolved")
          if (res) {
            store.commit("setUserStats", res);
          } else {
            store.commit("setUserStats", {
              reports: 0,
              likes: 0,
              likeTags: 0,
              identifiedSpecimens: 0,
            });
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    }
    return;
  },
};
