
export default {
     encodeGeohash(location, precision) {
         //console.log(location);
         
        // Characters used in location geohashes
        var BASE32 = "0123456789bcdefghjkmnpqrstuvwxyz";
        // Number of bits per geohash character
        var BITS_PER_CHAR = 5;
        // The following value assumes a polar radius of
        // const EARTH_POL_RADIUS = 6356752.3;
        // The formulate to calculate E2 is
        // E2 == (EARTH_EQ_RADIUS^2-EARTH_POL_RADIUS^2)/(EARTH_EQ_RADIUS^2)
        // The exact value is used here to avoid rounding errors
        var E2 = 0.00669447819799;
        // Equatorial radius of the earth in meters
        var EARTH_EQ_RADIUS = 6378137.0;
        // The meridional circumference of the earth in meters
        var EARTH_MERI_CIRCUMFERENCE = 40007860;
        // Cutoff for rounding errors on double calculations
        var EPSILON = 1e-12;
        // Default geohash length
        var GEOHASH_PRECISION = 10;
        // Maximum length of a geohash in bits
        var MAXIMUM_BITS_PRECISION = 22 * BITS_PER_CHAR;
        // Length of a degree latitude at the equator
        var METERS_PER_DEGREE_LATITUDE = 110574;
      
        //console.log("trying to geohash!");
        if (precision === void 0) {
          precision = GEOHASH_PRECISION;
        }
        //validateLocation(location);
        if (typeof precision === "number" && !isNaN(precision)) {
          if (precision <= 0) {
            throw new Error("precision must be greater than 0");
          } else if (precision > 22) {
            throw new Error("precision cannot be greater than 22");
          } else if (Math.round(precision) !== precision) {
            throw new Error("precision must be an integer");
          }
        } else {
          throw new Error("precision must be a number");
        }
        var latitudeRange = {
          min: -90,
          max: 90
        };
        var longitudeRange = {
          min: -180,
          max: 180
        };
        var hash = "";
        var hashVal = 0;
        var bits = 0;
        var even = 1;

        if(location.latitude){
          location.lat = location.latitude;
          location.lng = location.longitude;
        }
        while (hash.length < precision) {
          var val = even ? location.lng : location.lat;
          var range = even ? longitudeRange : latitudeRange;
          var mid = (range.min + range.max) / 2;
          if (val > mid) {
            hashVal = (hashVal << 1) + 1;
            range.min = mid;
          } else {
            hashVal = (hashVal << 1) + 0;
            range.max = mid;
          }
          even = !even;
          if (bits < 4) {
            bits++;
          } else {
            bits = 0;
            hash += BASE32[hashVal];
            hashVal = 0;
          }
        }
        return hash;
      }
};
