<template >
  <ion-list>
    <ion-item v-for="(option, keyname) in $store.state.formattedSpecimen.groupSpecific
    .collaborative.reports" :key="option" style="margin-bottom: 12px" @click="$store.state.reportDetails = option;$store.state.reportDetails.key = keyname; $store.state.showReportDetails = true">
     <ion-thumbnail class="thumbnail-in-collaborative-form">
        <ion-img v-if="option.photos && option.photos[0]" :src="'https://firebasestorage.googleapis.com/v0/b/soverdiwebsite.appspot.com/o/specimens%2F' +
        option.photos[0]._id +
        '?alt=media'"></ion-img>
      </ion-thumbnail>
      <ion-icon :icon="warning" color="warning" class="icon-in-collaborative-form"></ion-icon>
      <ion-label :class="{
        userownershiponitem: option.author_uid == $store.state.user.uid,
      }">
        {{ option.report }} <br />
        par: {{ option.author_name ? option.author_name : "anonyme" }} <br />
        <span v-if="option.dateDone && option.dateDone.seconds">{{ new Date(option.dateDone.seconds * 1000).toLocaleString('fr-CA',{
              year: "numeric",
              month: "long",
              day: "numeric",
            }).slice(0,25) }}</span><br />
      </ion-label>
      <ion-button v-if="option.author_uid == $store.state.user.uid" fill="clear">
        <ion-icon :icon="create"></ion-icon>
      </ion-button>
    </ion-item>
  </ion-list>
  <ion-modal :is-open="$store.state.showReportDetails" @didDismiss="$store.state.showReportDetails = false"
    class="favorite-details-modal"> 
    <report-details addOrEdit="edit"></report-details>
  </ion-modal>
</template>
<script>
import {
  IonItem,
  IonLabel,
  IonButton,
  IonList,
  IonIcon,
  IonModal,
  IonImg,
  IonThumbnail,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { alert, add, create, warning } from "ionicons/icons";
import ReportDetails from "./ReportDetails.vue";

export default defineComponent({
  name: "ReportComponent",
  props: {
    title: { type: String, default: "Défauts" },
  },
  setup() {

    return {
      alert,
      add,
      create,
      warning
    };
  }, 
  components: {
    IonLabel,
    IonItem,
    IonButton,
    IonList,
    IonIcon,
    ReportDetails,
    IonModal,
    IonImg,
    IonThumbnail
  },
});
</script>