/* eslint-disable no-prototype-builtins */
<template>
  <ion-content>
    <ion-header>
      <ion-toolbar style="padding-top: 0px">
        <ion-title>Modifier le rayon</ion-title>
      </ion-toolbar>
    </ion-header>
    <!--     rayon -->
    <div class="requests-options" >
      <ion-item lines="none"
        ><ion-label class="ion-text-wrap">
          Afficher uniquement les spécimens situés à
          <ion-text color="primary">{{
            $store.state.queryOptions.radius
          }}</ion-text>
          mètres du centre de la carte
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-range
          min="2"
          max="200"
          color="primary"
          v-model="$store.state.queryOptions.radius"
          @ionKnobMoveEnd="$store.state.drawRadiusCircleOnMap()"
        >
          <ion-icon slot="start" size="small" :icon="locate"></ion-icon>
          <ion-icon slot="end" :icon="locate"></ion-icon>
        </ion-range>
      </ion-item>
      <ion-header>
        <ion-toolbar style="padding-top: 0px">
          <ion-title>Filtrer les requêtes</ion-title>
        </ion-toolbar>
        <!-- <ion-item style="padding-left: 12px">
          <ion-label class="ion-text-wrap ion-no-margin"
            >Les spécimens affichés contiennent l'ensemble des filtres
            activés</ion-label
          >
        </ion-item> -->
      </ion-header>
      <!-- group specific -->
      <!-- <div v-if="$store.state.userGroupMembership === 'soverdi'">
        <ion-item>
          <ion-label slot="start" class="ion-text-wrap" style="font-size: 12px"
            >Ajouter aux spécimens affichés</ion-label
          >
          <ion-toggle
            v-model="$store.state.queryOptions.addToDisplayedSpecimens"
            slot="start" style="position:absolute;margin-left:200px"
          ></ion-toggle>
        </ion-item>
      </div> -->
      <div>
        <span
          v-for="filter in $store.state.queryFiltersList.basicQueryFilters"
          :key="filter"
          class="ion-no-padding"
        >
          <ion-item
            v-if="
              filter.displayName == 'classification' &&
              !$store.state.showSpeciesList
            "
          >
            <!--             <ion-label>{{ filter.displayName }}</ion-label>
 -->
            <ion-input
              :label="filter.displayName"
              v-model="filter.displayValue"
              style="margin: 10px"
              :autofocus="false"
              :type="filter.inputType ? filter.inputType : 'text'"
              :placeholder="'espèce'"
              @click="
                $store.state.showSpeciesList = !$store.state.showSpeciesList
              "
              :readonly="true"
            ></ion-input>
            <ion-icon
              :icon="closeCircle"
              @click="removeFromQueryFiltersList(filter)"
            ></ion-icon>
          </ion-item>
          <ion-item
            v-else-if="
              filter.type == 'boolean' && !$store.state.showSpeciesList
            "
          >
            <!-- <ion-label>{{ filter.displayName }}</ion-label> -->
            <ion-toggle
              v-model="filter.enteredValue"
              @ionChange="handleToggleChange(filter)"
            >{{ filter.displayName }}</ion-toggle>
          </ion-item>
          <ion-item
            v-else-if="
              !$store.state.showSpeciesList &&
              filter.fieldName.indexOf(
                'geojsonFeature.properties.classification'
              ) == -1
            "
          >
            <!-- <ion-label>{{ filter.displayName }}</ion-label> -->
            <ion-input
            :label="filter.displayName"
              v-model="filter.enteredValue"
              style="margin: 10px"
              autofocus="false"
              :type="filter.inputType ? filter.inputType : 'text'"
              :placeholder="filter.placeholder"
            ></ion-input>
            <ion-icon
              :icon="closeCircle"
              @click="removeFromQueryFiltersList(filter)"
            ></ion-icon>
          </ion-item>
        </span>
        <span
          v-for="filter in $store.state.queryFiltersList.groupQueryFilters"
          :key="filter"
          class="ion-no-padding"
        >
          <ion-item v-if="!$store.state.showSpeciesList">
            <!-- <ion-label>{{ filter.displayName }}</ion-label> -->
            <ion-input
              :label="filter.displayName"
              v-model="filter.enteredValue"
              style="margin: 10px"
              autofocus="false"
              :type="filter.inputType ? filter.inputType : 'text'"
              :placeholder="filter.placeholder"
            ></ion-input>
            <ion-icon
              :icon="closeCircle"
              @click="removeFromQueryFiltersList(filter)"
            ></ion-icon>
          </ion-item>
        </span>
        <!-- <ion-chip
        v-for="filter in $store.state.queryFiltersList.groupQueryFilters"
        :key="filter"
        @click="queryFilterSelected(filter)"
      >
        <ion-label>{{ filter.displayName }}</ion-label>
        <ion-icon :icon="closeCircle" @click="removeFromQueryFiltersList(filter)"></ion-icon>
      </ion-chip> -->
      </div>

      <ion-list>
        <!-- <ion-select
        style="padding-top: 12px"
        class="ion-padding"
        :value="selectedFilter"
        placeholder="ajouter un filtre"
        interface="popover"
        @ionChange="queryFilterSelected"
      >
        <ion-select-option
          v-for="filter in $store.state.queryFiltersList.basicQueryFilters"
          :key="filter"
          :value="filter"
          >{{ filter.displayName }}</ion-select-option
        >
        <ion-select-option
          v-for="filter in $store.state.queryFiltersList.groupQueryFilters"
          :key="filter"
          :value="filter"
          >{{ filter.displayName }}</ion-select-option
        >
      </ion-select> -->
        <div
          v-if="$store.state.selectedFilter"
          class="ion-no-padding"
          style="margin-top: 12px"
          lines="none"
        >
          <div
            v-if="$store.state.showSpeciesList"
            style="max-height: 100vh; width: 100%; overflow-y: scroll"
          >
            <search-species-component
              usedIn="requests"
            ></search-species-component>
          </div>

          <div
            v-if="$store.state.selectedFilter.displayName == 'habitation'"
            style="max-height: 50vh"
          >
            <ion-searchbar
              placeholder="filtrer liste des habitations"
              showCancelButton="false"
              debounce="0"
              v-model="habitationsInput"
            >
            </ion-searchbar>
            <ion-list
              class="habitations-selector"
              v-if="$store.state.uniqueHabitationNames"
            >
              <ion-item
                v-for="habitation in $store.state.uniqueHabitationNames"
                :key="habitation"
                @click="
                  $store.state.selectedFilter.enteredValue = habitation;
                  addToQueryFiltersList($store.state.selectedFilter);
                "
                v-show="
                  habitation.indexOf(habitationsInput) > -1 || !habitationsInput
                "
                >{{ habitation }}</ion-item
              >
            </ion-list>
          </div>
        </div>

        <!--     filters applied for query     -->
        <!--  <ion-item
        v-for="filter in $store.state.queryOptions.filters"
        :key="filter"
      >
        <ion-button
          @click="removeFromQueryFiltersList(filter)"
          fill="none"
          style="margin-left: 0px"
          ><ion-icon :icon="closeCircle" @click="removeFromQueryFiltersList(filter)" color="primary"></ion-icon
        ></ion-button>
        <ion-label>{{ filter.displayName }}</ion-label>
        <ion-input
          v-if="filter.displayValue"
          v-model="filter.displayValue"
          @click="editValue(filter)"
          color="primary"
        ></ion-input
        ><ion-input
          v-else
          v-model="filter.enteredValue"
          color="primary"
        ></ion-input>
      </ion-item> -->

        <!-- <ion-item v-if="$store.state.userGroupMembership === 'omhm'">
      <ion-label>Afficher habitations OMHM</ion-label>
      <ion-toggle slot="end"></ion-toggle>
    </ion-item> -->
      </ion-list>
      <ion-item
        v-if="
          $store.state.selectedFilter.displayName != 'espèce' &&
          $store.state.selectedFilter.displayName != 'habitation'
        "
      >
        <ion-button
          style="padding-bottom: 12px"
          slot="start"
          @click="
            addToQueryFiltersList($store.state.selectedFilter);
            $emit('fetchSpecimens', 'fitBounds');
          "
          fill="clear"
          >Appliquer</ion-button
        >
        <ion-button
          style="padding-bottom: 12px"
          slot="end"
          @click="$store.state.showQueryFilters = false"
          fill="clear"
          :color="'secondary'"
          >Fermer</ion-button
        >
      </ion-item>
    </div>
    <div @click="$store.state.showQueryFilters = false" style="width:100%;height: 50%; opacity: 0;top:0px"></div>

  </ion-content>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonList,
  IonRange,
  IonIcon,
  IonText,
  IonToggle,
  IonInput,
  IonButton,
  IonSearchbar,
} from "@ionic/vue";
import {
  personOutline,
  personCircle,
  search,
  ellipsisHorizontal,
  locate,
  checkmarkCircle,
  closeCircle,
  home,
  informationCircle,
  navigate,
  shuffle,
  star,
  checkmarkCircleOutline,
} from "ionicons/icons";
import { defineComponent, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import searchSpeciesComponent from "./searchSpeciesComponent.vue";
import firebaseService from "@/services/firebaseService";

export default defineComponent({
  name: "SubView1",
  components: {
    IonItem,
    IonLabel,
    IonList,
    IonRange,
    IonIcon,
    IonText,
    IonToggle,
    IonInput,
    searchSpeciesComponent,
    IonButton,
    IonSearchbar,
  },
  setup() {
    //console.log("setup function reached");
    // This is the equivalent of retrieving this.$store within a component using the Option API
    const store = useStore();

    onMounted(() => {
      //console.log("sidemenurequests component loaded");
    });

    const handleToggleChange = (filter) => {
      console.log(filter.enteredValue);
      if (!filter.enteredValue) {
        removeFromQueryFiltersList(filter);
      }
    };

    watch(
      () => store.state.selectedSpeciesFilter,
      () => {
        //console.log("watch selected filter");
        if (Object.keys(store.state.selectedSpeciesFilter).length) {
          store.state.queryFiltersList.basicQueryFilters.forEach((filter) => {
            //console.log(store.state.selectedSpeciesFilter.fieldName);
            // check if another classification field was already set.  If so, clear it
            if (
              filter.fieldName.indexOf("classification") > -1 &&
              filter.fieldName.indexOf(
                store.state.selectedSpeciesFilter.fieldName
              ) == -1
            ) {
              filter.enteredValue = null;
              filter.displayValue = null;
            }
            if (
              filter.fieldName.indexOf(
                store.state.selectedSpeciesFilter.fieldName
              ) > -1 ||
              filter.displayName == "classification"
            ) {
              filter.enteredValue =
                store.state.selectedSpeciesFilter.enteredValue;
              filter.displayValue =
                store.state.selectedSpeciesFilter.displayValue;
            }
            store.state.showSpeciesList = false;
          });
        }
      }
    );
    const selectedFilter = ref({ displayName: "", enteredValue: "" });
    let queriesListObj = {};
    store.state.queryOptions.filters.forEach((filter) => {
      //console.log("filter");
      //console.log(filter);
      queriesListObj[filter.displayName] = filter;
    });
    const habitationsInput = ref();
    const queryFilterSelected = (e) => {
      //console.log(e);
      //   selectedFilter.value = e;
      //console.log(selectedFilter.value);
      if (
        store.state.userGroupMembership == "omhm" &&
        store.state.selectedFilter.value &&
        store.state.selectedFilter.value.displayName == "habitation" &&
        !store.state.omhmAddressesFullList
      ) {
        // fetch all omhm addresses
        firebaseService().fetchOMHMAddresses(null, 1000000, false);
      }
    };
    const removeFromQueryFiltersList = (filter) => {
      //console.log(filter);

      if (queriesListObj[filter.displayName]) {
        delete queriesListObj[filter.displayName];
      }
      for (
        let index = 0;
        index < store.state.queryFiltersList.basicQueryFilters.length;
        index++
      ) {
        const currFilter =
          store.state.queryFiltersList.basicQueryFilters[index];
        if (currFilter.displayName == "classification") {
          // reset all classification related filters
          currFilter.displayValue = null;
          currFilter.enteredValue = null;
          store.state.queryFiltersList.basicQueryFilters.forEach(
            (basicQueryFilter) => {
              if (basicQueryFilter.fieldName.indexOf("classification") > -1) {
                basicQueryFilter.enteredValue = null;
                basicQueryFilter.displayValue = null;
              }
            }
          );
          store.commit("setSelectedSpeciesFilter", {
            displayValue: "",
            enteredValue: null,
          });
        }
        if (currFilter.displayName == filter.displayName) {
          // ion-input automatically parses null value as a 0 and prevents the placeholder to show when input type is number
          store.state.queryFiltersList.basicQueryFilters[index].enteredValue =
            filter.displayName == "année" ? NaN : null;
          store.state.queryFiltersList.basicQueryFilters[index].displayValue =
            null;
          break;
        }
      }
      for (
        let index = 0;
        index < store.state.queryFiltersList.groupQueryFilters.length;
        index++
      ) {
        const currFilter =
          store.state.queryFiltersList.groupQueryFilters[index];
        if (currFilter.displayName == filter.displayName) {
          // ion-input automatically parses null value as a 0 and prevents the placeholder to show when input type is number
          store.state.queryFiltersList.groupQueryFilters[index].enteredValue =
            null;
          store.state.queryFiltersList.groupQueryFilters[index].displayValue =
            null;
          break;
        }
      }
    };
    const addToQueryFiltersList = () => {
      store.state.queryFiltersList.basicQueryFilters.forEach((filter) => {
        // add to queriesListObj that is used to avoid adding a filter twice
        if (filter.displayName != "classification") {
          queriesListObj[filter.displayName] = filter;
        }
      });
      store.state.queryFiltersList.groupQueryFilters.forEach((filter) => {
        // add to queriesListObj that is used to avoid adding a filter twice
        queriesListObj[filter.displayName] = filter;
      });
      //console.log(queriesListObj);
      //console.log(store.state.selectedFilter);
      // add new filter
      /*  queriesListObj[store.state.selectedFilter.displayName] =
        store.state.selectedFilter.value; */
      // eslint-disable-next-line no-prototype-builtins
      /*       if (!queriesListObj[selectedFilter.value.displayName].hasOwnProperty("displayValue")){
        queriesListObj[selectedFilter.value.displayName].displayValue = selectedFilter.value.enteredValue;
      } */
      // push in an array for the view
      const keyNames = Object.keys(queriesListObj);
      store.commit("setFiltersListArrayInQueryOptions", []);
      const tempArray = [];
      keyNames.forEach((keyName) => {
        if (queriesListObj[keyName].enteredValue)
          tempArray.push(queriesListObj[keyName]);
      });
      store.commit("setFiltersListArrayInQueryOptions", tempArray);
      store.state.selectedFilter = { displayName: "", enteredValue: "" };
      store.state.showQueryFilters = false;
    };
    const editValue = (value) => {
      store.state.selectedFilter.value = value;
    };

    return {
      personOutline,
      personCircle,
      search,
      ellipsisHorizontal,
      locate,
      checkmarkCircle,
      closeCircle,
      home,
      informationCircle,
      navigate,
      shuffle,
      star,
      checkmarkCircleOutline,
      queryFilterSelected,
      selectedFilter,
      addToQueryFiltersList,
      removeFromQueryFiltersList,
      editValue,
      habitationsInput,
      handleToggleChange,
    };
  },
});
</script>
<style lang="css" scoped>
ion-item {
  font-size: small;
  /*   --background: transparent;
 */
  border-top: 1px solid --ion-color-secondary;
}

ion-range {
  padding: 0px;
}
</style>
