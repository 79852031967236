<template>
  <!--       Sélectionner espèce -->
  <ion-item
    class=""
    @click="setShowSearchInputElementsOFF"
    v-show="usedIn == 'specimenForm'"
  >
    <ion-label class="form-divider-title" style="max-width:85px">Espèce</ion-label>
    <ion-label class="ion-text-wrap" style="text-align: end; " v-if="$store.state.formattedSpecimen">{{
      $store.state.formattedSpecimen.nameFr
    }}</ion-label>
  </ion-item>
  <div v-show="showSearchInputElements">
    <ion-searchbar
      :animated="true"
      placeholder="entrez le nom de l'espèce"
      debounce="0"
      v-model="searchInput"
      @ionCancel="cancelSelectedSpecies"
      :style="{
        position: usedIn == 'specimenForm' ? 'relative' : 'fixed',
        'z-index': 1,
      }"
      :showCancelButton="usedIn == 'requests' ? 'always' : 'never'"
      cancelButtonText="Annuler"
    >
    </ion-searchbar>
    <ion-list
      v-if="searchInput"
      :style="{
        'padding-top': usedIn == 'specimenForm' ? '0px' : '56px',
        'margin-left': usedIn == 'specimenForm' ? '16px' : '0px',
      }"
    >
      <!-- <ion-item
        v-for="species in $store.state.allEssencesDictionaryAsArray"
        v-show="
          species.fr.name.toLowerCase().indexOf(searchInput.toLowerCase()) >
            -1 ||
          species.en.name.toLowerCase().indexOf(searchInput.toLowerCase()) >
            -1 ||
          species.la.toLowerCase().indexOf(searchInput.toLowerCase()) > -1
        "
        :key="species"
        @click="setChosenSpecies(species)"
        >{{ species.fr.name }}</ion-item
      > -->
      <ion-item
        v-for="species in $store.state.allEssencesDictionaryAsArray"
        v-show="
          species.fr.name.toLowerCase().indexOf(searchInput.toLowerCase()) >
            -1 ||
            species.fr.noDiacritics.toLowerCase().indexOf(searchInput.toLowerCase()) >
            -1
        "
        :key="species"
        @click="setChosenSpecies(species)"
        >{{ species.fr.name }}</ion-item
      >
    </ion-list>

    <ion-list
      v-if="!searchInput"
      :style="{
        'padding-top': usedIn == 'specimenForm' ? '0px' : '56px',
        'margin-left': usedIn == 'specimenForm' ? '16px' : '0px',
      }"
    >
      <ion-item
        v-for="species in $store.state.allEssencesDictionaryAsArray"
        :key="species"
        @click="setChosenSpecies(species)"
        >{{ species.fr.name }}
      </ion-item>
    </ion-list>
  </div>
</template>
<script>
import { leaf } from "ionicons/icons";
import {
  IonLabel,
  IonItem,
  IonSearchbar,
  IonList,
  modalController,
  alertController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import firebaseService from "../services/firebaseService";

export default defineComponent({
  name: "searchSpecies",
  props: {
    name: String,
    mapTypeId: String,
    zoom: Number,
    createOrModify: String,
    usedIn: String,
  },
  setup(props) {
    const store = useStore();

    //console.log(props.usedIn);
    const searchInput = ref(null);
    const selectedSpecies = ref();
    const showSearchInputElements = ref();
    showSearchInputElements.value =
      props.usedIn == "specimenForm" ? false : true;
    const setShowSearchInputElements = () => {
      showSearchInputElements.value = !showSearchInputElements.value;
    };
    const setChosenSpecies = (species) => {
      //console.log("setting chosen species");
      //console.log(species);
      selectedSpecies.value = species.fr.name;
      if (props.usedIn == "addSpecimen") {
        //console.log(props.usedIn);
        //console.log(species);
        if (
          store.state.userGroupMembership != "omhm" 
        ) {
          const radius = 10;
          const sameSpeciesInGivenRadius =
            firebaseService().checkForSameSpeciesInGivenRadius(
              species.code,
              radius
            );
          //console.log("sameSpeciesInGivenRadius");
          //console.log(sameSpeciesInGivenRadius);
          sameSpeciesInGivenRadius.then((res) => {
            //console.log(res);
            if (res.length > 0) {
              const vernaculaire =
                store.state.allEssencesDictionary[species.code.slice(0, 2)];
              const plural = res.length > 1 ? "s " : " ";
              const confirmAddSpecimen = async () => {
                const alert = await alertController.create({
                  cssClass: "my-custom-class",
                  header:
                    "Il y a déjà " +
                    res.length +
                    " autre" +
                    plural +
                    vernaculaire.fr.name.toLowerCase() +
                    plural +
                    "à moins de " +
                    radius +
                    " mètres de vous.",
                  message:
                    "Êtes-vous certain que l'arbre que vous souhaitez ajouter n'est pas déjà géoréférencé?",
                  buttons: [
                    {
                      text: "Annuler",
                      role: "cancel",
                      cssClass: "secondary",
                      id: "cancel-button",
                      handler: () => {
                        //console.log("before dismissing modal");
                        modalController.dismiss();
                      },
                    },
                    {
                      text: "Poursuivre le géoréférencement",
                      id: "confirm-button",
                      handler: () => {
                        store.dispatch("formatNewSpecimen").then(function () {
                          //     store.state.openNewSpecimenDetailsForm(species);
                          //console.log("before dismissing modal");
                          modalController.dismiss();
                          store.state.goToAddNewSpecimen(species, true);
                          //   store.state.breakPointRelativeToContent = 0.9;
                        });
                      },
                    },
                  ],
                });
                return alert.present();
              };
              confirmAddSpecimen();
            } else {
              store.dispatch("formatNewSpecimen").then(function () {
                //     store.state.openNewSpecimenDetailsForm(species);
                //console.log("before dismissing modal");
                modalController.dismiss();
                store.state.goToAddNewSpecimen(species, true);
                /* setTimeout(()=>{
                  store.commit("setShowCleAddNewSpecimen", true);
                },1000) */
                //   store.state.breakPointRelativeToContent = 0.9;
              });
            }
          });
        } else {
          store.dispatch("formatNewSpecimen").then(function () {
            store.state.openNewSpecimenDetailsForm(species);
            //console.log("before dismissing modal");
            modalController.dismiss();
            store.state.breakPointRelativeToContent = 0.9;
          });
        }
      } else if (props.usedIn == "specimenForm") {
        store.state.formattedSpecimen.essence = species.code;
        store.state.formattedSpecimen.nameFr = species.fr.name;
      } else {
        // update temp filter for ui
        store.state.queryFiltersList.basicQueryFilters.forEach((filter) => {
          if (filter.displayName == "classification") {
            filter.enteredValue = species.code;
          }
        });
        // push in queryFiltersList
        if (species.code.length == 2) {
          store.commit("setSelectedSpeciesFilter", {
            fieldName: "geojsonFeature.properties.classification.genus",
            operator: "==",
            enteredValue: species.code,
            type: "string",
            displayName: "genre",
            displayValue:
              store.state.allEssencesDictionary[species.code].fr.name,
          });
        } else if (species.code.length == 4) {
          store.commit("setSelectedSpeciesFilter", {
            fieldName: "geojsonFeature.properties.classification.species",
            operator: "==",
            enteredValue: species.code,
            type: "string",
            displayName: "espèce",
            displayValue:
              store.state.allEssencesDictionary[species.code].fr.name,
          });
        } else {
          store.commit("setSelectedSpeciesFilter", {
            fieldName: "geojsonFeature.properties.classification.cultivar",
            operator: "==",
            enteredValue: species.code,
            type: "string",
            displayName: "cultivar",
            displayValue:
              store.state.allEssencesDictionary[species.code].fr.name,
          });
        }
      }
      //  store.commit("setFormattedSpecimen", formattedSpecimen);
      showSearchInputElements.value = false;
    };
    const cancelSelectedSpecies = () => {
      if (props.usedIn != "requests") {
        //console.log("usedIn addSpecimen");
        //  modalController.dismiss();
      } else {
        //console.log("usedIn not addSpecimen");

        showSearchInputElements.value = false;
        store.commit("setSelectedFilter", {
          displayName: null,
          enteredValue: null,
        });
        store.state.showSpeciesList = false;
      }
    };
    /* const handleKeyboardOnIphone = () => {
    //console.log(isPlatform("iphone"));
      store.commit("setShowKeyboard", isPlatform("iphone") ? true : false);
    }; */
    return {
      searchInput,
      selectedSpecies,
      showSearchInputElements,
      setShowSearchInputElements,
      setChosenSpecies,
      leaf,
      cancelSelectedSpecies,
      /*       handleKeyboardOnIphone,
       */
    };
  },
  components: {
    IonLabel,
    IonItem,
    IonSearchbar,
    IonList,
  },
});
</script>
<style scoped>
ion-list {
  overflow-y: scroll;
}
</style>
