<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="closeModal()">FERMER</ion-button>
        </ion-buttons>
        <ion-item style="--background:transparent"><span style="margin: auto;">Reconnaissance visuelle par
            <b>PlantNet</b></span></ion-item>
      </ion-toolbar>
      <ion-progress-bar v-show="showProgressBar" type="indeterminate"></ion-progress-bar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-item v-show="!plantNetResponse">
        <ion-label class="ion-text-wrap">
          Prendre en photo les caractéristiques de l'arbre (feuilles, fleurs, fruits, écorce, etc). Deux photos minimum.
          <br> <a @click="showPlantNetTips = true">Conseils supplémentaires</a>
        </ion-label>
      </ion-item>
      <div v-if="plantNetResponse">
        <ion-list v-for="result in plantNetResponse.results.slice(0, 5)" :key="result" class="plant-net-result">
          <ion-item @click="$store.state.openPicturesSlider(0, result.images, result)" lines="none">
            <ion-label>
              <b>{{ result.species.scientificNameWithoutAuthor }}</b>
            </ion-label>
            <ion-label slot="end" class="ion-text-wrap">
              Certitude {{ Math.round(result.score * 100) }}%
            </ion-label>
            <!-- <ion-button
              slot="end"
              :disabled="result.score < 0.03"
              @click="choosePlantNetResult(result)"
            >
              Choisir
            </ion-button> -->
          </ion-item>
          <!-- <ion-item @click="$store.state.openPicturesSlider(0, result.images, result)" v-show="vernacularNamesInFrench(result.gbif.vernacular)">  
            <ion-label>{{ vernacularNamesInFrench(result.gbif.vernacular) }}</ion-label>
          </ion-item> -->
          <ion-item v-show="result.species.commonNames.length > 0"
            @click="$store.state.openPicturesSlider(0, result.images, result)">
            <ion-label style="margin-top:0px;margin-bottom:4px">{{ result.species.commonNames.join() }}</ion-label>
          </ion-item>
          <ion-item>
            <ion-img v-for="(image, index) in result.images" :key="image" :src="image.url.s"
              @click="$store.state.openPicturesSlider(index, result.images, result)">
            </ion-img>
          </ion-item>
        </ion-list>
        <ion-item>
          <ion-button @click="plantNetResponse = false">Recommencer</ion-button>
        </ion-item>
      </div>
      <!-- <specimen-basic-infos></specimen-basic-infos> -->
      <photos-component v-show="!plantNetResponse" container="VisualRecognition"></photos-component>
      <div v-show="!waitingForPlantNetResponse && !plantNetResponse" class="ion-padding">
        <ion-button @click="identify()" expand="block" style="max-width: 300px; margin: auto"
          :disabled="Object.keys($store.state.pictureSet).length < 2">Identifier</ion-button>
      </div>
      <div v-show="waitingForPlantNetResponse">
        <ion-button @click="closeModal()" color="danger" slot="end">Annuler
        </ion-button>
        <ion-progress-bar type="indeterminate"></ion-progress-bar>
        <ion-item><ion-label>En attente de la réponse de PlantNet</ion-label></ion-item>
      </div>
    </ion-content>
    <ion-modal :is-open="showPlantNetTips" :showBackdrop="true" :backdropDismiss="true"
      @ionModalDidDismiss="showPlantNetTips = false">
      <ion-header>
        <ion-toolbar>
          <!--           <ion-label>Conseils photos plantNet</ion-label>
 --> <ion-button @click="closeModal()" slot="end">FERMER</ion-button>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-img :src="'../assets/images/plantNetPictureTips.png'" style="padding-bottom: 60px">
        </ion-img>
      </ion-content>
    </ion-modal>
    <ion-modal id="picturesSliderModal" :is-open="$store.state.showPicturesSlider" @didDismiss="$store.state.showPicturesSlider = false">
      <PicturesSlider :embedded="false" @choose-plant-net-result="choosePlantNetResult"></PicturesSlider>
    </ion-modal>
  </ion-page>
</template>

<script>
import {
  modalController,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonContent,
  IonItem,
  IonLabel,
  IonImg,
  IonList,
  IonProgressBar,
  IonModal,
} from "@ionic/vue";
import { ref, defineComponent } from "vue";
import { useStore } from "vuex";
import { usePhoto } from "@/composables/usePhoto";
// the detail page
import PhotosComponent from "./PhotosComponent.vue";
import PicturesSlider from "./PicturesSlider.vue";

export default defineComponent({
  name: "VisualRecognitionAddNewSpecimen",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonContent,
    PhotosComponent,
    IonItem,
    //SpecimenBasicInfos,
    IonProgressBar,
    IonModal,
    IonImg,
    PicturesSlider
  },
  props: ["modalNav"],
  setup(props) {
    const store = useStore();
    const plantNetResponse = ref();
    const waitingForPlantNetResponse = ref(false);
    const showPlantNetTips = ref(false);
    const showProgressBar = ref(false);
    const vernacularNamesInFrench = (items) => {
      if (items && items.filter((name) => name.language == 'fra')[0]) {
        return items.filter((name) => name.language == 'fra')[0].vernacularName + " (fra)";
      } else {
        return null
      }
    };
    const vernacularNamesInEnglish = (items) => {
      if (items.filter((name) => name.language == 'eng')[0])
        console.log(items);
      console.log(items.filter((name) => name.language == 'eng')[0]);
      return items.filter((name) => name.language == 'eng')[0].vernacularName + " (eng)";
    };

    const { uploading, savePicture } = usePhoto();
    const choosePlantNetResult = (result) => {
      console.log("reached choosePlantNet result function");
      // show progress bar
      showProgressBar.value = true;
      console.log(result);
      // generate speciesCode from the name provided by plantNet
      // split provided name into single words
      const speciesWords =
        result.species.scientificNameWithoutAuthor.split(" ");
      let speciesCode = "";
      speciesWords.forEach((word) => {
        // keep the first two letters of each word and concatenate.
        speciesCode += word.slice(0, 2);
        //console.log(speciesCode.toUpperCase());
      });

      /*       // check if another specimen of the same species is already in the user surrounding
      // only keep the first to letters of the speciesCode to check for genus
      const reducedSpeciesCode = speciesCode.slice(0, 2);
    //console.log("reducedSpeciesCode: " + speciesCode.toUpperCase());
      if (firebaseService().checkForSameSpeciesInGivenRadius(speciesCode.toUpperCase(), 10)) {
        alert("another was found");
        return null;
      } */

      store.dispatch("formatNewSpecimen").then(function () {
        // add property to store plantNet's data
        store.state.formattedSpecimen.plantNet = {};
        // try to find speciesCode in Branche's inventory
        let matchFound = false;
        for (let index = 0; index < store.state.allEssencesDictionaryAsArray.length; index++) {
          const item = store.state.allEssencesDictionaryAsArray[index];
          if (item.gbifInfos.gbif_id == result.gbif.id) {
            matchFound = true;
            // slice the code to last 4 digits because gbif doesnt provide the cultivar
            store.state.formattedSpecimen.essence = item.code.slice(0,4);

           //store.state.formattedSpecimen.nameFr = item.fr.name;
            break;
          }
        }
        if (!matchFound) {
          // speciesCode wasnt found, add a flag on the specimen
          store.state.formattedSpecimen.plantNet.speciesWasNotFoundInBranche = true;
         /*  store.state.formattedSpecimen.nameFr =
            result.species.scientificNameWithoutAuthor; */
          // add an underscore before essenceCode to prevent the system to match a two letter treeicon with a species that is not in Branche
          store.state.formattedSpecimen.essence = '_' + speciesCode.toUpperCase();
        }
        // add GBIF ID
        store.state.formattedSpecimen.plantNet.gbif_id = result.gbif.id;
        store.state.formattedSpecimen.plantNet.scientificNameWithoutAuthor =
          result.species.scientificNameWithoutAuthor;
        store.state.formattedSpecimen.plantNet.score = result.score;
        store.state.formattedSpecimen.plantNet.genus = result.species.genus.scientificNameWithoutAuthor;
        store.state.formattedSpecimen.plantNet.commonNames = result.species.commonNames;
        // next property is for querying all plantNetIdentifications using firestore
        store.state.formattedSpecimen.plantNetIdentification = true;
        store.state.formattedSpecimen.owner = store.state.user.displayName;
        store.state.formattedSpecimen.plant_date = new Date().toISOString();

        // save pictures on remote and store urls on specimen
        const pictures = Object.values(store.state.pictureSet);
        if (pictures.length === 0) {
          console.error("choose a file");
          alert("aucune image n'a été trouvée");
          return false;
        }
        const pictureSetUploaded = [];
        const tempPromiseObj = {};
        for (let i = 0; i < pictures.length; i += 1) {
          //console.log(pictures[i]);
          tempPromiseObj[i] = savePicture(
            pictures[i].picture,
            new Date().getTime() + "_" + i + ".jpg",
            pictures[i].treePart,
            "nocontainer"
          );
          pictureSetUploaded.push(tempPromiseObj[i]);
        }
        async function runAllPromises() {
          await Promise.all(pictureSetUploaded).then(() => {
            //console.log(pictureSetUploaded);
            //console.log("saving on remote");
            // save on remote
            store
              .dispatch(
                "formatSpecimenForServer",
                store.state.formattedSpecimen
              )
              .then(async function (result) {
                console.log("specimen  saved on server");
                //console.log("store.state.formattedSpecimen");
                //console.log(store.state.formattedSpecimen);
                // remove watch position because geolocalization is done
                //      mapService.clearGetCurrentPositionSetInterval();
                //console.log(result);
                //    loader.hide();
                store.commit("setRepositionSpecimen", false);
                // hide progress bar
                showProgressBar.value = false;
                // close visual recognition modal
                console.log("closing modal");
                await modalController.dismiss();
              })
              .catch(function (error) {
                alert("La sauvegarde du spécimen a échouée.  Veuillez réessayer plus tard.");
                //     loader.hide();
                console.error("Error writing firestore document: ", error);
              });
          });
        }
        runAllPromises();
      });
    };
    const identify = async () => {
      waitingForPlantNetResponse.value = true;
      // send to plantNet
      const PROJECT = "all"; // try 'weurope', 'canada'…
      const API_URL = "https://my-api.plantnet.org/v2/identify/" + PROJECT;

      // to make this example work you have to expose your API key and
      // authorize your webserver address in "Authorized domains" section
      // see https://my.plantnet.org/account/doc#exposekey
      const API_KEY = "2b10F06no4KLOpjkCi2FuexBQ";
      // 1. Get the file from an input type=file :
      // const fileInput = document.getElementById('picture');

      const images = Object.values(store.state.pictureSet);
      if (images.length === 0) {
        console.error("choose a file");
        return false;
      }

      // 2. Build POST form data
      const form = new FormData();
      for (let i = 0; i < images.length; i += 1) {
        //console.log(images[i]);
        // form.append('organs', images[i].treePart);
        form.append("organs", "auto");
        form.append("images", images[i].picture);
      }

      // 3. Add GET URL parameters
      const url = new URL(API_URL);
      url.searchParams.append("include-related-images", "true"); // try false
      url.searchParams.append("api-key", API_KEY);
      url.searchParams.append("lang", 'fr');
      //console.log(form);
      for (const p of form) {
        //console.log(p);
      }
      // 4. Send request
      fetch(url.toString(), {
        method: "POST",
        body: form,
      })
        .then((response) => {
          if (response.ok) {
            response
              .json()
              .then(async (r) => {
                //console.log(JSON.stringify(r));
                uploading.value = false;
                plantNetResponse.value = r;
                waitingForPlantNetResponse.value = false;
                /* // fetch name in french from gbif db
                const gbifPromises = [];
                const gbifResults = [];
                plantNetResponse.value.results.forEach((result) => {
                  const gbifID = result.gbif.id;
                  const urlForGbif = new URL('https://api.gbif.org/v1/species/' + gbifID + '/vernacularNames');
                  gbifPromises.push(fetch(urlForGbif.toString()));
                })
                const gbifResponses = await Promise.all(gbifPromises);
                gbifResponses.forEach(async (response) => {
                  const parsedResult = await response.json();
                  console.log(plantNetResponse.value.results);
                  // put back into plantNet result
                  for (
                    let index = 0;
                    index < plantNetResponse.value.results.length;
                    index++
                  ) {
                    if (parsedResult.results[0] && plantNetResponse.value.results[index].gbif.id == parsedResult.results[0].taxonKey) {
                      plantNetResponse.value.results[index].gbif.vernacular = parsedResult.results;
                      break;
                    }
                  } 
                  gbifResults.push(parsedResult);
                })
                console.log('gbifResults');
                console.log(gbifResults); */
              })
              .catch((err) => {
                console.error(err);
                uploading.value = false;
              });
          } else {
            const resp = `status: ${response.status} (${response.statusText})`;
            //console.log(resp);
            uploading.value = false;
            if (resp.indexOf('404') > -1) {
              alert("Aucune espèce n'a été trouvée pour les images que vous avez fournies.");
              waitingForPlantNetResponse.value = false;
            }
          }
        })
        .catch((error) => {
          console.error(error);
          uploading.value = false;
        });
    };
    /**
     *  when going to the next page, I pass the nav as a property
     * so I don't need to get it from the document again
     */
    const nextPage = (componentName) => {
      store.state.modalNav.value.push(componentName, {
        // these come across as properties on the component
        modalNav: store.state.modalNav,
      });
    };
    /**
     * close the modal dialog
     */
    const closeModal = async () => {
      console.log("reached closeMOdal function");
      if (plantNetResponse.value) {
        plantNetResponse.value = false;
        return
      } else {
        store.state.showPicturesSlider = false;
        await modalController.dismiss();
      }
    };

    const goBack = () => {
      const nav = props.modalNav;
      nav.pop();
    };

    return {
      nextPage,
      closeModal,
      goBack,
      identify,
      plantNetResponse,
      waitingForPlantNetResponse,
      choosePlantNetResult,
      showProgressBar,
      showPlantNetTips,
      vernacularNamesInEnglish,
      vernacularNamesInFrench
    };
  },
});
</script>
