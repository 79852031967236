/* eslint-disable no-prototype-builtins */
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { IonicVue } from "@ionic/vue";
import {
  indexedDBLocalPersistence,
  initializeAuth,
  getAuth,
  onAuthStateChanged,
} from "firebase/auth";
import { Capacitor } from "@capacitor/core";
/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";
/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";
/* Theme variables */
import "./theme/variables.css";
//import dotenv from "dotenv"
//dotenv.config();
import ProfileService from "./services/ProfileService.js";
import store from "./store";
//console.log(store);
//import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import mapService from "./services/mapService";
import { Keyboard } from "@capacitor/keyboard";
console.log("main.ts");
function whichAuth() {
  let auth;
  if (Capacitor.isNativePlatform()) {
    auth = initializeAuth(store.state.firebaseApp, {
      persistence: indexedDBLocalPersistence,
    });
    Keyboard.addListener("keyboardDidHide", () => {
      //console.log('keyboard did hide');
      store.state.mapboxMap.resize();
    });
  } else {
    auth = getAuth();
  }
  return auth;
}
let auth;
setTimeout(() => {
  auth = whichAuth();
  store.commit("setAuth", auth);
  onAuthStateChanged(auth, (user) => {
    console.log("auth state changed");
    console.log(user);
    if (user !== null) {
      //console.log("uID: " + user.uid)
      //console.log("user's email address: " + (user.email ? user.email : "N/A"));
      //   //console.log("user's picture: " + (user.profileImageURL ? user.profileImageURL : "N/A"));
  
      //console.log("setIsLogged in commited to store");
      user
        .getIdTokenResult()
        .then((idTokenResult: any) => {
          //  //console.log(idTokenResult.claims.groupMembership);
          if (idTokenResult.claims.hasOwnProperty("groupMembership")) {
            store.commit("setUser", user);
            store.commit("setIsLoggedIn", true);
            //console.log("Specific custom claim retrieved: " + idTokenResult.claims.groupMembership[0]); // or result.claims["yourClaimKey"]
            store.commit(
              "setUserGroupMembership",
              idTokenResult.claims.groupMembership[0]
            );
            store.commit(
              "setUserReadOnly",
              idTokenResult.claims.hasOwnProperty("readOnly")
                ? idTokenResult.claims.readOnly
                : false
            );
            store.commit(
              "setUserEditOnly",
              idTokenResult.claims.hasOwnProperty("editOnly")
                ? idTokenResult.claims.editOnly
                : false
            );
            store.commit(
              "setUserInfosToDisplay",
              idTokenResult.claims.editOnly
                ? idTokenResult.claims.groupMembership[0] +
                    " " +
                    idTokenResult.claims.editOnly
                : idTokenResult.claims.groupMembership[0]
            );
            //   store.commit("setUserDisplayName", idTokenResult.claims.editOnly ? idTokenResult.claims.editOnly : idTokenResult.claims.groupMembership[0]);
            if (idTokenResult.claims.groupMembership[0] == "omhm") {
              // set userName instead of groupName
              store.commit("setUserDisplayName", idTokenResult.claims.name);
            }
            ProfileService.groupRelatedSetup(
              idTokenResult.claims.groupMembership[0]
            );
          } else {
            if (
              user.providerData[0].providerId == "password" &&
              !user.emailVerified
            ) {
              store.commit("setUser", user);
              store.commit("setUserGroupMembership", "anonyme");
              ProfileService.groupRelatedSetup("anonyme");
              return;
            }
            store.commit("setUser", user);
            store.commit("setIsLoggedIn", true);
            store.commit("setUserGroupMembership", "singleUser");
            //console.log("store.state.user.displayName");
            //console.log(store.state.user.displayName);
            //  if (store.state.user.displayName) {
            store.commit("setUserInfosToDisplay", store.state.user.displayName);
            store.commit("setUserDisplayName", store.state.user.displayName);
            ProfileService.groupRelatedSetup("singleUser");
            //  }
          }
        })
        .catch((error) => {
          //console.log(error);
        });
    } else {
      //console.log("user has logged out ");
      // fill email prop with previous user
      const prevUserEmail = JSON.parse(JSON.stringify(store.state.user.email));
      store.commit("setUser", {
        email: prevUserEmail,
        displayName: null,
        password: "",
      });
      store.commit("setIsLoggedIn", false);
      store.commit("setUserGroupMembership", "anonyme");
      store.commit("setUserReadOnly", false);
      store.commit("setUserEditOnly", false);
      store.commit("setUserInfosToDisplay", "anonyme");
      ProfileService.groupRelatedSetup("anonyme");
    }
  });
}, 2000);

// Use matchMedia to check the user preference
const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");

toggleDarkTheme(prefersDark.matches);

// Listen for changes to the prefers-color-scheme media query
prefersDark.addListener((mediaQuery) => toggleDarkTheme(mediaQuery.matches));

// Add or remove the "dark" class based on if the media query matches
function toggleDarkTheme(shouldAdd: any) {
  //console.log("toggle dark theme");
  document.body.classList.toggle("dark", shouldAdd);
  if (store.state.mapboxMap) {
    if (shouldAdd) {
      store.state.currentMapStyle = "dark-v11";
      mapService.setMapStyle("dark-v11");
      /* store.state.mapboxMap.setConfigProperty(
        store.state.currentMapStyle,
        "lightPreset",
        "night"
      ); */
    } else {
      //store.state.mapboxMap.setConfigProperty(store.state.currentMapStyle, "lightPreset", "day");
      store.state.currentMapStyle = "light-v11";
      mapService.setMapStyle("light-v11");
    }
  }
}

const app = createApp(App)
  .use(IonicVue, {
    swipeBackEnabled: false,
    animated: true,
  })
  .use(router)
  .use(store);

router.isReady().then(() => {
  app.mount("#app");
  defineCustomElements(window);
});
