<template>
  <ion-item
    v-if="$store.state.formattedSpecimen"
    lines="full"
    class="ion-no-padding"
  >
    <ion-thumbnail slot="start" class="thumbnail-item-for-nearest-specimen">
      <img
        :src="
          '../assets/arbres/' +
          $store.state.formattedSpecimen.essence +
          '_feuille.png'
        "
        @error="imageLoadOnError"
      />
    </ion-thumbnail>
    <ion-label class="ion-text-wrap">
      <h1 style="width: calc(100% - 20px);">
        {{ $store.state.formattedSpecimen.nameFr }}
      </h1>
      <em> {{ $store.state.formattedSpecimen.nameLa }} </em>
      <h4>{{ $store.state.returnOwner() }}</h4>
      <h4 v-if="$store.state.formattedSpecimen.plant_date">
        identfié le:
        {{ $store.state.formattedSpecimen.plant_date.slice(0, 10) }}
        <b
          v-if="
            $store.state.returnPlantationYear({
              properties: $store.state.formattedSpecimen,
            })
          "
          style="float: right; margin-right: 6px"
          >planté en
          {{
            $store.state.returnPlantationYear({
              properties: $store.state.formattedSpecimen,
            })
          }}</b
        >
      </h4>
      <!--   <h4>ID: {{ $store.state.formattedSpecimen._id ? $store.state.formattedSpecimen._id : '' }}</h4> -->
    </ion-label>
  </ion-item>
</template>

<script>
import { IonItem, IonLabel, IonThumbnail } from "@ionic/vue";
import { defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "SpecimenBasicInfos",
  props: {
    title: { type: String, default: "Infos générales" },
  },
  data() {
    return {
      content: "Content",
    };
  },
  setup() {
    const imageLoadOnError = (e) => {
      store.state.fallbackIndex++;
      console.log(store.state.fallbackIndex);
      if (store.state.fallbackIndex > 3) {
        return;
      }
      if (store.state.fallbackIndex == 1) {
        e.target.src =
          "../assets/arbres/" +
          store.state.formattedSpecimen.essence.slice(0, 4) +
          "_feuille.png";
      } else if (store.state.fallbackIndex == 2) {
        e.target.src =
          "../assets/arbres/" +
          store.state.formattedSpecimen.essence.slice(0, 2) +
          "_feuille.png";
      } else {
        e.target.src = "../assets/arbres/XX_feuille.png";
        store.state.fallbackIndex = 0;
      }
    };
    const store = useStore();
    return {
      imageLoadOnError,
      // store,
    };
  },
  components: {
    IonItem,
    IonLabel,
    IonThumbnail,
  },
});
</script>
