<template>
  <ion-page
    :style="{
      'margin-top': keyboardIsDisplayed ? '62px' : '0px',
    }"
  >
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="closeModal">FERMER</ion-button>
        </ion-buttons>
        <ion-title>Répertoire</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <search-species-component
        :usedIn="addSpecimen"
      ></search-species-component>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  modalController,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
} from "@ionic/vue";
import { ref, defineComponent } from "vue";
import { useStore } from "vuex";

import searchSpeciesComponent from "./searchSpeciesComponent.vue";

export default defineComponent({
  name: "ListeEspecesHome",
  props: ["addSpecimen"],
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonTitle,
    IonContent,
    searchSpeciesComponent,
  },
  setup() {
    const store = useStore();
    /**
     * close the modal dialog
     */
    const closeModal = async () => {
      setTimeout(() => {
        store.state.mapboxMap.resize();
      }, 1000);
      await modalController.dismiss();
    };

    let keyboardIsDisplayed = ref(false);
    /* 
    Keyboard.addListener("keyboardWillShow", (info) => {
    //console.log("keyboard will show with height:", info.keyboardHeight);

    });

    Keyboard.addListener("keyboardDidShow", (info) => {
    //console.log("keyboard did show with height:", info.keyboardHeight);
      keyboardIsDisplayed.value = true;

    });

    Keyboard.addListener("keyboardWillHide", () => {
    //console.log("keyboard will hide");
      keyboardIsDisplayed.value = false;
    //  store.state.mapboxMap.resize();

    });

    Keyboard.addListener("keyboardDidHide", () => {
    //console.log("keyboard did hide");
      store.state.mapboxMap.resize();

    }); */

    return {
      closeModal,
      keyboardIsDisplayed,
    };
  },
});
</script>
