<template>
  <div v-if="$store.state.nearestSpecimen.distanceFromUser">
    <!-- <ion-modal
      :is-open="$store.state.showNearestSpecimenModal"
      :breakpoints="[
        0,
        $store.state.initialBreakpointForNearestSpecimen,
        $store.state.secondaryBreakpointForNearestSpecimen,
        0.9,
      ]"
      :initialBreakpoint="$store.state.initialBreakpointForNearestSpecimen"
      showBackdrop="false"
      backdropDismiss="false"
      :backdrop-breakpoint="1"
      @ionModalDidDismiss="nearestSpecimenModalDismissed()"
      class="modal-content-nearest-specimen"
    >
    </ion-modal> -->
    <modal-component></modal-component>
  </div>
</template>

<script>
import /*   IonHeader,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonThumbnail,
  IonLabel,
  IonItem,
  IonButton,*/
// IonModal,
"@ionic/vue";
import { defineComponent } from "vue";
import { useStore } from "vuex";
import ModalComponent from "@/components/nearestSpecimenModal.vue";

export default defineComponent({
  name: "NearestSpecimen",
  props: {
    title: { type: String, default: "Près de vous" },
  },
  data() {
    return {
      content: "Content",
    };
  },
  setup() {
    const store = useStore();
    //console.log(store.state.showNearestSpecimenModal);
    /* const showSpecimenDetailsInSideMenu = () => {
      store.commit("setSideMenuDisabled", false);
      store.commit("setCurrentSegment", "specimen");
      store.commit(
        "setChosenSpecimenMarkerFirestoreId",
        store.state.nearestSpecimen.geojsonFeature.properties.firestoreId
      );
      store.commit(
        "setFetchedSpecimen",
        store.state.fetchedSpecimensObj[
          store.state.chosenSpecimenMarkerFirestoreId
        ]
      );
      store
        .dispatch(
          "formatFetchedSpecimen",
          store.state.fetchedSpecimensObj[
            store.state.chosenSpecimenMarkerFirestoreId
          ].geojsonFeature.properties
        )
        .then(function () {
          //
        });
    }; */

    const nearestSpecimenModalDismissed = () => {
      //console.log("nearest specimen modal dismissed");
      store.commit("setShowNearestSpecimenModal", false);
      store.commit("setShowNewSpecimenModal", false);
      store.commit("setShowRefreshMap", false);
      //    store.commit("setActivatedTabQuerySelector", ".show-nearest-specimen");
      //   mapService.clearGetCurrentPositionSetInterval();
      setTimeout(() => {
        //console.log("resizing map");
        store.state.mapboxMap.resize();
      }, 20);
    };
    let nearestSpecimenSheetModal;
    /* const openModal = async () => {
      nearestSpecimenSheetModal = await modalController.create({
        component: Modal,
        initialBreakpoint: store.state.initialBreakpointForNearestSpecimen,
        breakpoints: [
          0,
          store.state.initialBreakpointForNearestSpecimen,
          store.state.secondaryBreakpointForNearestSpecimen,
          1,
        ],
        showBackdrop: true,
        backdropDismiss: false,
        ionModalDidDismiss: test("dismissed"),
        swipeToClose: true,
      });
      store.commit("setNearestSpecimenSheetModal", nearestSpecimenSheetModal);

      return nearestSpecimenSheetModal.present();
    }; */

    /* onMounted(() => {
      setTimeout(() => {
        openModal();
      }, 5);
    }); */

    return {
      //showSpecimenDetailsInSideMenu,
      nearestSpecimenModalDismissed,
    };
  },
  components: {
    ModalComponent,
  },
});
</script>
