<template>
  <div class="cle-identification-root-page">
    <ion-nav :root="rootPage" id="modal-nav"></ion-nav>
  </div>
</template>

<script>
import {
  IonNav
} from "@ionic/vue";
import { defineComponent } from "vue";
import { checkmarkCircleOutline, checkmarkOutline } from "ionicons/icons";

export default defineComponent({
  name: "CleIdentificationEspecesComponent",
  props: ["rootPage"],
  components: {
    IonNav
  },
  setup() {
    
    return {
      checkmarkCircleOutline,
      checkmarkOutline,
      innerHeight,
    };
  },
});
</script>

<style scoped>
/* ion-accordion {
  border-top: 1px solid var(--ion-text-color);
background-color: var(--ion-background-color)
} */
/* ion-accordion::part(content) {  
  background-color: rgb(49, 192, 144);
} */
ion-item {
  /*   --background: transparent;
 */
  border-top: 1px solid var(--ion-color-step-500);
  cursor: pointer;
}

ion-list {
  background-color: var(--ion-color-step-150);
  padding: 0px;
}
</style>