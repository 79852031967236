<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="closeModal">FERMER</ion-button>
        </ion-buttons>
        <ion-buttons slot="start">
          <ion-button v-if="levels.length > 0" @click="goBackward()">RETOUR</ion-button>
        </ion-buttons>
        <ion-title v-show="levels.length < 1">Clé d'identification</ion-title>
        <div style="margin-left: 24px">
          <ion-label v-for="level in levels" :key="level">{{ level.title }} /
          </ion-label>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-item v-for="(item, index) in currentLevel" v-show="!item.essenceCode" :key="item"
        @click="goForward(item, index)" class="cle-identification-items ion-no-padding" :button="true">
        <img slot="start" :src="'../assets/arbres/' + item.imageName + '_feuille.png'" class="cle-identification-img" @error="$store.state.imageLoadOnError"/>
        <ion-label>
          <h1 class="ion-text-wrap">{{ item.title }}</h1>
          <span class="ion-text-wrap">{{ item.description }}</span>
        </ion-label>
      </ion-item>
      <ion-item v-for="item in currentLevel" v-show="item.essenceCode" :key="item"
        @click="$store.state.goToAddNewSpecimen(item)" class="cle-identification-items ion-no-padding" :button="false">
        <img slot="start" :src="'../assets/arbres/' + item.imageName + '_feuille.png'" class="cle-identification-img" @error="$store.state.imageLoadOnError"/>
        <ion-label>
          <h1 class="ion-text-wrap">{{ item.title }}</h1>
          <span class="ion-text-wrap">{{ item.description }}</span>
        </ion-label>
      </ion-item>
      <!--  <ion-item
        @click="nextPage(CleAiguillesEcaillles)"x
        class="cle-identification-items"
        button=""
      >
        <img
          slot="start"
          :src="'../assets/arbres/PI_feuille.png'"
          class="cle-identification-img"
        />
        <ion-label slot="end">
          <h1>Conifères</h1>
          <span class="ion-text-wrap"
            >Les conifères ont des feuilles en forme d’aiguille ou d’écaille et
            portent leurs graines dans un cône (on l’appelle souvent une
            cocotte).</span
          >
        </ion-label>
      </ion-item> -->
    </ion-content>
  </ion-page>
</template>

<script>
import {
  modalController,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
  createGesture
} from "@ionic/vue";
import { ref, onMounted, defineComponent } from "vue";
import { useStore } from "vuex";
import firebaseService from "../services/firebaseService";

// the detail page

export default defineComponent({
  name: "CleIdentificationHome",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonTitle,
    IonContent,
  },
  setup() {
    const store = useStore();
    const { getCollectionData } = firebaseService();
    //const cleIdentificationRef = doc(db, options.collectionName, options.docName)
    const cleIdentification = getCollectionData({
      collectionName: "cleIdentificationCategories"
    });
    cleIdentification.then((res) => {
      //console.log(res);
      store.state.cleIdentification = res;
      currentLevel.value = JSON.parse(
        JSON.stringify(store.state.cleIdentification)
      );
    });
    const returnFallbackImage = (e)=>{
      e.target.src = '../assets/arbres/XX_feuille.png'
    };
    // the nav ref
    const modalNav = ref(null);
    const currentLevel = ref();
    const levels = ref([]);
    const goForward = (item, index) => {
      //console.log(index);
      levels.value.push({ index: index, title: item.title });
      //console.log(currentLevel.value[index].choices);
      // order items alphabetically
      currentLevel.value = Object.values(
        currentLevel.value[index].choices
      ).sort((a, b) => {
        return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
      });
    };
    const goBackward = () => {
      currentLevel.value = JSON.parse(
        JSON.stringify(store.state.cleIdentification)
      );
      levels.value.splice(-1, 1);
      levels.value.forEach((level) => {
        currentLevel.value = Object.values(
          currentLevel.value[level.index].choices
        ).sort((a, b) => {
          return a.title.toLowerCase().localeCompare(b.title.toLowerCase());
        });
      });
    };

    onMounted(() => {
      const v = document.getElementById("modal-nav");
      modalNav.value = v;
      store.commit("setModalNav", modalNav.value);
      
      const gesture = createGesture({
        el: modalNav.value,
        onStart: (detail) => onStart(detail),
      });
      gesture.enable()
    });

    const onStart = (detail) => {
      console.log(detail);
      if (detail.deltaX < 0){
        // swipe to the left
        goBackward()
      } 
    };

    /**
     *  when going to the next page, I pass the nav as a property
     * so I don't need to get it from the document again
     */
    const nextPage = (componentName) => {
      modalNav.value.push(componentName, {
        // these come across as properties on the component
        modalNav: modalNav,
      });
    };
    /**
     * close the modal dialog
     */
    const closeModal = async () => {
      await modalController.dismiss();
    };

    return {
      nextPage,
      closeModal,
      goForward,
      goBackward,
      levels,
      currentLevel,
      returnFallbackImage
    };
  },
});
</script>
