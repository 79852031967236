import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Tab1Page from '../views/Tab1Page.vue';
import store from "../store"


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Tab1Page,
    beforeEnter: (to, from) => {
      // reject the navigation
    //console.log("before entering tab1");
      if (store.state.mapboxMap) {
        store.state.mapFadeInAnimation.delay(500).play();

         setTimeout(() => {
          store.state.mapboxMap.resize();

        }, 100) 
      //console.log("resizing map");
      }
    },
    /*  children: [
       {
         path: 'tab1',
         component: () => import('@/views/Tab1Page.vue')
       },
       {
         path: 'tab2',
         component: {},
       },
       {
         path: 'tab3',
         component: () => import('@/views/Tab3Page.vue')
       }
     ] */
  },
  {
    // path: '/user/:id',
    path: '/userLogin/',
    name: 'userLogin',
    // lazy loads the component
    component: () => import('@/views/userLogin.vue')
    // component: userProfile
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
