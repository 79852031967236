<template>
  <!-- <ion-item-divider>
    <ion-label class="form-divider-title">Observations</ion-label>
  </ion-item-divider> -->
  <div v-if="$store.state.formattedSpecimen">
    <ion-item>
<!--       <ion-label class="form-divider-title">État de santé</ion-label> -->      
      <ion-select
        label="État de santé"
        name="observations.healthStatus"
        v-model="
          $store.state.formattedSpecimen.groupSpecific[groupSpecific]
            .observations.healthStatus.current.value
        "
        @ionChange="setHealthStatus"
      >
        <ion-select-option
          v-for="option in $store.state.groupSpecificTreeModel.dropdowns
            .healthStatus"
          :key="option"
          >{{ option }}</ion-select-option
        >
      </ion-select>
<!--       <ion-button
        fill="clear"
        slot="end"
        @click="showHealthStatusHistory = !showHealthStatusHistory"
        ><ion-icon :icon="bookOutline"></ion-icon
      ></ion-button> -->
    </ion-item>
    <div v-show="showHealthStatusHistory">
      <span
        v-for="item in $store.state.formattedSpecimen.groupSpecific[
          groupSpecific
        ].observations.healthStatus.history"
        :key="item"
      >
        <ion-item class="history-list-items">
          <ion-label class="ion-text-wrap"
            ><b>{{ item.value }}</b>
            <span v-if="item.archivedOn"
              >le {{ item.archivedOn.time.slice(0, 10) }} par
              {{ item.archivedOn.name }}</span
            >
            <span v-else>Date inconnue</span></ion-label
          >
        </ion-item>
      </span>
    </div>

<!--     <ion-item>
      <ion-input
        label="DHP"
        name="observations.dhp"
        v-model="
          $store.state.formattedSpecimen.groupSpecific[groupSpecific]
            .observations.dhp.current.value
        "
        @ionBlur="setDhp"
      ></ion-input>
    </ion-item> -->

    <ion-item>
      <!-- <ion-label class="form-divider-title">Diamètre hauteur poitrine (DHP)</ion-label> -->
      <ion-select
        v-if="
          $store.state.formattedSpecimen.groupSpecific[groupSpecific]
            .observations.leafVolume
        "
        label="Diamètre hauteur poitrine (DHP)"
        name="observations.dhp"
        v-model="
          $store.state.formattedSpecimen.groupSpecific[groupSpecific]
            .observations.dhp.current.value
        "
        @ionChange="setDhp"
      >
        <ion-select-option
          v-for="option in $store.state.groupSpecificTreeModel.dropdowns
            .dhp"
          :key="option"
          >{{ option }}</ion-select-option
        >
      </ion-select>
    </ion-item>

    <ion-item>
      <!-- <ion-label class="form-divider-title">Défoliation</ion-label> -->
      <ion-select
        v-if="
          $store.state.formattedSpecimen.groupSpecific[groupSpecific]
            .observations.leafVolume
        "
        label="Défoliation"
        name="observations.leafVolume"
        v-model="
          $store.state.formattedSpecimen.groupSpecific[groupSpecific]
            .observations.leafVolume.current.value
        "
        @ionChange="setLeafVolume"
      >
        <ion-select-option
          v-for="option in $store.state.groupSpecificTreeModel.dropdowns
            .leafVolume"
          :key="option"
          >{{ option }}</ion-select-option
        >
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-select
        v-if="
          $store.state.formattedSpecimen.groupSpecific[groupSpecific]
            .observations.mauvaisEmplacement
        "
        label="Mauvais emplacement"
        name="observations.mauvaisEmplacement"
        v-model="
          $store.state.formattedSpecimen.groupSpecific[groupSpecific]
            .observations.mauvaisEmplacement.current.value
        "
        @ionChange="setMauvaisEmplacement"
        ><ion-select-option
          v-for="option in $store.state.groupSpecificTreeModel.dropdowns
            .mauvaisEmplacement"
          :key="option"
          >{{ option }}</ion-select-option
        ></ion-select
      >
    </ion-item>
  </div>
</template>

<script>
import {
  IonItem,
  IonSelect,
  IonSelectOption,
  IonLabel,
/*   IonIcon,
  IonButton, */
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { bookOutline } from "ionicons/icons";

export default defineComponent({
  name: "ObservationsComponent",
  props: {
    title: { type: String, default: "Observations" },
    groupSpecific: String,
  },
  setup(props) {
    const store = useStore();
    const healthStatus = ref();
    const dhp = ref();
    const leafVolume = ref();
    const mauvaisEmplacement = ref();
    const showHealthStatusHistory = ref();
    const setHealthStatus = (ev) => {
      store.state.formattedSpecimen.groupSpecific[
        props.groupSpecific
      ].observations.healthStatus.current.value = ev.detail.value;
      // store.commit("setFormattedSpecimen", formattedSpecimen.value);
    };
    const setDhp = (ev) => {
      //console.log("dhp.value: "+ dhp.value);
      //console.log("ev: ");
      //console.log(ev);
      //console.log(store.state.formattedSpecimen.groupSpecific[props.groupSpecific].observations
      //     .dhp.current.value)
      store.state.formattedSpecimen.groupSpecific[props.groupSpecific].observations.dhp.current.value = ev.detail.value;
      //  store.commit("setFormattedSpecimen", formattedSpecimen.value);
    };
    const setLeafVolume = (ev) => {
      store.state.formattedSpecimen.groupSpecific[
        props.groupSpecific
      ].observations.leafVolume.current.value = ev.detail.value;
      //   store.commit("setFormattedSpecimen", formattedSpecimen.value);
    };
    const setMauvaisEmplacement = (ev) => {
      //console.log(ev.detail.value);
      store.state.formattedSpecimen.groupSpecific[
        props.groupSpecific
      ].observations.mauvaisEmplacement.current.value = ev.detail.value;
      //     store.commit("setFormattedSpecimen", formattedSpecimen.value);
    };

    return {
      // store,
      healthStatus,
      dhp,
      leafVolume,
      mauvaisEmplacement,
      bookOutline,
      setHealthStatus,
      setDhp,
      setLeafVolume,
      setMauvaisEmplacement,
      showHealthStatusHistory,
    };
  },
  components: {
    IonItem,
    IonSelect,
    IonSelectOption,
    IonLabel,
/*     IonIcon,
    IonButton, */
  },
});
</script>
