


//console.log("loading requestParameters");

var latLng, radius, polygon, polyline, polygonInLeafletDrawFormat, story, ekhoProperties, options, currentQuery, currentQueryEnteredValue, currentQueryYear;

var plantationAppModeProjectLoaded = false;

var basicPropertiesList = [{
  fieldName: "_id",
  operator: "==",
  enteredValue: null,
  type: "string",
  displayName: "ID",
  propertyType: "basic",
}, {
  fieldName: "",
  operator: "==",
  enteredValue: null,
  type: "string",
  displayName: "classification",
  propertyType: "basic",
}, {
  fieldName: "owner",
  operator: "==",
  enteredValue: null,
  type: "string",
  displayName: "identifié par",
  propertyType: "basic",
}, /* , {
    fieldName: "owning_stories",
    operator: "array-contains",
    enteredValue: null,
    type: "number",
    displayName: "ID de la collection"
  } */ {
  fieldName: "hasPhoto",
  operator: "==",
  enteredValue: true,
  type: "boolean",
  displayName: "photo",
  propertyType: "basic",
}, {
  fieldName: "createdOn.year",
  operator: "==",
  enteredValue: null,
  type: "number",
  displayName: "année",
  propertyType: "basic",
}, {
  fieldName: "createdOn.month",
  operator: "==",
  enteredValue: null,
  type: "number",
  displayName: "mois",
  propertyType: "basic",
  selectOptions: "monthsOptions"  // an array is named like that in $scope (in main.js)
}, {
  fieldName: "createdOn.date",
  operator: "==",
  enteredValue: null,
  type: "number",
  displayName: "date",
  propertyType: "basic",
}];

export default {

  setLatLng: function (value) {
    latLng = value;
    //console.log(latLng);
  },
  getLatLng: function (latOrLng) {
    //console.log("getlatlong");
    if (latLng) {
      if (latOrLng === "lat") {
        return latLng.lat;
      } else {
        return latLng.lng;
      }
    } else {
      return "";
    }
  },

  setRadius: function (value) {
    radius = value;
    //console.log(value);
  },
  getRadius: function () {
    return radius;
  },
  setPolygon: function (value) {
    polygon = value;
    //console.log(value);
  },
  getPolygon: function () {
    if (polygon) {
      return polygon;
    } else {
      return {
        type: "FeatureCollection",
        features: []
      };
    }
  },
  setPolyline: function (value) {
    if (value) {
      polyline = value.features[0].geometry.coordinates;
      //console.log(value);
    }
  },
  getPolyline: function () {
    if (polyline) {
      return polyline;
    }
  },
  setPolygonInLeafletDrawFormat: function (value) {
    polygonInLeafletDrawFormat = value;
    //console.log(value);
  },
  getPolygonInLeafletDrawFormat: function () {
    return polygonInLeafletDrawFormat;
  },
  setStory: function (value) {
    story = value;
  },
  getStory: function () {
    return story;
  },
  setEkhoProperties: function (value) {
    ekhoProperties = value;
  },
  getEkhoProperties: function () {
    return ekhoProperties;
  },
  setOptions: function (value) {
    options = value;
  },
  getOptions: function () {
    return options;
  },
  setPlantationAppModeProjectsLoaded: function () {
    plantationAppModeProjectLoaded = true;
  },
  getPlantationAppModeProjectsLoaded: function () {
    return plantationAppModeProjectLoaded;
  },
  setCurrentQueryEnteredValue: function (value) {
    currentQueryEnteredValue = value;
  },
  getCurrentQueryEnteredValue: function () {
    return currentQueryEnteredValue;
  },
  setCurrentQuery: function (value) {
    currentQuery = value;
  },
  getCurrentQuery: function () {
    if (!currentQuery) {
      return {
        chosen: {},
        showTextInput: false,
        selectedPropertiesToSearch: [],
        propertiesToSearch: basicPropertiesList,
        groupSpecificPropertiesToSearch: [],
        tasksPropertiesToSearch: [],
        cancelSpecificQueryChosen: function () {
          this.chosen = {};
          //console.log(this.chosen);
          this.showTextInput = false;
          this.enteredValue = "";
          this.enteredValueForTag = "";
          this.taskFilter = null;
          this.year = "";
          this.taskYear = null;
          this.taskYearGreaterThan = null;
          this.taskYearLessThan = null;
        }
      };
    }
    return currentQuery;
  },
  setCurrentQueryYear: function (value) {
    currentQueryYear = value;
  },
  getCurrentQueryYear: function () {
    return currentQueryYear;
  },
};

