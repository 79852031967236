import { ref } from "vue";
import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from "@capacitor/camera";
import {
  getStorage,
  ref as storageRef,
  uploadString,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { useStore } from "vuex";
import { getFirestore, writeBatch, doc } from "firebase/firestore";
import mapService from "@/services/mapService";
console.log("loading usePhoto composable");

const photos = ref([]);
const uploading = ref(false);

export function usePhoto() {
  let store = useStore();

  const convertBlobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  const savePicture = async (photo, filename, treePart, container, item) => {
    console.log("trying to save picture");
    //console.log(photo);
    let base64Data;
    let blob;
    if (photo.webPath) {
      // Fetch the photo, read as a blob, then convert to base64 format
      const response = await fetch(photo.webPath);
      blob = await response.blob();
    } else {
      // photo is already a blob
      blob = photo;
    }
    base64Data = await convertBlobToBase64(blob);
    const tempPicture = {
      treePart: treePart,
      picture: blob,
      pictureForUI: URL.createObjectURL(blob),
    };
    store.state.pictureSet[treePart] = tempPicture;

    if (container == "VisualRecognition") {
      // do not save yet, user might take more pictures or cancel the operation
      uploading.value = false;
    } else {
      // save on remote
      //console.log(filename);
      //console.log("base64Data");
      //console.log(base64Data);
      const storage = getStorage(store.state.firebaseApp);
      const storageRefForSpecimenPicture = storageRef(
        storage,
        "specimens/" + filename
      );
      const metadata = {
        contentType: "image/jpeg",
      };
      const dataUrlString = await uploadString(
        storageRefForSpecimenPicture,
        base64Data,
        "data_url",
      );
      //console.log('Uploaded a data_url string! ' + dataUrlString);
      //console.log('Uploaded a blob or file!');
      //console.log(filename);
      const url = await getDownloadURL(storageRefForSpecimenPicture);
      //console.log("downloadUrl: " + url);
      //this.pictureSource = uploadedFile.downloadURL;
      const photoObj = {
        url: url,
        _id: filename,
        treePart: treePart ? treePart : null,
      };
      if (treePart == "favorites" || treePart == "reports") {
        if (treePart == "favorites") {
          if (
            !store.state.formattedSpecimen.groupSpecific.collaborative.likes[
            item.like_id
            ]
          ) {
            store.state.formattedSpecimen.groupSpecific.collaborative.likes[
              item.like_id
            ] = item;
          }
          store.state.formattedSpecimen.groupSpecific.collaborative.likes[
            item.like_id
          ].photos.push(photoObj);
          // add collaborative icon onto map
          store.state.collaborativeDataQueryObj.push({
            has_likes: true,
            specimen_id:
              store.state.fetchedSpecimen.geojsonFeature.properties._id,
            firestoreId:
              store.state.fetchedSpecimen.geojsonFeature.properties.firestoreId,
            g: {
              geopoint: {
                _lat: store.state.fetchedSpecimen.geojsonFeature.geometry
                  .coordinates[1],
                _long:
                  store.state.fetchedSpecimen.geojsonFeature.geometry
                    .coordinates[0],
              },
            },
          });
          mapService.addCollaborativeIcons();
          // open specimen details accordingly
          setTimeout(() => {
            store.state.relativeToContent();
          }, 200);
        } else {
          if (
            !store.state.formattedSpecimen.groupSpecific.collaborative.reports[
            item.report_id
            ]
          ) {
            store.state.formattedSpecimen.groupSpecific.collaborative.reports[
              item.report_id
            ] = item;
          }
          store.state.formattedSpecimen.groupSpecific.collaborative.reports[
            item.report_id
          ].photos.push(photoObj);
          // add collaborative icon onto map
          store.state.collaborativeDataQueryObj.push({
            has_reports: true,
            specimen_id:
              store.state.fetchedSpecimen.geojsonFeature.properties._id,
            firestoreId:
              store.state.fetchedSpecimen.geojsonFeature.properties.firestoreId,
            g: {
              geopoint: {
                _lat: store.state.fetchedSpecimen.geojsonFeature.geometry
                  .coordinates[1],
                _long:
                  store.state.fetchedSpecimen.geojsonFeature.geometry
                    .coordinates[0],
              },
            },
          });
          mapService.addCollaborativeIcons();
          // open specimen details accordingly
          setTimeout(() => {
            store.state.relativeToContent();
          }, 200);
        }
        // save right away in collaborative collection
        store
          .dispatch("formatSpecimenForServer", {
            specimen: store.state.formattedSpecimen,
            collaborativeForm: true,
          })
          .then(function (result) {
            //console.log("specimen  saved on server");
            //console.log(result);
            //    loader.hide();
            store.commit("setRepositionSpecimen", false);
            /* if (treePart == "reports") {
              // after the report's picture is saved, the ui goes back to list view
              const modalRef = document.querySelector(
                ".modal-content-specimen-details"
              );
              //console.log(modalRef);
              modalRef.setCurrentBreakpoint(0.5);
            } */
          })
          .catch(function (error) {
            alert("La sauvegarde du spécimen a échouée.  Veuillez réessayer plus tard.");
            //     loader.hide();
            console.error("Error writing firestore document: ", error);
          });
      } else {
        if (!store.state.formattedSpecimen.photos) {
          store.state.formattedSpecimen.photos = [];
        }
        store.state.formattedSpecimen.photos.push(photoObj);
        store.state.formattedSpecimen.hasPhoto = true;
        if (store.state.formattedSpecimen.firestoreId) {
          // not a new specimen, update on remote right now
          //      store.commit("setSpecimenPicture", photoObj);
          // save new url on remote specimen
          var firestoreDb = getFirestore();
          //connectFirestoreEmulator(firestoreDb, 'localhost', 8080);

          const currentDataOwning_group =
            store.state.formattedSpecimen.owning_group;
          let collectionPath =
            "groups/" + currentDataOwning_group + "/specimens";
          let specimenRef = doc(
            firestoreDb,
            collectionPath,
            store.state.formattedSpecimen.firestoreId
          );

          var batch = writeBatch(firestoreDb);
          batch.update(specimenRef, {
            "geojsonFeature.properties.photos":
              store.state.formattedSpecimen.photos,
          });
          batch.update(specimenRef, {
            "geojsonFeature.properties.hasPhoto": true,
          });
          //console.log("batch update photos infos on specimen2");
          // update local object in selection
          store.state.fetchedSpecimensObj[
            store.state.formattedSpecimen.firestoreId
          ].geojsonFeature.properties.photos =
            store.state.formattedSpecimen.photos;
          // Commit the batch
          batch
            .commit()
            .then(function () {
              //console.log("uploading picture on remote done!");
            })
            .catch(function (error) {
              alert(
                "Vous n'avez pas la permission d'ajouter une photo sur ce spécimen"
              );
              //console.log("File upload error: " + error);
              uploading.value = false;
            });
        }
      }
      uploading.value = false;
    }
  };

  const deletePicture = (chosenPhoto, storeCallback) => {
    //console.log(chosenPhoto);
    if (!store) {
      store = storeCallback;
    }
    if (!chosenPhoto._id) {
      // that picture has not been saved on server, it is only stored on the device memory
      delete store.state.pictureSet[chosenPhoto.treePart];
    } else {
      // Create a reference to the file to delete
      const storage = getStorage(store.state.firebaseApp);
      const storageRefForSpecimenPictureToDelete = storageRef(
        storage,
        "specimens/" + chosenPhoto._id
      );
      // Delete the file
      deleteObject(storageRefForSpecimenPictureToDelete)
        .then(() => {
          // update specimen
          //console.log("updating specimen");
          if (chosenPhoto.treePart == "favorites") {
            //alert("favorite");
            // updating the collaborative group data will be done from favoriteDetails
          } else if (
            store.state.formattedSpecimen &&
            store.state.formattedSpecimen.photos
          ) {
            // the specimen has not been deleted, we must update it
            console.log(store.state.formattedSpecimen.photos.length);
            for (
              let index = 0;
              index < store.state.formattedSpecimen.photos.length;
              index++
            ) {
              if (
                store.state.formattedSpecimen.photos[index]._id ==
                chosenPhoto._id
              ) {
                store.state.formattedSpecimen.photos.splice(index, 1);
              }
            }
            if (store.state.formattedSpecimen.photos.length == 0) {
              store.state.formattedSpecimen.hasPhoto = false;
            }
            // save specimen on remote 
            var firestoreDb = getFirestore();
            //connectFirestoreEmulator(firestoreDb, 'localhost', 8080);

            const currentDataOwning_group =
              store.state.formattedSpecimen.owning_group;
            let collectionPath =
              "groups/" + currentDataOwning_group + "/specimens";
            let specimenRef = doc(
              firestoreDb,
              collectionPath,
              store.state.formattedSpecimen.firestoreId
            );
            var batch = writeBatch(firestoreDb);
            batch.update(specimenRef, {
              "geojsonFeature.properties.photos":
                store.state.formattedSpecimen.photos,
            });
            batch.update(specimenRef, {
              "geojsonFeature.properties.hasPhoto":
                store.state.formattedSpecimen.hasPhoto,
            });
            //console.log("batch update photos infos on specimen2");

            // Commit the batch
            batch.commit().then(function () {
              //console.log("picture ref removed on remote specimen done!");
            });
            uploading.value = false;
            // update local object in selection
            store.state.fetchedSpecimensObj[
              store.state.formattedSpecimen.firestoreId
            ].geojsonFeature.properties.photos =
              store.state.formattedSpecimen.photos;
            store.state.fetchedSpecimensObj[
              store.state.formattedSpecimen.firestoreId
            ].geojsonFeature.properties.hasPhoto =
              store.state.formattedSpecimen.hasPhoto;
          }
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          //console.log("error: " + error)
        });
    }
  };

  const takePhoto = async (treePart, container, item) => {
    //console.log(treePart);
    //console.log(container);
    //console.log(item);
    uploading.value = false;
    try {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        quality: 100,
      });
      const filename = new Date().getTime() + ".jpg";
      const savedFileImage = await savePicture(
        photo,
        filename,
        treePart,
        container,
        item
      );
      photos.value = [savedFileImage, ...photos.value];
    } catch (e) {
      //console.log(e)
      // errors.value.push(e);
      //console.log('catch cameraphoto');
      uploading.value = false;
    }
  };

  return {
    photos,
    takePhoto,
    uploading,
    deletePicture,
    savePicture,
  };
}
