<template>
  <!-- <ion-header>
    <ion-toolbar>
      <ion-title>{{ title }}
        <ion-label style="font-size: smaller">({{ $store.state.nearestSpecimen.distanceFromUser }} m.)</ion-label>
      </ion-title>
    </ion-toolbar>
  </ion-header> -->
  <div v-if="$store.state.nearestSpecimen.distanceFromUser" style="height:inherit">
    <ion-toolbar style="position: absolute; height: inherit; --min-height:56px; border-bottom: 0.55px var(--ion-color-step-500) solid">
      <ion-thumbnail class="thumbnail-item-for-nearest-specimen" slot="start">
        <img
          :src="
            '../assets/arbres/' +
            $store.state.nearestSpecimen.geojsonFeature.properties.essence.slice(
              0,
              2
            ) +
            '.png'
          "
          @error="imageLoadOnError"
        />
      </ion-thumbnail>
      <ion-label class="ion-text-wrap">
        <div class="nearest-specimen-label" @click="showSpecimenDetails()">
          <h2
            style="
              margin-right: 16px;
              color: color:var(--ion-color-step-950);
            
            "
          >
           {{
              $store.state.nearestSpecimen.geojsonFeature.properties.essenceName
                .fr.name
            }} - <span style="color:var(--ion-color-step-850)">{{$store.state.nearestSpecimen.geojsonFeature.properties.createdOn.year }}</span> - À {{ $store.state.nearestSpecimen.distanceFromUser }}
              {{
                $store.state.nearestSpecimen.distanceFromUser > 1
                  ? "mètres"
                  : "mètre"
              }}
              de vous 
              <span
              v-show="$store.state.showCompass"
              style="color: red; font-weight: 400"
            >
            <ion-icon
              :icon="compassOutline"
              color="danger"
              size="small"
              style="margin-left: 8px;margin-right:8px;"
            ></ion-icon>
              {{ $store.state.compassDir }}
            </span>
          </h2>
        </div>
        <!--    <h4>par {{ $store.state.nearestSpecimen.geojsonFeature.properties.owner }} le {{
            $store.state.nearestSpecimen.geojsonFeature.properties.plant_date.slice(0, 10)
        }}</h4> -->
        <!--  <h4>identfié le: </h4>ß
      <h4>ID: {{ $store.state.formattedSpecimen._id ? $store.state.formattedSpecimen._id : '' }}</h4> -->
      </ion-label>
    </ion-toolbar>
  </div>
  <ion-item v-if="!$store.state.nearestSpecimen.distanceFromUser">
    <ion-icon :icon="helpOutline" slot="start"> </ion-icon>
    <ion-label class="ion-text-wrap">
      <em
        >Affiche automatiquement les informations du spécimen le plus près de
        vous.</em
      >
    </ion-label>
  </ion-item>
</template>

<script>
import SpecimenService from "@/services/SpecimenService";
import {
  // IonHeader, IonTitle,
  IonToolbar,
  IonIcon,
  IonThumbnail,
  IonLabel,
  IonItem,
  // IonButton,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { helpOutline, compassOutline } from "ionicons/icons";

export default defineComponent({
  name: "nearestSpecimenModal",
  props: {
    title: { type: String, default: "Près de vous" },
  },
  setup() {
    const store = useStore();
    const showSpecimenDetails = () => {
      // set neareastSpecimen as formattedSPecimen (current)
    //console.log(store.state.nearestSpecimen);
      SpecimenService.getFormattedSpecimen(
        store.state.nearestSpecimen.geojsonFeature.properties
      ).then(() => {
        store.state.currentSegment = "specimen";
        store.commit(
          "setChosenSpecimenMarkerFirestoreId",
          store.state.nearestSpecimen.geojsonFeature.properties.firestoreId
        );
        store.state.relativeToContent();
      });
      // show specimenModal
    };
    return {
      showSpecimenDetails,
      helpOutline,
      compassOutline,
    };
  },
  components: {
    //  IonHeader, IonTitle,
    IonToolbar,
    IonIcon,
    IonThumbnail,
    IonLabel,
    IonItem,
    //   IonButton,
  },
});
</script>
