var arbresIcons = {
	list: [
		{
			"code": "PONIIT"
		},
		{
			"code": "PI"
		},
		{
			"code": "AB"
		},
		{
			"code": "TH"
		},
		{
			"code": "PS"
		},
		{
			"code": "FA"
		},
		{
			"code": "AL"
		},
		{
			"code": "BE"
		},
		{
			"code": "CR"
		},
		{
			"code": "SY"
		},
		{
			"code": "ACPLCO"
		},
		{
			"code": "PN"
		},
		{
			"code": "FR"
		},
		{
			"code": "PR"
		},
		{
			"code": "GI"
		},
		{
			"code": "CA"
		},
		{
			"code": "GY"
		},
		{
			"code": "OS"
		},
		{
			"code": "RO"
		},
		{
			"code": "AM"
		},
		{
			"code": "LA"
		},
		{
			"code": "CE"
		},
		{
			"code": "PH"
		},
		{
			"code": "SO"
		},
		{
			"code": "TI"
		},
		{
			"code": "ACRU"
		},
		{
			"code": "SA"
		},
		{
			"code": "JU"
		},
		{
			"code": "AE"
		},
		{
			"code": "UL"
		},
		{
			"code": "MA"
		},
		{
			"code": "CT"
		},
		{
			"code": "AC"
		},
		{
			"code": "QU"
		},
		{
			"code": "MG"
		},
		{
			"code": "QUBI"
		},
		{
			"code": "PO"
		},
		{
			"code": "GL"
		},
		{
			"code": "CG"
		},
		{
			"code": "ACGI"
		},
        {
			"code": "ARBU--"
		},
        {
			"code": "PLATEB"
		},
        {
			"code": "XX"
		}

	],
	listForAvatars: [
		{
			"code": "PONIIT"
		},
		{
			"code": "PI"
		},
		{
			"code": "AB"
		},
		{
			"code": "TH"
		},
		{
			"code": "PS"
		},
		{
			"code": "FA"
		},
		{
			"code": "AL"
		},
		{
			"code": "BE"
		},
		{
			"code": "CR"
		},
		{
			"code": "SY"
		},
		{
			"code": "PN"
		},
		{
			"code": "FR"
		},
		{
			"code": "PR"
		},
		{
			"code": "GI"
		},
		{
			"code": "CA"
		},
		{
			"code": "GY"
		},
		{
			"code": "OS"
		},
		{
			"code": "RO"
		},
		{
			"code": "AM"
		},
		{
			"code": "LA"
		},
		{
			"code": "CE"
		},
		{
			"code": "PH"
		},
		{
			"code": "SO"
		},
		{
			"code": "TI"
		},
		{
			"code": "ACRU"
		},
		{
			"code": "SA"
		},
		{
			"code": "JU"
		},
		{
			"code": "AE"
		},
		{
			"code": "UL"
		},
		{
			"code": "MA"
		},
		{
			"code": "CT"
		},
		{
			"code": "AC"
		},
		{
			"code": "QU"
		},
		{
			"code": "MG"
		},
		{
			"code": "QUBI"
		},
		{
			"code": "PO"
		},
		{
			"code": "GL"
		},
		{
			"code": "CG"
		},
		{
			"code": "ACGI"
		}

	]
}
export default {
	arbresIcons: ()=>{
		return arbresIcons
	}
}