<template>
  <ion-content>
    <ion-header>
      <ion-toolbar class="ion-no-padding">
        <ion-buttons slot="end">
          <ion-button @click="closeModal">FERMER</ion-button>
        </ion-buttons>
        <ion-title>Remarque</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-card>
      <span v-for="(photo, i) in currentLike.photos" :key="photo">
        <ion-button v-if="editCurrentLike && currentLike.photos.length > 0"
          @click="confirmDeletePicture(photo, currentLike, i)" class="remove-picture-icon" fill="clear" color="danger">
          <ion-icon :icon="removeCircle"></ion-icon>
        </ion-button>
        <img :src="'https://firebasestorage.googleapis.com/v0/b/soverdiwebsite.appspot.com/o/specimens%2F' +
            photo._id +
            '?alt=media'
            " @click="
            showImgModal = !showImgModal;
          chosenPhoto = photo;
          " />
      </span>
      <ion-item v-if="editCurrentLike && currentLike.photos.length == 0">
        <ion-label slot="end">Ajoutez une photo</ion-label>
        <ion-icon slot="end" :icon="camera" @click="takePhoto('favorites', 'Favorites', currentLike)">
        </ion-icon>
      </ion-item>
      <ion-card-header v-show="!editCurrentLike">
        <span v-if="currentLike.dateDone">{{
            new Date(currentLike.dateDone.seconds * 1000).toLocaleString('fr-CA', {
              year: "numeric",
              month: "long",
              day: "numeric",
            }).slice(0, 25)
          }}</span>
        <ion-card-subtitle>par {{ currentLike.author_name }}</ion-card-subtitle>
      </ion-card-header>
      <ion-card-content v-show="!editCurrentLike" class="ion-text-wrap">{{ currentLike.description }}
      </ion-card-content>
      <ion-item v-show="editCurrentLike">
        <ion-label :position="labelPositioning" class="ion-text-wrap">Expliquez-nous ce que cet arbre a de
          remarquable</ion-label>
        <ion-textarea v-model="currentLike.description" :auto-grow="currentLike.description ? currentLike.description.length == 0 : true
            " :autofocus="true" inputmode="text" :maxlength="500" :spellcheck="true" rows="5"></ion-textarea>
      </ion-item>
      <ion-item class="ion-text-wrap">
        <ion-button v-if="currentLike.author_uid == $store.state.user.uid && !editCurrentLike" @click="modifyLike()"
          color="primary" slot="start">
          Modifier
        </ion-button>
        <ion-button v-if="editCurrentLike && currentLike.author_uid == $store.state.user.uid
            " fill="outline" @click="confirmRemoveLike(currentLike)" slot="start" color="danger">Effacer
          <!--           <ion-icon :icon="trash"></ion-icon>
 --> </ion-button>
        <ion-button v-if="editCurrentLike" :disabled="currentLike.description.length < 1" @click="addLike()"
          slot="end">Sauvegarder</ion-button>
        <!--  <ion-action-sheet
          :is-open="isOpenRef"
          header="Partager"
          css-class="my-custom-class"
          :buttons="buttons"
          @didDismiss="setOpen(false)"
        >
        </ion-action-sheet> -->
      </ion-item>
    </ion-card>
  </ion-content>
</template>

<script>
import firebaseService from "@/services/firebaseService";
import mapService from "@/services/mapService";
import {
  IonContent,
  IonIcon,
  IonButton,
  IonToolbar,
  IonHeader,
  IonItem,
  IonLabel,
  IonTextarea,
  IonTitle,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  modalController,
  alertController,
} from "@ionic/vue";
import { defineComponent, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { usePhoto } from "@/composables/usePhoto";
import {
  closeCircle,
  camera,
  chatbubbleEllipsesOutline,
  shareSocialOutline,
  logoFacebook,
  logoInstagram,
  removeCircle,
  trash,
} from "ionicons/icons";
export default defineComponent({
  name: "FavoriteDetails",
  props: ["addOrEdit"],
  setup(props) {
    //console.log("FavoriteDetails");

    const { errors, loading } = firebaseService();
    const store = useStore();
    const specimen = ref();
    const firestoreId = ref();
    const labelPositioning = ref("floating");
    const editCurrentLike = ref(false);
    let tempLike = {};
    const modifyLike = () => {
      // copy current like
      tempLike.title = JSON.parse(JSON.stringify(currentLike.value.title));
      tempLike.description = JSON.parse(
        JSON.stringify(currentLike.value.description)
      );
      //console.log(tempLike);
      editCurrentLike.value = true;
    };
    const cancelModifyLike = () => {
      currentLike.value.title = tempLike.title;
      currentLike.value.description = tempLike.description;
      editCurrentLike.value = false;
      if (!currentLike.value.title || !currentLike.value.description)
        closeModal();
    };
    const { takePhoto, photos, uploading, deletePicture, savePicture } =
      usePhoto();
    const testBreakPoint = () => {
      const modalRef = document.querySelector(
        ".modal-content-specimen-details"
      );
      //console.log(modalRef);
      modalRef.setCurrentBreakpoint(0.9);
    };
    const closeModal = async () => {
      if (editCurrentLike.value) {
        //console.log("cancelling modify");
        cancelModifyLike();
      }
      await modalController.dismiss();
    };
    const confirmDeletePicture = async (chosenPhoto, currentLike, i) => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Souhaitez-vous vraiment effacer cette image?",
        message: "Cette action est irréversible!!!",
        buttons: [
          {
            text: "Annuler",
            role: "cancel",
            cssClass: "secondary",
            id: "cancel-button",
            handler: () => {
              // do nothing
            },
          },
          {
            text: "Effacer",
            id: "confirm-button",
            handler: () => {
              //console.log("Confirm Okay");
              deletePicture(chosenPhoto);
              // updating data
              console.log(currentLike);
              currentLike.photos.splice(i, 1);
              console.log(currentLike);
              // save on remote
              // finishSaving();
              //  showImgModal.value = false;
            },
          },
        ],
      });
      return alert.present();
    };
    const confirmRemoveLike = async (like) => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Souhaitez-vous vraiment effacer cette remarque?",
        message: "Cette action est irréversible!!!",
        buttons: [
          {
            text: "Annuler",
            role: "cancel",
            cssClass: "secondary",
            id: "cancel-button",
            handler: () => {
              // do nothing
            },
          },
          {
            text: "Effacer",
            id: "confirm-button",
            handler: () => {
              //console.log("Confirm Okay");
              
              if (
                store.state.formattedSpecimen.groupSpecific.collaborative.likes[
                  like.like_id
                ] && store.state.formattedSpecimen.groupSpecific.collaborative.likes[
                  like.like_id
                ].photos.length > 0
              ) {
                // remove pictures from storage
                store.state.formattedSpecimen.groupSpecific.collaborative.likes[
                  like.like_id
                ].photos.forEach((picture) => {
                  deletePicture(picture);
                });
              }
              // updating data
              delete store.state.formattedSpecimen.groupSpecific.collaborative
                .likes[like.like_id];

              if (
                Object.keys(
                  store.state.formattedSpecimen.groupSpecific.collaborative
                    .likes
                ).length < 1
              ) {
                // remove collaborative icon from the marker
                for (
                  let index = 0;
                  index < store.state.collaborativeDataQueryObj.length;
                  index++
                ) {
                  const collaborativeData =
                    store.state.collaborativeDataQueryObj[index];
                  if (
                    collaborativeData.specimen_id ==
                    store.state.fetchedSpecimen.geojsonFeature.properties
                      ._id &&
                    collaborativeData.has_likes
                  ) {
                    if (!collaborativeData.likes) {
                      collaborativeData.has_likes = false;
                      break;
                    } else if (
                      Object.keys(collaborativeData.likes).length < 2
                    ) {
                      collaborativeData.has_likes = false;
                      break;
                    }
                  }
                }
              }
              /* store.state.collaborativeDataQueryObj.forEach(
                (collaborativeData, i) => {
                  if (
                    collaborativeData.specimen_id ==
                      store.state.fetchedSpecimen.geojsonFeature.properties
                        ._id &&
                    collaborativeData.has_likes
                  ) {
                    collaborativeTypeCounter++;
                    if (collaborativeTypeCounter < 2) {
                      // there was no or only one like. Set has_likes to false
                      collaborativeData.has_likes = false;
                    }
                  }
                }
              ); */
              mapService.addCollaborativeIcons();
              // save specimen on remote
              finishSaving();
              //  showImgModal.value = false;
            },
          },
        ],
      });
      return alert.present();
    };
    const isOpenRef = ref(false);
    const setOpen = (state) => (isOpenRef.value = state);
    const buttons = [
      {
        text: "Facebook",
        icon: logoFacebook,
        data: {
          type: "delete",
        },
        handler: () => {
          //console.log("Delete clicked");
        },
      },
      {
        text: "Instagram",
        icon: logoInstagram,
        data: 10,
        handler: () => {
          //console.log("Share clicked");
        },
      },
      {
        text: "Cancel",
        icon: closeCircle,
        role: "cancel",
        handler: () => {
          //console.log("Cancel clicked");
        },
      },
    ];

    const currentLike = ref({
      author_email: store.state.user.email,
      author_uid: store.state.user.uid,
      author_name: store.state.user.displayName,
      dateDone: new Date(),
      description: "",
      title: "",
      photos: [],
      like_id: store.state.user.uid + "_" + new Date().getMilliseconds(),
    });

    function finishSaving() {
      store
        .dispatch("formatSpecimenForServer", {
          specimen: store.state.formattedSpecimen,
          collaborativeForm: true,
        })
        .then(function (result) {
          //console.log("specimen  saved on server");
          //console.log(result);
          //    loader.hide();
          store.commit("setRepositionSpecimen", false);
          //  store.commit("setBreakPointRelativeToContent", 0.5);
          store.state.cappedBreakpoints = [
            0.001, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65,
            0.7, 0.75, 0.8, 0.85, 0.9, 0.95,
          ];
          store.state.relativeToContent();
          closeModal();
        })
        .catch(function (error) {
          alert("La sauvegarde du spécimen a échouée.  Veuillez réessayer plus tard.");
          //     loader.hide();
          console.error("Error writing firestore document: ", error);
        });
    }

    const addLike = () => {
      // check if the user added a picture
      if (currentLike.value.photos.length == 0) {
        alert("Vous devez ajouter une photo à votre remarque.");
        return;
      }
      //console.log("adding like");
      const newLikeKeyname = currentLike.value.like_id;
      // add collaborative icon onto map
      store.state.collaborativeDataQueryObj.push({
        has_likes: true,
        specimen_id: store.state.fetchedSpecimen.geojsonFeature.properties._id,
        firestoreId:
          store.state.fetchedSpecimen.geojsonFeature.properties.firestoreId,
        g: {
          geopoint: {
            _lat: store.state.fetchedSpecimen.geojsonFeature.geometry
              .coordinates[1],
            _long:
              store.state.fetchedSpecimen.geojsonFeature.geometry
                .coordinates[0],
          },
        },
      });
      mapService.addCollaborativeIcons();
      // open specimen details accordingly
      setTimeout(() => {
        store.state.relativeToContent();
      }, 200);
      if (!store.state.formattedSpecimen.groupSpecific.collaborative.likes) {
        store.state.formattedSpecimen.groupSpecific.collaborative.likes = {};
        store.state.formattedSpecimen.groupSpecific.collaborative.likes[
          newLikeKeyname
        ] = currentLike.value;
      } else {
        store.state.formattedSpecimen.groupSpecific.collaborative.likes[
          newLikeKeyname
        ] = currentLike.value;
      }
      finishSaving();
    };

    onMounted(() => {
      //console.log("onMounted" + props.addOrEdit);
      const addOrEdit = props.addOrEdit;
      //console.log(addOrEdit);
      if (addOrEdit == "edit") {
        //console.log("salut");
        //console.log("allo" + store.state.likeDetails);
        currentLike.value = store.state.likeDetails;
        labelPositioning.value = "stacked";
      } else {
        editCurrentLike.value = true;
      }
    });

    return {
      specimen,
      errors,
      loading,
      firestoreId,
      closeCircle,
      camera,
      testBreakPoint,
      takePhoto,
      photos,
      uploading,
      deletePicture,
      savePicture,
      addLike,
      currentLike,
      labelPositioning,
      closeModal,
      chatbubbleEllipsesOutline,
      setOpen,
      buttons,
      isOpenRef,
      shareSocialOutline,
      logoFacebook,
      logoInstagram,
      editCurrentLike,
      removeCircle,
      confirmDeletePicture,
      confirmRemoveLike,
      modifyLike,
      cancelModifyLike,
      trash,
    };
  },
  components: {
    IonContent,
    IonIcon,
    IonButton,
    IonToolbar,
    IonHeader,
    IonItem,
    IonLabel,
    IonTextarea,
    IonTitle,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
  },
});
</script>
