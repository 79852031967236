<template >
  <ion-item-divider>
    <ion-label class="form-divider-title">Tâches {{ $store.state.userGroupMembership }}</ion-label>
  </ion-item-divider>
  <div v-if="$store.state.formattedSpecimen">
    <ion-list
      v-for="task in $store.state.formattedSpecimen.groupSpecific[
        $store.state.userGroupMembership
      ].tasks"
      :key="task.name"
      style="margin-bottom: 16px; margin-top: 16px"
    > 
      <ion-item v-if="task">
        <label>{{ task.name }} </label>
        <ion-button
          fill="clear"
          slot="end"
          v-if="task.history && task.history.length"
          @click="task.showHistory = !task.showHistory"
          ><ion-icon :icon="bookOutline"></ion-icon
        ></ion-button>
      </ion-item>
      <ion-list v-show="task.showHistory">
        <ion-item v-for="item in task.history" :key="item">
          <ion-label v-if="item.dateToDo"
            >À faire
            <ion-label>{{ item.dateToDo.slice(0, 10) }}</ion-label></ion-label
          >
          <ion-label v-if="item.dateDone"
            >Fait
            <ion-label>{{ item.dateDone.slice(0, 10) }}</ion-label></ion-label
          >
          <ion-label v-if="item.recordedBy && item.recordedBy.name" style="font-size: small"
            >{{ item.recordedBy.name }}
            <ion-label v-if="item.recordedBy.time">{{
              item.recordedBy.time.slice(0, 10)
            }}</ion-label></ion-label
          >
        </ion-item>
      </ion-list>
      <ion-item>
        <ion-button
          fill="outline"
          id="open-datetime-modal"
          @click="editDateToDo(task)"
        >
          À faire avant le
        </ion-button>
        <label v-if="task.suivi.dateToDo">{{ task.suivi.dateToDo }}</label>
      </ion-item>
      <ion-item>
        <ion-button fill="outline" @click="editDateDone(task)"
          >Fait le</ion-button
        >
        <label v-if="task.suivi.dateDone">{{ task.suivi.dateDone }}</label>
      </ion-item>
    </ion-list>
  </div>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonItemDivider,
  IonList,
  IonButton,
  IonIcon,
} from "@ionic/vue";
import { bookOutline } from "ionicons/icons";
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "TasksComponent",
  props: {
    title: { type: String, default: "Observations" },
  },
  setup() {
    const store = useStore();
  //console.log("building tasks before");

    //  const tasksArray = computed(() => {
    //console.log("building tasks");
    const array = [];
    // build tasks
    store.state.groupSpecificTreeModel.tasks.forEach((taskName) => {
      const taskObj = {
        name: taskName,
        dateToDo: null,
        dateDone: null,
        history: null,
        show: ref(false),
        showHistory: false,
      };
      array.push(taskObj);
    });
    store.commit("setTasksArray", array);
    //   return array;
    //   });

    const editDateToDo = (task) => {
      //console.log(task);
      store.commit("setShowDateTime", true);
      store.commit("setEditingTask", { name: task.name, prop: "dateToDo" });
      // task.show.value = !task.show.value;
    };
    const editDateDone = (task) => {
      //console.log(task);
      store.commit("setShowDateTime", true);
      store.commit("setEditingTask", { name: task.name, prop: "dateDone" });
      // task.show.value = !task.show.value;
    };
    return {
      // store,
      editDateToDo,
      editDateDone,
      bookOutline,
    };
  },
  components: {
    IonItem,
    IonLabel,
    IonItemDivider,
    IonList,
    IonButton,
    IonIcon,
  },
});
</script>
