<template>
  <Pie :data="chartData" :options="chartOptions" style="width: 500px; max-width: 100vw" />
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "vue-chartjs";

ChartJS.register(ArcElement, Tooltip, Legend);

export default {
  name: "PieChart",
  components: {
    Pie,
  },
  computed: {
    chartData() {
      let data = [];
      const labelsSpecimensByGenus = [];
      const dataSpecimensByGenus = [];

      if (this.$store.state.selectedFeaturedProject && this.$store.state.selectedFeaturedProject.stats) {
        data = Object.values(this.$store.state.selectedFeaturedProject.stats.genus);
      } else if (this.$store.state.fetchedSpecimensByGenus) {
        data = this.$store.state.fetchedSpecimensByGenus;
      }
      if (!data || !data.length) {
        data = [{
          "amount": 0,
          "nameFr": "empty",
          "code": "PR"
        }];
      }
      data.forEach((item) => {
        labelsSpecimensByGenus.push(item.nameFr);
        dataSpecimensByGenus.push(item.amount);
      });
      return /* mutable chart data */ {
        labels: labelsSpecimensByGenus,
        datasets: [
          {
            data: dataSpecimensByGenus,
/*             label: " ",
 */            backgroundColor: [
              "#ea5545",
              "#f46a9b",
              "#ef9b20",
              "#edbf33",
              "#ede15b",
              "#bdcf32",
              "#87bc45",
              "#27aeef",
              "#b33dc6",
            ],
          },
        ],
      };
    },
    chartOptions() {
      // Use matchMedia to check the user preference
      const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
      ChartJS.defaults.color = prefersDark.matches ? "white" : "black";
      return {
        responsive: false,
      };
    },
  },
  data() {
    return {
      /*       chartData: {
        labels: store.state.fetchedSpecimensByPlantationDateChartLabels,
        datasets: [ { data: store.state.fetchedSpecimensByPlantationDateChartData,
          label: 'Année de plantation',
            backgroundColor: '#009b81', } ]
      },
      chartOptions: {
        responsive: true
      } */
    };
  },
};
</script>
