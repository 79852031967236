/* eslint-disable no-prototype-builtins */
<template>
  <div class="collaborative-form-button-group">
    <ion-item v-if="!$store.state.user.uid || !$store.state.isLoggedIn">
      <ion-label class="ion-text-wrap"
        >Vous devez vous authentifier pour collaborer</ion-label
      >
      <ion-button style="padding-right: 8px;" :routerLink="'/userLogin/'" slot="end" @click="closeModal()">
        <ion-icon :icon="personOutline"></ion-icon>
      </ion-button>
    </ion-item>
    <ion-item
      class="ion-no-padding"
      style="--padding-top: 0px; --border-color: var(--ion-color-step-500)"
    >
      <ion-toolbar value="" class="ion-no-padding" style="padding: 0px">
        <!-- <ion-segment-button class="collaborative-form-button" :disabled="!$store.state.user" value="photo"
          @click="takePhoto('fullTree')">
          <ion-label>Photo</ion-label>
          <ion-icon :icon="camera"></ion-icon>
        </ion-segment-button> -->
        <ion-buttons
          slot="start"
          :style="{
            width:
              $store.state.formattedSpecimen.owning_group != 'villeMontreal'
                ? '28%'
                : '50%',
          }"
        >
          <ion-button
            class="collaborative-form-button"
            :disabled="!$store.state.user.uid || !$store.state.isLoggedIn"
            type="submit"
            value="favorite"
            @click="addLikeTag()"
            fill="clear"
          >
            <ion-icon
              style="margin-right: 4px"
              size="large"
              :icon="
                $store.state.formattedSpecimen.groupSpecific.collaborative
                  .likeTags &&
                $store.state.formattedSpecimen.groupSpecific.collaborative
                  .likeTags[$store.state.user.uid]
                  ? heart
                  : heartOutline
              "
              :color="
                $store.state.formattedSpecimen.groupSpecific.collaborative
                  .likeTags &&
                $store.state.formattedSpecimen.groupSpecific.collaborative
                  .likeTags[$store.state.user.uid]
                  ? 'danger'
                  : 'primary'
              "
            >
            </ion-icon>
            <ion-label
              style="
                position: absolute;
                color: var(--ion-text-color);
                font-size: smaller;
                font-weight: bold;
                margin-top:8px
              "
              :style="{'margin-left': isPlatform('ios') ? '-40px' : '-48px'}"
              color="primary"
              v-if="
                Object.keys(
                  $store.state.formattedSpecimen.groupSpecific.collaborative
                    .likeTags
                ).length > 0
              "
            >
              {{
                Object.keys(
                  $store.state.formattedSpecimen.groupSpecific.collaborative
                    .likeTags
                ).length
              }}
            </ion-label>
            <ion-label style="margin-top: 6px">Aimer</ion-label>
          </ion-button>
        </ion-buttons>
        <ion-buttons
          slot="end"
          :style="{
            width:
              $store.state.formattedSpecimen.owning_group != 'villeMontreal'
                ? '37%'
                : '50%',
          }"
        >
          <ion-button
            class="collaborative-form-button"
            :disabled="!$store.state.user.uid || !$store.state.isLoggedIn"
            type="submit"
            value="favorite"
            @click="
              $store.state.showNewLikeDetails = !$store.state.showNewLikeDetails
            "
            fill="clear"
          >
            <ion-icon
              size="large"
              style="margin-right: 4px"
              :icon="cameraOutline"
              :color="'primary'"
            ></ion-icon>
            <ion-label style="margin-top: 6px">Remarques</ion-label>
          </ion-button>
        </ion-buttons>
        <ion-buttons
          v-if="$store.state.formattedSpecimen.owning_group != 'villeMontreal'"
          slot="end"
          :style="{
            width:
              $store.state.formattedSpecimen.owning_group != 'villeMontreal'
                ? '33%'
                : '50%',
          }"
        >
          <ion-button
            fill="clear"
            slot="end"
            class="collaborative-form-button"
            :disabled="!$store.state.user.uid || !$store.state.isLoggedIn"
            type="reset"
            value="signaler"
            @click="
              openSignalementsPicker(
                1,
                structure.options[1].length,
                structure.options,
                structure.prefix
              )
            "
          >
            <!--           <ion-img src="../assets/icon/watering_can.png" style="height: 24px;margin-top:12px;margin-bottom: -2;"/>
 -->
            <ion-icon
              size="large"
              style="margin-right: 4px; height: 28px"
              :icon="medkitOutline"
              :color="'primary'"
            ></ion-icon>
            <ion-label style="margin-top: 6px">Signaler</ion-label>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-item>
    <photos-component
      container="collaborativeForm"
      v-if="
        $store.state.formattedSpecimen &&
        $store.state.formattedSpecimen.photos &&
        $store.state.formattedSpecimen.photos.length &&
        !(
          $store.state.formattedSpecimen.owning_group ==
            $store.state.userGroupMembership &&
          ($store.state.formattedSpecimen.owning_group != 'singleUser' ||
            $store.state.formattedSpecimen.author_uid == $store.state.user.uid)
        )
      "
    ></photos-component>
    <favorite-component
      v-if="
        $store.state.formattedSpecimen && Object.keys($store.state.formattedSpecimen.groupSpecific.collaborative.likes).length > 0
      "
    ></favorite-component>
    <report-component
      v-if="
        $store.state.formattedSpecimen && Object.keys($store.state.formattedSpecimen.groupSpecific.collaborative.reports).length > 0"
    ></report-component>
    <!--  <ion-item>
      <ion-button @click="saveSpecimen()">Sauvegarder</ion-button>
     
    </ion-item> -->
  </div>

  <ion-modal
    :is-open="$store.state.showDateTime"
    @didDismiss="dateTimeModalDismissed"
  >
    <ion-content>
      <ion-header>
        <ion-toolbar>
          <ion-title>
            <b>{{ $store.state.editingTask.name }} </b>
          </ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-datetime
        @ionChange="setDateTime"
        size="cover"
        showDefaultButtons="true"
        showClearButton="true"
        presentation="date"
        locale="fr-CA"
      >
      </ion-datetime>
    </ion-content>
  </ion-modal>

  <ion-modal
    :is-open="$store.state.showNewLikeDetails"
    @didDismiss="$store.state.showNewLikeDetails = false"
    class="favorite-details-modal"
  >
    <favorite-details addOrEdit="add"></favorite-details>
  </ion-modal>
</template>

<script>
import firebaseService from "@/services/firebaseService";
import mapService from "@/services/mapService";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonLabel,
  IonItem,
  IonModal,
  IonDatetime,
  alertController,
  IonButton,
  IonButtons,
  IonIcon,
  pickerController,
  isPlatform
} from "@ionic/vue";
import {
  personOutline,
  camera,
  cameraOutline,
  heart,
  heartOutline,
  medkitOutline,
  chatbubbleEllipsesOutline,
} from "ionicons/icons";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { usePhoto } from "@/composables/usePhoto";
import ReportComponent from "./ReportComponent.vue";
import FavoriteComponent from "./FavoriteComponent.vue";
import FavoriteDetails from "./FavoriteDetails.vue";
import PhotosComponent from "./PhotosComponent.vue";

let handleInput;
export default defineComponent({
  name: "collaborativeSpecimenForm",
  inheritAttrs: false,
  props: {
    name: String,
    mapTypeId: String,
    zoom: Number,
    createOrModify: String,
  },
  setup() {
    const store = useStore();
    const { takePhoto, photos } = usePhoto();
    const { errors, loading } = firebaseService();
    const specimen = ref();
    const formType = ref();

    const closeModal = () => {
      const modalRef = document.querySelector(
        ".modal-content-specimen-details"
      );
      modalRef.setCurrentBreakpoint(0.001);
    };

    let formattedSpecimen;
    if (store.state.createOrModifySpecimen == "create") {
      formType.value = "create";
      //console.log(formType.value);
      store.dispatch("formatNewSpecimen").then(function () {
        // get specimen object model
        //console.log(store.state.formattedSpecimen);
        formattedSpecimen = store.state.formattedSpecimen;
      });
    } else {
      formType.value = "modify";
      //console.log("modify specimen");
      /* store.dispatch("formatFetchedSpecimen",specimen.value).then(function () {
        // get specimen object model
        //console.log(store.state.formattedSpecimen);
        formattedSpecimen = store.state.formattedSpecimen;
      }); */
    }

    const setPlantation = (ev) => {
      //console.log(ev.detail.checked);
      formattedSpecimen.new_tree = ev.detail.checked;
      store.commit("setFormattedSpecimen", formattedSpecimen);
    };

    onMounted(() => {
      // check if current user has already tagliked this tree
    });

    const dateTimeModalDismissed = () => {
      //console.log("dateTimeModalDismissed");
      store.commit("setShowDateTime", false);
    };
    const setDateTime = (ev) => {
      //console.log(ev.detail.value);
      // set on task
      const array =
        store.state.formattedSpecimen.groupSpecific[
          store.state.userGroupMembership
        ].tasks;
      array.forEach((task) => {
        if (task.name === store.state.editingTask.name) {
          const prop = store.state.editingTask.prop;
          // only one can be set at a time
          if (prop == "dateToDo") {
            task.suivi.dateDone = null;
            task.suivi[prop] = ev.detail.value;
          } else {
            task.suivi.dateToDo = null;
            task.suivi[prop] = ev.detail.value;
          }
        }
      });
    };
    const defaultColumnOptions = [
    ["One", "Two", "Three", "Four", "Five"],
    ];

    const confirmAddPictureToReport = async (currentReport) => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Svp joindre une photo à votre signalement",
        message:
          "Si vous annulez l'opération, votre signalement ne sera pas sauvegardé.",
        buttons: [
          {
            text: "Annuler",
            role: "cancel",
            cssClass: "secondary",
            id: "cancel-button",
            handler: () => {
              // updating data
              delete store.state.formattedSpecimen.groupSpecific.collaborative
                .reports[currentReport.report_id];
            },
          },
          {
            text: "Ajouter photo",
            id: "confirm-button",
            handler: () => {
              takePhoto("reports", "Reports", currentReport);
            },
          },
        ],
      });
      return alert.present();
    };

    const structure = {
      options: [
        store.state.collaborativeFormObjModel.pickerOptions.col1,
        store.state.collaborativeFormObjModel.pickerOptions.col2,
      ],
      prefix: ["Localisation", "Structure"],
    };

    const phyto = {
      options: [
        store.state.collaborativeFormObjModel.pickerOptions.col1,
        store.state.collaborativeFormObjModel.pickerOptions.col2,
      ],
      prefix: ["Localisation", "Structure"],
    };

    function getColumns(numColumns, numOptions, columnOptions, prefix) {
      let columns = [];
      for (let i = 0; i < numColumns; i++) {
        columns.push({
          name: `col-${i}`,
          options: getColumnOptions(i, numOptions, columnOptions),
          //     prefix: prefix[i],
          //    optionsWidth: "3",
          //      prefixWidth: "1",
          // suffix: "suffix",
        });
      }
      return columns;
    }

    function getColumnOptions(columnIndex, numOptions, columnOptions) {
      let options = [];
      for (let i = 0; i < numOptions; i++) {
        options.push({
          text: columnOptions[columnIndex][i % numOptions],
          value: i,
        });
      }

      return options;
    }
    async function openSignalementsPicker(
      numColumns = 1,
      numOptions = 5,
      columnOptions = defaultColumnOptions,
      prefix = "prefix"
    ) {
      //console.log(columnOptions);
      const picker = await pickerController.create({
        cssClass: "ion-picker-modified",
        columns: getColumns(numColumns, numOptions, columnOptions, prefix),
        buttons: [
          {
            text: "Annuler",
            role: "cancel",
          },
          {
            text: "Confirmer",
            handler: (value) => {
              //console.log(`Got Value ${JSON.stringify(value)}`);
              let newReport = {
                author_email: store.state.user.email,
                author_uid: store.state.user.uid,
                author_name: store.state.user.displayName,
                dateDone: new Date(),
                report: value["col-0"].text,
                //         name: value["col-1"].text,
                //         type: prefix[1],
                report_id:
                  store.state.user.uid + "_" + new Date().getMilliseconds(),
                photos: [],
              };
              if (
                !store.state.formattedSpecimen.groupSpecific.collaborative
                  .reports
              ) {
                store.state.formattedSpecimen.groupSpecific.collaborative.reports =
                  {};
                store.state.formattedSpecimen.groupSpecific.collaborative.reports[
                  newReport.report_id
                ] = newReport;
              } else {
                store.state.formattedSpecimen.groupSpecific.collaborative.reports[
                  newReport.report_id
                ] = newReport;
              }
              confirmAddPictureToReport(newReport);
              /*  // save specimen
               store
                 .dispatch("formatSpecimenForServer", { specimen: store.state.formattedSpecimen, collaborativeForm: true })
                 .then(function (result) {
                 //console.log("specimen  saved on server");
                   //console.log(result);
                   //    loader.hide();
                   store.commit("setRepositionSpecimen", false);
                 })
                 .catch(function (error) {
                   alert("Unable to save specimen, try again later!");
                   //     loader.hide();
                   console.error("Error writing firestore document: ", error);
                 }); */
            },
          },
        ],
      });

      await picker.present();
    }

    const checkForm = (e) => {
      //console.log("checking form");
      const errors = [];

      // eslint-disable-next-line no-prototype-builtins
      if (!formattedSpecimen.groupSpecific.hasOwnProperty("omhm")) {
        if (formattedSpecimen.essence) {
          return true;
        } else {
          if (!formattedSpecimen.essence) {
            errors.push("Espèce");
          }
        }
      } else {
        if (
          formattedSpecimen.essence &&
          formattedSpecimen.photos.length > 0 &&
          formattedSpecimen.photos[0]._id &&
          formattedSpecimen.groupSpecific[store.state.userGroupMembership[0]]
            .observations.healthStatus.current.value &&
          formattedSpecimen.groupSpecific[store.state.userGroupMembership[0]]
            .observations.leafVolume.current.value &&
          formattedSpecimen.groupSpecific[store.state.userGroupMembership[0]]
            .observations.dhp.current.value.length > 0
        ) {
          return true;
        }

        if (!formattedSpecimen.essence) {
          errors.push("Espèce");
        }
        if (
          !formattedSpecimen.groupSpecific[store.state.userGroupMembership[0]]
            .observations.healthStatus.current.value
        ) {
          errors.push("État de santé");
        }
        if (
          !formattedSpecimen.groupSpecific[store.state.userGroupMembership[0]]
            .observations.leafVolume.current.value
        ) {
          errors.push("Défoliation");
        }
        if (
          formattedSpecimen.groupSpecific[store.state.userGroupMembership[0]]
            .observations.dhp.current.value.length === 0
        ) {
          errors.push("DHP");
        }
        if (!formattedSpecimen.photos.length > 0) {
          errors.push("Photo");
        }
      }
    };
    const saveSpecimen = () => {
      //in case we updated profile pic and returned to this page
      // formattedSpecimen.specimen_pic = store.state.profile.specimen_pic;
      // if (checkForm()) {
      const tre = true;
      if (tre) {
        //console.log("checkForm done, tryin to save");

        /*   // check if the specimen was set to dead
        if (
          formattedSpecimen.groupSpecific.hasOwnProperty(
            store.state.userGroupMembership[0]
          ) &&
          formattedSpecimen.groupSpecific[
            store.state.userGroupMembership[0]
          ].observations.hasOwnProperty("healthStatus") &&
          formattedSpecimen.groupSpecific[
            store.state.userGroupMembership[0]
          ].observations.healthStatus.current.value === "Mort" &&
          !formattedSpecimen.groupSpecific[
            store.state.userGroupMembership[0]
          ].observations.healthStatus.current.cod
        ) {
          presentAlertPrompt();
          dialogs
            .action({
              message:
                store.state.userGroupMembership[0] === "soverdi"
                  ? "Si vous poursuivez, ce spécimen sera considéré comme mort et pourra seulement être affiché sur la carte si vous ajoutez le filtre 'État de santé: Mort' à vos requêtes. \n Veuillez préciser la cause de la mortalité svp."
                  : "Veuillez préciser la cause de la mortalité svp.",
              cancelButtonText: "Annuler",
              actions: [
                "sécheresse",
                "déneigement",
                "tondeuse ou coupe bordure",
                "vandalisme ou vol",
                "animaux",
                "inconnue",
              ],
            })
            .then((result) => {
              //console.log(result);
              if (result !== "Annuler") {
                if (isIOS) {
                  loader.show();
                }
                store.commit(
                  "setDeletedSpecimen",
                  store.state.fetchedSpecimen
                );
                // add cause of death to healthStatus property
                formattedSpecimen.groupSpecific[
                  store.state.userGroupMembership[0]
                ].observations.healthStatus.current.cod = result;

                store.commit("setSavedSpecimen", formattedSpecimen);

                store
                  .dispatch("formatSpecimenForServer", formattedSpecimen)
                  .then(function (result) {
                    //console.log("specimen  saved on server");
                    //console.log(result);
                    loader.hide();
                    vm.editable = false;
                    vm.$navigateBack();

                    
                  })
                  .catch(function (error) {
                    alert("Unable to save specimen, try again later!");
                    loader.hide();
                    console.error("Error writing firestore document: ", error);
                  });
              } else {
              }
            });
        } else if (
          // check if specimen was abattu
          store.state.userGroupMembership[0] === "omhm" &&
          formattedSpecimen.groupSpecific.hasOwnProperty(
            store.state.userGroupMembership[0]
          ) &&
          formattedSpecimen.groupSpecific[
            store.state.userGroupMembership[0]
          ].tasks[0].suivi.hasOwnProperty("dateDone") &&
          formattedSpecimen.groupSpecific[
            store.state.userGroupMembership[0]
          ].tasks[0].suivi.dateDone
        ) {
          dialogs
            .confirm({
              title: "Ce spécimen sera considéré comme abattu",
              message:
                "Vous avez déclaré que l'abattage de l'arbre a été complété.  Si vous poursuivez, ce spécimen ne sera plus affiché sur la carte",
              okButtonText: "OK",
              cancelButtonText: "Annuler",
            })
            .then((result) => {
              //console.log(result);
              if (result) {
                if (isIOS) {
                  loader.show();
                }
                store.commit(
                  "setDeletedSpecimen",
                  store.state.fetchedSpecimen
                );
                store.commit("setSavedSpecimen", formattedSpecimen);

                store
                  .dispatch("formatSpecimenForServer", formattedSpecimen)
                  .then(function (result) {
                    //console.log("specimen  saved on server");
                    //console.log(result);
                    loader.hide();
                    vm.editable = false;
                    vm.$navigateBack();
                  })
                  .catch(function (error) {
                    alert("Unable to save specimen, try again later!");
                    loader.hide();
                    console.error("Error writing firestore document: ", error);
                  });
              }
            });
        } else { */

        store.commit("setSavedSpecimen", formattedSpecimen);

        store
          .dispatch("formatSpecimenForServer", formattedSpecimen)
          .then(function (result) {
            //console.log("specimen  saved on server");
            //console.log(result);
            //    loader.hide();
            store.commit("setRepositionSpecimen", false);
          })
          .catch(function (error) {
            alert("La sauvegarde du spécimen a échouée.  Veuillez réessayer plus tard.");
            //     loader.hide();
            console.error("Error writing firestore document: ", error);
          });
      }
      // }
    };
    const confirmDelete = async () => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Souhaitez-vous vraiment effacer ce spécimen?",
        message: "Cette action est irréversible!!!",
        buttons: [
          {
            text: "Annuler",
            role: "cancel",
            cssClass: "secondary",
            id: "cancel-button",
            handler: () => {
              // do nothing
            },
          },
          {
            text: "Effacer",
            id: "confirm-button",
            handler: () => {
              //console.log("Confirm Okay");
              deleteSpecimen();
            },
          },
        ],
      });
      return alert.present();
    };
    const deleteSpecimen = () => {
      store
        .dispatch(
          "batchDeleteSpecimenInfoOnRemote",
          store.state.formattedSpecimen
        )
        .then(function (result) {
          //console.log("deleted specimen archived on server");
          //console.log(result);
          // loader.hide();
        })
        .catch(function (error) {
          alert("Le spécimen n'a pas été effacé. Veuillez réessayer plus tard svp.");
          console.error(" Error writing firestore document: ", error);
        });
    };
    const addLikeTag = () => {
      if (
        store.state.formattedSpecimen.groupSpecific.collaborative.likeTags &&
        store.state.formattedSpecimen.groupSpecific.collaborative.likeTags[
          store.state.user.uid
        ]
      ) {
        // current user has already liked this tree
        // confirm if user really wants to unlike this tree
        const confirmRemoveLikeTag = async () => {
          const alert = await alertController.create({
            cssClass: "my-custom-class",
            header: "Souhaitez-vous vraiment retirer cet arbre de vos favoris?",
            message: "",
            buttons: [
              {
                text: "Annuler",
                role: "cancel",
                cssClass: "secondary",
                id: "cancel-button",
                handler: () => {
                  //console.log("Confirm Cancel:");
                },
              },
              {
                text: "Retirer",
                id: "confirm-button",
                handler: () => {
                  delete store.state.formattedSpecimen.groupSpecific
                    .collaborative.likeTags[store.state.user.uid];
                  // remove collaborative icon
                  /* for (
                    let index = 0;
                    index < store.state.likeTagsInSelection.length;
                    index++
                  ) {
                    const element = store.state.likeTagsInSelection[index];
                    console.log(element);
                    if (
                      element.properties.specimen_id == store.state.formattedSpecimen._id
                    ) {
                      store.state.likeTagsInSelection.splice(index, 1);
                      break;
                    }
                  } */

                  if (
                    Object.keys(
                      store.state.formattedSpecimen.groupSpecific.collaborative
                        .likeTags
                    ).length < 1
                  ) {
                    // remove collaborative icon
                    for (
                      let index = 0;
                      index < store.state.collaborativeDataQueryObj.length;
                      index++
                    ) {
                      const collaborativeData =
                        store.state.collaborativeDataQueryObj[index];
                      if (
                        collaborativeData.specimen_id ==
                          store.state.fetchedSpecimen.geojsonFeature.properties
                            ._id &&
                        collaborativeData.has_likeTags
                      ) {
                        console.log(collaborativeData);
                        if (!collaborativeData.likeTags) {
                          collaborativeData.has_likeTags = false;
                          break;
                        } else if (
                          Object.keys(collaborativeData.likeTags).length < 2
                        ) {
                          collaborativeData.has_likeTags = false;
                          break;
                        }
                      }
                    }
                  }

                  /* 
                  store.state.collaborativeDataQueryObj.forEach(
                    (collaborativeData, i) => {
                      if (
                        collaborativeData.specimen_id ==
                          store.state.fetchedSpecimen.geojsonFeature.properties
                            ._id &&
                        collaborativeData.has_likeTags
                      ) {
                        // check if we need to remove a collaborative icon from the marker
                        let collaborativeTypeCounter = 0;
                        store.state.collaborativeDataQueryObj.forEach(
                          (collaborativeData, i) => {
                            if (
                              collaborativeData.specimen_id ==
                                store.state.fetchedSpecimen.geojsonFeature
                                  .properties._id &&
                              collaborativeData.has_likeTags
                            ) {
                              collaborativeTypeCounter++;
                              if (collaborativeTypeCounter < 2) {
                                // there was no or only one like. Set has_likes to false
                                collaborativeData.has_likeTags = false;
                              }
                            }
                          }
                        );
                      }
                    }
                  ); */
                  mapService.addCollaborativeIcons();
                  // save right away in collaborative collection
                  store
                    .dispatch("formatSpecimenForServer", {
                      specimen: store.state.formattedSpecimen,
                      collaborativeForm: true,
                    })
                    .then(function (result) {
                      //console.log("specimen saved on server");
                    })
                    .catch(function (error) {
                      alert("La sauvegarde du spécimen a échouée.  Veuillez réessayer plus tard.");
                      //     loader.hide();
                      console.error(
                        "Error writing firestore document: ",
                        error
                      );
                    });
                },
              },
            ],
          });
          return alert.present();
        };
        confirmRemoveLikeTag();
      } else {
        let newLikeTag = {
          author_email: store.state.user.email,
          author_uid: store.state.user.uid,
          author_name: store.state.user.displayName,
          dateDone: new Date(),
          likeTag_id: store.state.user.uid,
        };
        if (
          !store.state.formattedSpecimen.groupSpecific.collaborative.likeTags
        ) {
          store.state.formattedSpecimen.groupSpecific.collaborative.likeTags =
            {};
          store.state.formattedSpecimen.groupSpecific.collaborative.likeTags[
            newLikeTag.likeTag_id
          ] = newLikeTag;
        } else {
          store.state.formattedSpecimen.groupSpecific.collaborative.likeTags[
            newLikeTag.likeTag_id
          ] = newLikeTag;
        }
        // add collaborative icon onto map
        /* let likeTag = {
          type: "Feature",
          geometry: store.state.fetchedSpecimen.geojsonFeature.geometry,
          properties: {
            specimen_id: store.state.fetchedSpecimen.geojsonFeature.properties._id,
          },
        }; */
        //    store.state.likeTagsInSelection.push(likeTag);

        store.state.collaborativeDataQueryObj.push({
          has_likeTags: true,
          specimen_id:
            store.state.fetchedSpecimen.geojsonFeature.properties._id,
          firestoreId:
            store.state.fetchedSpecimen.geojsonFeature.properties.firestoreId,
          g: {
            geopoint: {
              _lat: store.state.fetchedSpecimen.geojsonFeature.geometry
                .coordinates[1],
              _long:
                store.state.fetchedSpecimen.geojsonFeature.geometry
                  .coordinates[0],
            },
          },
        });
        mapService.addCollaborativeIcons();
        //console.log(store.state.formattedSpecimen);
        //console.log(store.state.user.uid);
        // save right away in collaborative collection
        store
          .dispatch("formatSpecimenForServer", {
            specimen: store.state.formattedSpecimen,
            collaborativeForm: true,
          })
          .then(function (result) {
            //console.log("specimen saved on server");
          })
          .catch(function (error) {
            alert("La sauvegarde du spécimen a échouée.  Veuillez réessayer plus tard.");
            //     loader.hide();
            console.error("Error writing firestore document: ", error);
          });
      }
    };

    return {
      specimen,
      errors,
      loading,
      formType,
      handleInput,
      dateTimeModalDismissed,
      setDateTime,
      setPlantation,
      formattedSpecimen,
      saveSpecimen,
      confirmDelete,
      openSignalementsPicker,
      personOutline,
      camera,
      cameraOutline,
      heart,
      heartOutline,
      medkitOutline,
      takePhoto,
      chatbubbleEllipsesOutline,
      defaultColumnOptions,
      structure,
      phyto,
      photos,
      confirmAddPictureToReport,
      addLikeTag,
      closeModal,
      isPlatform
    };
  },
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonLabel,
    IonModal,
    IonDatetime,
    IonContent,
    PhotosComponent,
    FavoriteDetails,
    ReportComponent,
    FavoriteComponent,
    IonIcon,
    IonItem,
    IonButton,
    IonButtons,
  },
});
</script>
