/* eslint-disable no-prototype-builtins */
<template>
  <div style="background-color: black">
    <ion-item-divider>
      <ion-label class="form-divider-title">Habitation</ion-label>
    </ion-item-divider>
    <!--       Sélectionner habitation -->
    <span v-if="$store.state.formattedSpecimen">
      <ion-item class="ion-text-wrap"
        ><ion-label>{{
          $store.state.formattedSpecimen.groupSpecific[
            $store.state.userGroupMembership
          ].place.name ? $store.state.formattedSpecimen.groupSpecific[
            $store.state.userGroupMembership
          ].place.name : $store.state.formattedSpecimen.groupSpecific[
            $store.state.userGroupMembership
          ].place.habitationName
        }} <b>{{
          $store.state.formattedSpecimen.groupSpecific[
            $store.state.userGroupMembership
          ].place.habitationNumber
        }}</b></ion-label
        ><ion-button
          slot="end"
          fill="outline"
          @click="setShowSearchInputElements"
          >Modifier</ion-button
        ></ion-item
      >
      <ion-item class="ion-text-wrap">{{
        $store.state.formattedSpecimen.groupSpecific[
          $store.state.userGroupMembership
        ].place.address
      }}</ion-item>
    </span>
    <div v-show="showSearchInputElements">
      <ion-searchbar
        placeholder="filtrer liste des habitations"
        showCancelButton="false"
        debounce="0"
        v-model="searchInput"
      >
      </ion-searchbar>
      <ion-list
        class="habitations-selector"
        v-if="$store.state.omhmAddresses && $store.state.omhmAddresses.features"
      >
        <ion-item
          v-for="habitation in $store.state.omhmAddresses.features"
          :key="habitation"
          @click="setChosenHabitation(habitation)"
          v-show="
          habitation.properties.place.habitationName.indexOf(searchInput) > -1 ||
          !searchInput
        "
          >{{ habitation.properties.place.habitationName }}</ion-item
        >
      </ion-list>
    </div>
  </div>
</template>

<script>
import firebaseService from "@/services/firebaseService";
import {
  IonLabel,
  IonItem,
  IonSearchbar,
  IonItemDivider,
  IonList,
  IonButton,
} from "@ionic/vue";
import { defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
//console.log("specimenPage omhm loaded");
let handleInput;
export default defineComponent({
  name: "specimenPageOmhm",
  props: {
    name: String,
    mapTypeId: String,
    zoom: Number,
    createOrModify: String,
  },
  setup(props) {
    const store = useStore();
    const { errors, loading, fetchOMHMAddresses } =
      firebaseService();
    const searchInput = ref();
    const selectedHabitation = ref();
    const showSearchInputElements = ref();
    fetchOMHMAddresses(null, 500, false);
    let formattedSpecimen;

    onMounted(() => {
     
      let items = [];
      if (document.querySelector(".habitations-selector")) {
        items = Array.from(
          document.querySelector(".habitations-selector").children
        );
      }
      watch(
        () => searchInput.value,
        (/* old,new */) => {
          const query = searchInput.value.toLowerCase();
          requestAnimationFrame(() => {
            items.forEach((item) => {
              const shouldShow =
                item.textContent.toLowerCase().indexOf(query) > -1;
              item.style.display = shouldShow ? "block" : "none";
            });
          });
        }
      );
      handleInput = (event) => {
        const query = event.target.value.toLowerCase();
        requestAnimationFrame(() => {
          items.forEach((item) => {
            const shouldShow =
              item.textContent.toLowerCase().indexOf(query) > -1;
            item.style.display = shouldShow ? "block" : "none";
          });
        });
      };
    });

    const setShowSearchInputElements = () => {
      showSearchInputElements.value = !showSearchInputElements.value;
    };
    const setChosenHabitation = (habitation) => {
      //console.log("setting chosen habitation");
      //console.log(habitation);
      selectedHabitation.value = habitation;
      store.state.formattedSpecimen.groupSpecific[
        store.state.userGroupMembership
      ].place = habitation.properties.place;
      // also add name property for legacy reasons and to make habitations query work
      store.state.formattedSpecimen.groupSpecific[
        store.state.userGroupMembership
      ].place.name = habitation.properties.place.habitationName;
      showSearchInputElements.value = false;
    };

    return {
      errors,
      loading,
      handleInput,
      searchInput,
      IonList,
      setShowSearchInputElements,
      setChosenHabitation,
      showSearchInputElements,
      formattedSpecimen,
    };
  },
  components: {
    IonLabel,
    IonItem,
    IonSearchbar,
    IonList,
    IonItemDivider,
    IonButton,
  },
});
</script>