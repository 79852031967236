<template>
  <ion-toolbar
    v-if="$store.state.formattedSpecimen"
    id="specimenbasicinfos"
    class="specimen-details-border-top ion-no-padding"
  >
    <specimen-basic-infos
      v-if="$store.state.formattedSpecimen"
      @click="testBreakPoint()"
    ></specimen-basic-infos>
    <ion-button
      fill="clear"
      @click="closeSpecimenDetails()"
      class="side-menu-close-button"
    >
      <ion-icon :icon="closeCircle"></ion-icon>
    </ion-button>
  </ion-toolbar>

  <!--     <div>allo{{ $store.state.userGroupMembership }}  {{ $store.state.formattedSpecimen.author_uid }} {{ $store.state.user.uid }} </div>
 -->

  <ion-content class="specimen-details-container" v-if="$store.state.formattedSpecimen">
    <div class="side-menu-specimen-content" id="sideMenuSpecimen">
      <collaborative-specimen-form
        id="collaborativeSpecimenForm"
      ></collaborative-specimen-form>
      <single-user-form
        v-if="
          $store.state.userGroupMembership == 'singleUser' &&
          $store.state.formattedSpecimen.author_uid == $store.state.user.uid
        "
        id="singleUserForm"
      ></single-user-form>
      <specimen-page-omhm
        v-else-if="
          $store.state.userGroupMembership == 'omhm' &&
          $store.state.formattedSpecimen.owning_group == 'omhm'
        "
        :createOrModify="'modify'"
        id="specimenPageOmhm"
      ></specimen-page-omhm>
     <!--  <specimen-page-soverdi
        v-else-if="
          $store.state.userGroupMembership == 'soverdi' &&
          $store.state.formattedSpecimen.owning_group == 'soverdi'
        "
        :createOrModify="'modify'"
        id="specimenPageSoverdi"
      ></specimen-page-soverdi> -->
      <!-- <ion-item>
      <ion-label>
        {{ firestoreId }}
      </ion-label>
    </ion-item> -->

      <!-- <ion-item v-if="errors.length">
      <ion-text>
        {{ errors }}
      </ion-text>
    </ion-item> -->
      <ion-loading :isOpen="false"></ion-loading>
    </div>
  </ion-content>
</template>

<script>
import firebaseService from "@/services/firebaseService";
import mapService from "@/services/mapService";
import {
  IonLoading,
  IonContent,
  IonIcon,
  IonButton,
  IonToolbar,
} from "@ionic/vue";
import { defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
import SpecimenBasicInfos from "./specimenBasicInfos.vue";
import SpecimenPageOmhm from "@/components/specimenPageOMHM.vue";
/* import SpecimenPageSoverdi from "@/components/specimenPageSoverdi.vue";
 */import singleUserForm from "@/components/singleUserForm.vue";
import collaborativeSpecimenForm from "@/components/collaborativeSpecimenForm.vue";
import { closeCircle } from "ionicons/icons";
export default defineComponent({
  name: "sideMenuSpecimen",
  setup() {
    const { errors, loading } =
      firebaseService();
    const store = useStore();
    const specimen = ref();
    const firestoreId = ref();

    const testBreakPoint = () => {
      const modalRef = document.querySelector(
        ".modal-content-specimen-details"
      );
    //console.log(modalRef);
   //  modalRef.setCurrentBreakpoint(store.state.breakPointRelativeToContent);
      // also centermap on specimen
      store.state.relativeToContent();
    };

    const closeSpecimenDetails = () => {
      //
    //console.log("closing specimenDetails");
    //console.log(store.state.sideMenuRef);
      mapService.deselectMarker();
      /* const slideOutAnimation = createAnimation()
        .addElement(store.state.sideMenuRef)
        .duration(250)
        //  .fromTo("opacity", "1", "0");
        .fromTo("transform", "translateY(0)", `translateY(1000px)`);
      const slideInAnimation = createAnimation()
        .addElement(store.state.sideMenuRef)
        .duration(0)
        //       .fromTo("opacity", "0", "1");
        .fromTo("transform", "translateY(1000px)", `translateY(0)`);
      slideOutAnimation.play(); */
      //  setTimeout(() => {
      const modalRef = document.querySelector(
        ".modal-content-specimen-details"
      );
      modalRef.setCurrentBreakpoint(0.001);
      // store.state.currentSegment = null;
    //  store.state.breakPointRelativeToContent = 0.001;
      //    slideInAnimation.play();
      //   }, 251);
      if (
        store.state.userGroupMembership == "omhm" &&
        store.state.showNewSpecimenModal
      ) {
        store.commit("setShowNewSpecimenModal", false);
    //    mapService.clearGetCurrentPositionSetInterval();
      }
    };
    onMounted(() => {
    //console.log("onMounted");
    });
    // //console.log(route.params.firestoreId);
    /* watch(
      () => store.state.chosenSpecimenMarkerFirestoreId,
      () => {
        //console.log(
          "watch store.state.chosenSpecimenMarkerFirestoreId changed"
        );
        firestoreId.value = store.state.chosenSpecimenMarkerFirestoreId;
        specimen.value = computed(
          () =>
            store.state.fetchedSpecimensObj[
              store.state.chosenSpecimenMarkerFirestoreId
            ]
        );
        store.commit(
          "setFetchedSpecimen",
          store.state.fetchedSpecimensObj[
            store.state.chosenSpecimenMarkerFirestoreId
          ]
        );
        store
          .dispatch(
            "formatFetchedSpecimen",
            store.state.fetchedSpecimensObj[
              store.state.chosenSpecimenMarkerFirestoreId
            ].geojsonFeature.properties
          )
          .then(function () {
            // get specimen object model
            //console.log(store.state.formattedSpecimen);
             //console.log(
              store.state.formattedSpecimen.groupSpecific.omhm.observations
                .healthStatus.current.value
            );
            //console.log(
              store.state.formattedSpecimen.groupSpecific.omhm.observations.dhp
                .current.dhpTotal
            );
            //console.log(
              store.state.formattedSpecimen.groupSpecific.omhm.observations
                .leafVolume.current.value
            );
            //console.log(
              store.state.formattedSpecimen.groupSpecific.omhm.observations
                .mauvaisEmplacement.current.value
            );
            //console.log(store.state.chosenSpecimenMarkerFirestoreId);

            //   formattedSpecimen = store.state.formattedSpecimen;
          });
      }
    ); */

    return {
      specimen,
      errors,
      loading,
      firestoreId,
      closeSpecimenDetails,
      closeCircle,
      testBreakPoint,
    };
  },
  components: {
    IonLoading,
    SpecimenBasicInfos,
    SpecimenPageOmhm,
/*     SpecimenPageSoverdi,
 */    singleUserForm,
    collaborativeSpecimenForm,
    IonContent,
    IonIcon,
    IonButton,
    IonToolbar,
  },
});
</script>
