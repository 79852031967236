<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="goBack">{{
            $store.state.showCleAddNewSpecimen ? "Annuler" : "Retour"
          }}</ion-button>
        </ion-buttons>
        <ion-title v-if="$store.state.cleIdentificationChosenSpecies.title">Clé d'identification</ion-title>
        <ion-title v-else>Espèce sélectionnée</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <specimen-basic-infos></specimen-basic-infos>
      <ion-item v-if="$store.state.cleIdentificationChosenSpecies && $store.state.cleIdentificationChosenSpecies.description && $store.state.cleIdentificationChosenSpecies.description.length > 0">
        <ion-label
          class="ion-text-wrap"
        >
          {{ $store.state.cleIdentificationChosenSpecies.description }}
        </ion-label>
      </ion-item>
      <photos-component container="CleAddNewSpecimen"></photos-component>
      
      <ion-item class="ion-padding">
        <ion-button
        size="default"
        style="margin: auto;"
          @click="saveSpecimen()"
          :disabled="
            !(
              ($store.state.pictureSet.fullTree ||
                $store.state.pictureSet.flower ||
                $store.state.pictureSet.fruit ||
                $store.state.pictureSet.leaf) &&
              ($store.state.pictureSet.fullTree ||
                $store.state.pictureSet.flower ||
                $store.state.pictureSet.fruit ||
                $store.state.pictureSet.leaf)
            )
          "
          >Sauvegarder</ion-button
        >
      </ion-item>

     
    </ion-content>
  </ion-page>
</template>

<script>
import {
  modalController,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
} from "@ionic/vue";
import { defineComponent } from "vue";
import { useStore } from "vuex";

// the detail page
import specimenBasicInfos from "./specimenBasicInfos.vue";
import PhotosComponent from "./PhotosComponent.vue";

export default defineComponent({
  name: "CleAddNewSpecimen",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonButton,
    IonTitle,
    IonContent,
    specimenBasicInfos,
    PhotosComponent,
  },
  props: ["modalNav"],
  setup(props) {
    const store = useStore();
    //console.log("opening cleAddNewSpecimen");

    /**
     *  when going to the next page, I pass the nav as a property
     * so I don't need to get it from the document again
     */
    const nextPage = (componentName) => {
      store.state.modalNav.value.push(componentName, {
        // these come across as properties on the component
        modalNav: store.state.modalNav,
      });
    };
    /**
     * close the modal dialog
     */
    const closeModal = async () => {
      await modalController.dismiss();
    };

    const goBack = () => {
      if (store.state.showCleAddNewSpecimen) {
        store.state.showCleAddNewSpecimen = false;
      } else {
        const nav = props.modalNav;
        nav.pop();
      }
    };

    const saveSpecimen = () => {
      //in case we updated profile pic and returned to this page
      // formattedSpecimen.specimen_pic = store.state.profile.specimen_pic;

      store
        .dispatch("formatSpecimenForServer", store.state.formattedSpecimen)
        .then(function (result) {
          //console.log("specimen  saved on server");
          //console.log("store.state.formattedSpecimen");
          //console.log(store.state.formattedSpecimen);
          // remove watch position because geolocalization is done
          //   mapService.clearGetCurrentPositionSetInterval();
          //console.log(result);
          //    loader.hide();
          store.commit("setRepositionSpecimen", false);
          // close cle identification modal
          closeModal();
        })
        .catch(function (error) {
          alert("La sauvegarde du spécimen a échouée.  Veuillez réessayer plus tard.");
          //     loader.hide();
          console.error("Error writing firestore document: ", error);
        });
      // }
    };

    return {
      nextPage,
      closeModal,
      goBack,
      saveSpecimen,
    };
  },
});
</script>
