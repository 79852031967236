/* eslint-disable no-prototype-builtins */

import firebaseService from "../services/firebaseService";
import { getFirestore, writeBatch, doc, GeoPoint } from "firebase/firestore";
import specimensColor from "../services/specimensColor";
import encodeGeohash from "../services/encodeGeohash";
import requestParameters from "../services/requestParameters";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import AnimatedPopup from "mapbox-gl-animated-popup";
import { nearestPoint, bbox, distance } from "@turf/turf";
import { Geolocation } from "@capacitor/geolocation";
import store from "../store";

mapboxgl.accessToken =
  "pk.eyJ1IjoidGdsIiwiYSI6ImNqcWNscGE5bTBseXk0M3B0dWI5Mm13MXYifQ.dRym0-KZQsdbUpUDaHk5VQ";
console.log("loading mapService");
var map;
const pulsingDotSize = 200;
const pulsingDot = {
  width: pulsingDotSize,
  height: pulsingDotSize,
  data: new Uint8Array(pulsingDotSize * pulsingDotSize * 4),

  // When the layer is added to the map,
  // get the rendering context for the map canvas.
  onAdd: function () {
    const canvas = document.createElement("canvas");
    canvas.width = this.width;
    canvas.height = this.height;
    this.context = canvas.getContext("2d");
  },

  // Call once before every frame where the icon will be used.
  render: function () {
    const duration = 2000;
    const t = (performance.now() % duration) / duration;

    const radius = (pulsingDotSize / 2) * 0.3;
    const outerRadius = (pulsingDotSize / 2) * 0.7 * t + radius;
    const context = this.context;



    // Draw the outer circle.
    context.clearRect(0, 0, this.width, this.height);
    context.beginPath();
    context.arc(this.width / 2, this.height / 2, outerRadius, 0, Math.PI * 2);
    context.fillStyle = `rgba(0, 155, 105, ${1 - t})`;
    context.fill();

    // Draw the inner circle.
    context.beginPath();
    context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
    context.fillStyle = "rgba(0, 155, 105, 1)";
    context.strokeStyle = "white";
    //context.lineWidth = 2 + 4 * (1 - t);
    context.lineWidth = 2;
    context.fill();
    context.stroke();

    if (store.state.orientationHandler) {
    // Draw the triangle
    context.beginPath();
    context.moveTo(100, 30);
    context.lineTo(125, 60);
    context.lineTo(75, 60);
    context.lineTo(100, 30);
    context.lineWidth = 2;
    context.fillStyle = "darkorange";
    context.strokeStyle = "white";
    context.fill();
    context.stroke();
  }

    // Update this image's data with data from the canvas.
    this.data = context.getImageData(0, 0, this.width, this.height).data;

    // Continuously repaint the map, resulting
    // in the smooth animation of the dot.
    map.triggerRepaint();

    // Return `true` to let the map know that the image was updated.
    return true;
  },
};

const enableWatchPosition = (centerMap) => {
  /*   if (store.state.showNearestSpecimenModal) {
    // run setNearest specimen once immediatly
    setNearestSpecimen();
    map.flyTo({
      center: store.state.mapCenter,
      zoom: map.getZoom() < 18 ? 18 : map.getZoom(),
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    });
  } */
  const watchId = Geolocation.watchPosition(
    { enableHighAccuracy: true },
    (coordinates, err) => {

      function moveendLoadSpecimensCallback() {
        store.state.queryOptions.radius = 100;
        var specimensLoaded = firebaseService().loadSpecimens();
        specimensLoaded.then(function (res) {
          setNearestSpecimen();
        });
        map.off("moveend", moveendLoadSpecimensCallback);
      }
      store.commit("setShowTryingToAcquirePosition", false);
      if (coordinates) {
        //console.log("Current position:", coordinates);
        store.commit("setCurrentPosition", coordinates);
        animateUserLocationOnMap();
        if (store.state.showNearestSpecimenModal || store.state.showCompass) {
          store.commit("setMapCenter", [
            coordinates.coords.longitude,
            coordinates.coords.latitude,
          ]);
          if (
            // check if user has moved more than 90 meters since last geoquery, if so fetch more specimens
            calculateDistanceBetweenTwoPoints(
              store.state.lastGeoqueryCoordinatesLngLat,
              store.state.mapCenter
            ) > 0.09
          ) {
            map.easeTo({
              center: store.state.mapCenter,
              zoom: map.getZoom() < 18 ? 18 : map.getZoom(),
              curve: 1,
              speed: 0.1,
              essential: true, // this animation is considered essential with respect to prefers-reduced-motion
            });
            map.on("moveend", moveendLoadSpecimensCallback);
          } else {
            // user has not moved more than 100m away from where it was at its last geoquery
            setNearestSpecimen();
            map.easeTo({
              center: store.state.mapCenter,
              zoom: map.getZoom() < 18 ? 18 : map.getZoom(),
              curve: 1,
              speed: 0.1,
              essential: true, // this animation is considered essential with respect to prefers-reduced-motion
            });
          }
        }
      }
    }
  );
  store.commit("setWatchId", watchId);
};
let popupTimeout = null;
let previousNearest = { properties: { _id: null } };
let popupNumber = 0;
let popupNumberStr = "0";

const setNearestSpecimen = () => {

  //console.log([store.state.currentPosition.coords.longitude, store.state.currentPosition.coords.latitude]);
  //console.log(store.state.fetchedSpecimensAsFeatureCollection);
  if (
    store.state.fetchedSpecimensAsFeatureCollection &&
    store.state.fetchedSpecimensAsFeatureCollection.features.length
  ) {
    var nearest = nearestPoint(
      [
        store.state.currentPosition.coords.longitude,
        store.state.currentPosition.coords.latitude,
      ],
      store.state.fetchedSpecimensAsFeatureCollection
    );
    //console.log(nearest);
    var distanceFromUser = calculateDistanceBetweenTwoPoints(
      store.state.mapCenter,
      nearest.geometry.coordinates
    );
    //console.log(distanceFromUser);
    var distanceFromUserReformatedInMeters = (distanceFromUser * 1000).toFixed(
      0
    );
    //console.log(distanceFromUserReformatedInMeters);
    // if distance is less than 10 meters and if it's not the same specimen, update nearestSpecimen value
    if (
      distanceFromUserReformatedInMeters < 10 &&
      nearest.properties._id !== previousNearest.properties._id
    ) {
      const previous = parseInt(popupNumberStr) - 1;
      if (popupNumber > previous && popupNumber > 0) {
        popup[popupNumberStr].remove();
        if (popupTimeout)
          clearTimeout(popupTimeout);
      }
      popupNumber++;
      popupNumberStr = popupNumber + "";
      popup[popupNumberStr] = new AnimatedPopup({
        openingAnimation: {
          duration: 1000,
          easing: "easeOutElastic",
          transform: "scale",
        },
        closingAnimation: {
          duration: 300,
          easing: "easeInBack",
          transform: "scale",
        },
        offset: 20,
      });

      previousNearest = JSON.parse(JSON.stringify(nearest));

      store.commit("setNearestSpecimen", {
        geojsonFeature: nearest,
        distanceFromUser: distanceFromUserReformatedInMeters,
      });

      var coordinates = nearest.geometry.coordinates;
      //console.log("locateOutlinefrommapservice");
      //console.log(locateOutline);
      var description =
        "<div id='openDetails" + popupNumberStr + "'><ion-item class='soverdi-projects-marker-popup' style='--background:white !important;background-color:transparent !important;--border-color:white !important;--border-width:10px !important;--border-radius:10px !important;min-width:200px !important'>" +
        "<ion-label class='ion-text-wrap' style='color:black !important;margin:0px'><b>" +
        nearest.properties.essenceName.fr.name +
        "</b><h4 v-if='store.state.returnPlantationYear({properties:$store.state.formattedSpecimen})'>" +
        /* nearest.properties.plant_date.slice(0, 10) + */
        store.state.returnPlantationYearForPopup(nearest) +
        "</h4>" +
        "<h4>" +
        store.state.returnOwner(nearest) +
        "</h4>" +
        "</ion-label></ion-item></div>";

      //  $scope.editProject('md', snapshot.val());

      /* // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
 
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    } */

      popup[popupNumberStr].setLngLat(coordinates).setHTML(description);

      popup[popupNumberStr].addTo(map);

      // remove popup after 6 seconds
      popupTimeout = setTimeout(() => {
        console.log("remove popup number " + popupNumberStr);
        // console.log(popup);
        if (popupNumber > previous)
          popup[popupNumberStr].remove();
        /*         popupNumber = 1;
                popupNumberStr = "0"; */
      }, 5000);
      const openNearestSpecimenDetails =
        document.getElementById("openDetails" + popupNumberStr);
      console.log("openNearestSpecimenDetails");
      console.log(openNearestSpecimenDetails);
      openNearestSpecimenDetails.addEventListener("click", function () {
        console.log("openNearestSpecimenDetails");
        selectSpecimenOnMap({ features: [nearest] });
        // popup[popupNumberStr].remove();
      });


    }
    //console.log("nearest specimen set");
  } else {
    store.commit("setNearestSpecimen", { distanceFromUser: null });
  }
};

const calculateDistanceBetweenTwoPoints = (from, to) => {
  var options = { units: "kilometers" };
  var distanceRes = null;
  if (from && to) {
    distanceRes = distance(from, to, options);
  }
  //console.log("distance: " + distanceRes);
  return distanceRes;
  // find closest specimen
};

/* function watchPositionCallback() {
  //console.log("reading callback");
  getCurrentPosition({ centerMap: true }).then(() => {
    if (
      // check if user has moved more than 100 meters since last geoquery
      calculateDistanceBetweenTwoPoints(
        store.state.lastGeoqueryCoordinatesLngLat,
        store.state.mapCenter
      ) > 0.1
    ) {
      // there are no specimens on map.  Fetch a few.  Unsuscribe from setInterval while fetching specimens.
      //console.log("clearing interval");
      //  clearGetCurrentPositionSetInterval();
      var specimensLoaded = firebaseService().loadSpecimens();
      specimensLoaded.then(function (res) {
        //console.log(res);
        //  store.commit("setFetchedSpecimens", firebaseService().specimenMarkers);
        //    setNearestSpecimen();
        // reapply setInterval
        //   enableWatchPosition();
      });
    } else {
      setNearestSpecimen();
    }
  });
} */

const clearGetCurrentPositionSetInterval = () => {
  //console.log("clearing interval");
  store.commit("setShowTryingToAcquirePosition", false);
  //console.log("clear watch");
  if (
    store.state.watchId != null &&
    !store.state.showNearestSpecimenModal &&
    !store.state.showNewSpecimenModal
  ) {
    store.state.watchId.then((res) => {
      //console.log("clear watch");
      //console.log("res");
      Geolocation.clearWatch({ id: res });
    });
  }
};

// get user position using native plugin
const getCurrentPosition = async (options) => {
  //console.log("trying to get position");
  store.commit("setShowTryingToAcquirePosition", true);
  const coordinates = await Geolocation.getCurrentPosition({
    enableHighAccuracy: true,
    timeout: options.timeOut ? options.timeOut : 4500, // has to timout in less time than setInterval
    maximumAge: 500,
  }).catch((err) => {
    //console.log("error in getcurrentposition");
    //console.log(err);
    if (options.timeOut && err.code == 3) {
      alert(
        "Le délai pour rafraîchir votre position est écoulé.  Réessayez plus tard svp."
      );
      store.commit("setShowTryingToAcquirePosition", false);
    }
  });

  function moveendCallback() {
    //console.log("A moveend event occurred.");
    animateUserLocationOnMap();
    //console.log("setting map center after resizing map");
    map.resize();
    map.flyTo({
      center: store.state.mapCenter,
      zoom: map.getZoom() < 18 ? 18 : map.getZoom(),
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    });
    map.off("moveend", moveendCallback);
  }
  if (!coordinates) {
    //console.log("no coordinates");
    store.commit("setShowTryingToAcquirePosition", false);
    return;
  } else {
    store.commit("setCurrentPosition", coordinates);
    store.commit("setShowTryingToAcquirePosition", false);

    if (options.centerMap) {
      map.resize();
      store.commit("setMapCenter", [
        coordinates.coords.longitude,
        coordinates.coords.latitude,
      ]);
      setTimeout(() => {
        map.flyTo({
          center: store.state.mapCenter,
          zoom: map.getZoom() < 18 ? 18 : map.getZoom(),
          essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        });
      }, 20);
    }
    map.on("moveend", moveendCallback);
  }
};

const animateUserLocationOnMap = () => {
  if (!store.state.currentPosition) {
    return;
  }
  if (!map.hasImage("pulsing-dot")) {
    map.addImage("pulsing-dot", pulsingDot, { pixelRatio: 2 });
  }
  const mapDotPointSource = map.getSource("dot-point");
  if (mapDotPointSource) {
    mapDotPointSource.setData({
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              store.state.currentPosition.coords.longitude,
              store.state.currentPosition.coords.latitude,
            ], // icon position [lng, lat]
          },
        },
      ],
    });
    return;
  }

  map.addSource("dot-point", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              store.state.currentPosition.coords.longitude,
              store.state.currentPosition.coords.latitude,
            ], // icon position [lng, lat]
          },
        },
      ],
    },
  });
  map.addLayer({
    id: "layer-with-pulsing-dot",
    type: "symbol",
    source: "dot-point",
    layout: {
      "icon-image": "pulsing-dot",
      "icon-allow-overlap": true,
      'icon-rotate': store.state.compassDir
    },
  });
};

var showProjectsPolygonsOnMap = false;

var projectsModalInstance;

var ilotsDeChaleurStyle = {
  version: 8,
  name: "Dark",
  sources: {
    mapbox: {
      type: "vector",
      url: "mapbox://mapbox.mapbox-streets-v8",
    },
    overlay: {
      type: "image",
      url: "https://firebasestorage.googleapis.com/v0/b/soverdiwebsite.appspot.com/o/accueil%2Ffeatured%2FilotsChaleurMontreal.png?alt=media&token=b4f79c86-023b-4ac6-a144-bbe959db9237",
      coordinates: [
        [-74.053, 45.7259],
        [-73.4373, 45.7579],
        [-73.399, 45.3658],
        [-74.011, 45.3345],
      ],
    },
  },
  sprite: "mapbox://sprites/mapbox/dark-v10",
  glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
  layers: [
    {
      id: "background",
      type: "background",
      paint: {
        "background-color": "#111",
      },
    },
    {
      id: "water",
      source: "mapbox",
      "source-layer": "water",
      type: "fill",
      paint: {
        "fill-color": "#2c2c2c",
      },
    },
    {
      id: "boundaries",
      source: "mapbox",
      "source-layer": "admin",
      type: "line",
      paint: {
        "line-color": "#797979",
        "line-dasharray": [2, 2, 6, 2],
      },
      filter: ["all", ["==", "maritime", 0]],
    },
    {
      id: "overlay",
      source: "overlay",
      type: "raster",
      paint: {
        "raster-opacity": 0.8,
      },
    },
    {
      id: "cities",
      source: "mapbox",
      "source-layer": "place_label",
      type: "symbol",
      layout: {
        "text-field": "{name_en}",
        "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
        "text-size": ["interpolate", ["linear"], ["zoom"], 4, 9, 6, 12],
      },
      paint: {
        "text-color": "#969696",
        "text-halo-width": 2,
        "text-halo-color": "rgba(0, 0, 0, 0.85)",
      },
    },
    {
      id: "states",
      source: "mapbox",
      "source-layer": "place_label",
      type: "symbol",
      layout: {
        "text-transform": "uppercase",
        "text-field": "{name_en}",
        "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
        "text-letter-spacing": 0.15,
        "text-max-width": 7,
        "text-size": ["interpolate", ["linear"], ["zoom"], 4, 10, 6, 14],
      },
      filter: ["==", ["get", "class"], "state"],
      paint: {
        "text-color": "#969696",
        "text-halo-width": 2,
        "text-halo-color": "rgba(0, 0, 0, 0.85)",
      },
    },
  ],
};

var speciesPopup = new mapboxgl.Popup({
  closeButton: false,
  closeOnClick: false,
  offset: [0, -20],
});

var soverdiProjectsPopup = new mapboxgl.Popup({
  closeButton: false,
  closeOnClick: false,
  offset: 24,
});

var draggingPopup = new mapboxgl.Popup({
  closeButton: false,
  closeOnClick: false,
});

const popup = {};

/* const addUserLocationControl = ()=>{
  class GeolocateControlWrapper extends mapboxgl.GeolocateControl { 
    _onSuccess (position) {
    
      console.log("success");

      this.fire('geolocate', position)
      this._finish()
    }

    _setupUI (supported) {
      super._setupUI(supported)
      if (supported === false) return
      this._geolocateButton.className += ' needsclick'
    }

    _onClickGeolocate () {
      super._onClickGeolocate()
      console.log("click");
      // toggle watching the device location
      if (this.options.watchPosition) {
        if (this._geolocationWatchID !== undefined) { // clear watchPosition
          console.log('looks good in if....')
        }
        else {
          // enable watchPosition
          console.log('looks good in else....')
        }
      }
    }
  }
  const geolocate = new mapboxgl.GeolocateControl({
    positionOptions: {
        enableHighAccuracy: true
    },
    // When active the map will receive updates to the device's location as it changes.
    trackUserLocation: true,
    // Draw an arrow next to the location dot to indicate which direction the device is heading.
    showUserHeading: true,
   // geolocation: Geolocation,
   fitBoundsOptions: {maxZoom:19},
   //watchPosition: true
});
  map.addControl(
    geolocate
);
geolocate.trigger();
}; */

const selectSpecimenOnMap = async (specimen, doNotShowDetails) => {
  //console.log(specimen.properties);
  //console.log(specimen.features);
  let specimenToSelectOnMap;
  var markerProperties;
  var coordinates;
  var essenceName;
  var fetchedTree;
  // check if specimen is provided by a map event
  let fetchedTreeInfos = null;
  if (!specimen.features) {
    return
  }
  if (specimen.features) {
    if (!specimen.features[0].properties.owner) {
      // this comes from a simplified data set, fetch full specimen before proceeding
      const tempQueryOptions = {
        collectionName: "specimens",
        limit: 0,
        radius: 500,
        filters: [
          {
            displayName: "",
            displayValue: "",
            enteredValue: specimen.features[0].properties._id ? specimen.features[0].properties._id : "",
            fieldName: "geojsonFeature.properties._id",
            operator: "==",
            type: "string",
            coordsForRadius: specimen.features[0].geometry.coordinates
          },
        ],
      };
      fetchedTree = await firebaseService().getCollectionData(tempQueryOptions);
      console.log("fetchedTree");
      //store.state.chosenSpecimenMarkerFirestoreId = fetchedTree[0].firestoreId;
      fetchedTree.forEach((tree) => {
        if (!tree.geojsonFeature) {
          return
        }
        tree.geojsonFeature.properties.firestoreId = tree.firestoreId;
        store.state.fetchedSpecimensObj[
          tree.firestoreId
        ] = tree;
        fetchedTreeInfos = tree;
      })
    }
    specimenToSelectOnMap = fetchedTreeInfos ? fetchedTreeInfos.geojsonFeature : specimen.features[0];
    coordinates = fetchedTreeInfos ? fetchedTreeInfos.geojsonFeature.geometry.coordinates : specimen.features[0].geometry.coordinates.slice();
    if (specimen.lngLat) {
      while (Math.abs(specimen.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += specimen.lngLat.lng > coordinates[0] ? 360 : -360;
      }
    }
    markerProperties = specimenToSelectOnMap.properties;
    // essenceName = JSON.parse(markerProperties.essenceName);
  } else {
    coordinates = specimen.geometry.coordinates.slice();
    markerProperties = specimen.properties;
    //  essenceName = markerProperties.essenceName
  }

  // Ensure that if the map is zoomed out such that multiple
  // copies of the feature are visible, the popup appears
  // over the copy being pointed to.

  /*  var imgPath = '../assets/arbres/' + markerProperties.essence.slice(0, 2) + '_feuille.png';
 
   const owner = markerProperties.owner ? markerProperties.owner : '';
 
   var description = "<ion-item class='marker-popup' style='--background:white !important;--border-color:white !important;--border-width:3px !important;--border-radius:10px !important;--inner-border-width:0px !important;border-top:none'> <ion-thumbnail slot='start' style='margin-left:-8px'><img src=" + imgPath + "></img></ion-thumbnail>" + "<ion-label class='ion-text-wrap' style='color:black !important;margin:0px'><b>" + essenceName.la + "</b><h4>" + owner + "</h4>" + "<h4>" + markerProperties.plant_date.slice(0, 10) + "</h4>" + "</ion-label></ion-item>"; */

  function iwClick() {
    store.state.fallbackIndex = 0;
    store.commit(
      "setChosenSpecimenMarkerFirestoreId",
      markerProperties.firestoreId
    );
    store.commit(
      "setFetchedSpecimen",
      store.state.fetchedSpecimensObj[
      store.state.chosenSpecimenMarkerFirestoreId
      ]
    );
    /*     if (
      store.state.userGroupMembership == "soverdi" &&
      store.state.fetchedSpecimen.geojsonFeature.properties.groupSpecific
        .soverdi
    ) {
      // check if specimen is from an active plantation
      firebaseService().getFirebaseDbData(
        "activePlantations/" +
          store.state.fetchedSpecimen.geojsonFeature.properties.groupSpecific
            .soverdi.plantation.project +
          "/" +
          store.state.fetchedSpecimen.geojsonFeature.properties.groupSpecific
            .soverdi.plantation.plantationName,
        "setSpecimenIsFromAnActivePlantation"
      );
    } */
    store.state.cappedBreakpoints = [
      0.001, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7,
      0.75, 0.8, 0.85, 0.9, 0.95,
    ];
    store
      .dispatch(
        "formatFetchedSpecimen",
        store.state.fetchedSpecimensObj[
          store.state.chosenSpecimenMarkerFirestoreId
        ].geojsonFeature.properties
      )
      .then(function () {
        //console.log("formattedSpecimen ready");
        console.log(store.state.formattedSpecimen);
        if (!doNotShowDetails) {
          // disable nearest specimen
          store.commit("setShowNearestSpecimenModal", false);
          store.commit("setCurrentSegment", "specimen");
          setTimeout(() => {
            store.state.relativeToContent();
          }, 75);
        } else {
          store.commit("setCurrentSegment", "specimen");

          const modalRef = document.querySelector(
            ".modal-content-specimen-details"
          );
          modalRef.setCurrentBreakpoint(0.001);
        }
      });
  }
  iwClick();

  if (map.getSource("point2")) {
    map.removeLayer("point2");
    map.removeSource("point2");
  }

  var pointToHighlightChosenMarker = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: coordinates,
        },
        properties: {
          firestoreId: "000",
        },
      },
    ],
  };

  // Add a single point to the map
  map.addSource("point2", {
    type: "geojson",
    data: pointToHighlightChosenMarker,
  });

  map.addLayer({
    id: "point2",
    type: "circle",
    source: "point2",
    paint: {
      "circle-emissive-strength": 1,
      "circle-radius": {
        base: 3.5,
        stops: [
          [12, 15],
          [16, 25],
          [22, 40],
        ],
      },
      "circle-color": "#3887be",
      "circle-opacity": 0,
      "circle-stroke-width": 2,
      "circle-stroke-color": "red",
    },
  });
};

const draggingSpecimen = (specimen) => {
  //console.log("draggingSpecimen");
  store.state.draggingWarning = null;
  let endingCoords;
  let startingCoords;
  function confirmDragging() {
    const specimenFeature = specimen.geojsonFeature;
    const owning_group = specimenFeature.properties.owning_group;
    if (owning_group != store.state.userGroupMembership) {
      // no ownership, operation denied
      exitFromDraggingMode();
      store.state.draggingModeActivated = false;
      alert("Vous n'avez pas la permission de déplacer ce spécimen.");
    } else {
      // specimen belongs to the user group. Full write will be allowed

      var newLocation = new GeoPoint(endingCoords.lat, endingCoords.lng);

      var geoHash = encodeGeohash.encodeGeohash({
        lat: endingCoords.lat,
        lng: endingCoords.lng,
      });

      // Get a new write batch
      const firestoreDb = getFirestore();
      let collectionPath =
        "groups/" + store.state.userGroupMembership + "/specimens";
      const specimenRef = doc(
        firestoreDb,
        collectionPath,
        specimenFeature.properties.firestoreId
      );
      let batch = writeBatch(firestoreDb);
      // Get a new write batch
      batch.update(specimenRef, {
        coordinates: newLocation,
      });
      batch.update(specimenRef, {
        g: { geohash: geoHash, geopoint: newLocation },
      });
      batch.update(specimenRef, {
        "geojsonFeature.geometry.coordinates": [
          endingCoords.lng,
          endingCoords.lat,
        ],
      });

      // add a property to tag this specimen as dragged
      let draggingInfos = {
        fromAdmin: false,
        fromMobile: true,
        date: new Date(),
        by: store.state.user.email,
        previousCoords: specimen.geojsonFeature.geometry.coordinates,
      };
      var keyAsString2 =
        "geojsonFeature.properties.groupSpecific." +
        store.state.userGroupMembership +
        ".positionSetByDragging";
      batch.update(specimenRef, {
        [keyAsString2]: draggingInfos,
      });

      // push into dragging history
      var keyAsString3 =
        "geojsonFeature.properties.groupSpecific." +
        store.state.userGroupMembership +
        ".positionSetByDraggingHistory";
      if (
        !specimenFeature.properties.groupSpecific[
          store.state.userGroupMembership
        ].positionSetByDraggingHistory
      ) {
        batch.update(specimenRef, {
          [keyAsString3]: [draggingInfos],
        });
      } else {
        specimenFeature.properties.groupSpecific[
          store.state.userGroupMembership
        ].positionSetByDraggingHistory.push(draggingInfos);
        batch.update(specimenRef, {
          [keyAsString3]:
            specimenFeature.properties.groupSpecific[
              store.state.userGroupMembership
            ].positionSetByDraggingHistory,
        });
      }

      // Commit the batch
      batch.commit().then(function () {
        //console.log("batch successfully committed!");
        store.state.draggingModeActivated = false;
        for (
          let index = 0;
          index <
          store.state.fetchedSpecimensAsFeatureCollection.features.length;
          index++
        ) {
          let element =
            store.state.fetchedSpecimensAsFeatureCollection.features[index];
          if (
            element.properties.firestoreId ==
            specimenFeature.properties.firestoreId
          ) {
            //console.log("match found");
            element.geometry.coordinates = [endingCoords.lng, endingCoords.lat];
            /* store.state.fetchedSpecimensAsFeatureCollection.features.splice(index, 1);
            store.state.fetchedSpecimensAsFeatureCollection.features.push(specimenFeature); */
            break;
          }
        }
        store.state.mapboxMap
          .getSource("fetchedSpecimens")
          .setData(store.state.fetchedSpecimensAsFeatureCollection);
        const pointToHighlightChosenMarker = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [endingCoords.lng, endingCoords.lat],
              },
              properties: {
                firestoreId: "000",
              },
            },
          ],
        };
        if (store.state.mapboxMap.getSource("point2"))
          store.state.mapboxMap
            .getSource("point2")
            .setData(pointToHighlightChosenMarker);
        exitFromDraggingMode();
      });
    }
  }
  function cancelDragging() {
    // move back to starting coords
    var pointToDragStartingCoords = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: specimen.geojsonFeature.geometry.coordinates,
          },
          properties: {
            firestoreId: "000",
          },
        },
      ],
    };
    map.getSource("pointToDrag").setData(pointToDragStartingCoords);
    draggingPopup.remove();
    map.off("mousemove", onMove);
    map.off("touchmove", onMove);
    map.off("touchend", onUp);
    map.off("mouseup", onUp);
  }
  function exitFromDraggingMode() {
    //console.log("exit from dragging mode");
    map.off("mousemove", onMove);
    map.off("touchmove", onMove);
    map.off("touchend", onUp);
    map.off("mouseup", onUp);

    if (map.getSource("pointToDrag")) {
      map.removeLayer("pointToDrag");
      map.removeSource("pointToDrag");
    }
    draggingPopup.remove();
    store.state.draggingWarning = null;
    // store.commit("setDraggingModeActivated", false);
  }
  function onMove(e) {
    //console.log("onMove");

    const coords = e.lngLat;
    draggingPopup.remove();
    // Set a UI indicator for dragging.
    // canvas.style.cursor = 'grabbing';

    // Update the Point feature in `geojson` coordinates
    // and call setData to the source layer `point` on it.
    var pointToDragTemp = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [coords.lng, coords.lat],
          },
          properties: {
            firestoreId: "000",
          },
        },
      ],
    };
    // specimen.geometry.coordinates = [coords.lng, coords.lat];
    map.getSource("pointToDrag").setData(pointToDragTemp);
  }
  const onUp = (e) => {
    //console.log("onUp");

    endingCoords = e.lngLat;
    //console.log("ending coords");
    //console.log(endingCoords);
    // make sure new coords are less than 20 meters away from starting coords
    var distanceFromStartingCoords = calculateDistanceBetweenTwoPoints(
      specimen.geojsonFeature.geometry.coordinates,
      [endingCoords.lng, endingCoords.lat]
    );
    //console.log(distanceFromStartingCoords);
    var distanceFromStartingCoordsInMeters = (
      distanceFromStartingCoords * 1000
    ).toFixed(0);
    if (distanceFromStartingCoordsInMeters > 50) {
      exitFromDraggingMode();
      store.state.draggingModeActivated = false;
      store.state.draggingWarning =
        "Vous ne pouvez pas déplacer un spécimen à plus de 50 mètres de son point d'origine.";
    } else {
      const popupContent =
        "<div class='soverdi-projects-marker-popup' style='padding:12px;background-color:white !important;border-color:white !important;border-width:3px !important;border-radius:10px !important;inner-border-width:0px !important;border-top:none'>" +
        "<ion-label position='stacked' class='ion-text-wrap' style='color:black !important;margin:0px'><b>" +
        "Souhaitez-vous déplacer le marqueur à cet endroit?" +
        "</b>" +
        "</ion-label><div style='margin-top:16px'> <ion-button fill='outline' color='danger' id='cancelDraggingBtn'>Non</ion-button><ion-button id='confirmDraggingBtn' style='float:right' fill='outline'>Oui</ion-button></div></div>";
      draggingPopup.setLngLat(endingCoords).setHTML(popupContent).addTo(map);
      const cancelBtn = document.getElementById("cancelDraggingBtn");
      cancelBtn.addEventListener("click", function () {
        //console.log("cancel dragging");
        exitFromDraggingMode();
        store.state.draggingModeActivated = false;
      });
      const confirmBtn = document.getElementById("confirmDraggingBtn");
      confirmBtn.addEventListener("click", function () {
        //console.log("confirm dragging");
        confirmDragging();
      });
    }

    // Update the Point feature in `geojson` coordinates
    // and call setData to the source layer `point` on it.
    /* store.state.fetchedSpecimensAsFeatureCollection.features.forEach((feature) => {
      if (feature) {
      //console.log(feature);
        //  feature.geometry.coordinates = [coords.lng, coords.lat];
      }
    }) */

    // Print the coordinates of where the point had
    // finished being dragged to on the map.
    /*  coordinates.style.display = 'block';
     coordinates.innerHTML = `Longitude: ${coords.lng}<br />Latitude: ${coords.lat}`;
     canvas.style.cursor = ''; */

    // Unbind mouse/touch events
    map.off("mousemove", onMove);
    map.off("touchmove", onMove);
    map.off("touchend", onUp);
    map.off("mouseup", onUp);
  };
  if (store.state.draggingModeActivated) {
    exitFromDraggingMode();
  } else {
    speciesPopup.remove();
    // add new layer for dragging
    var pointToDrag = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: specimen.geojsonFeature.geometry.coordinates,
          },
          properties: {
            firestoreId: "000",
          },
        },
      ],
    };

    // Add a single point to the map
    map.addSource("pointToDrag", {
      type: "geojson",
      data: pointToDrag,
    });

    map.addLayer({
      id: "pointToDrag",
      type: "circle",
      source: "pointToDrag",
      paint: {
        "circle-emissive-strength": 1,
        "circle-radius": {
          base: 1.75,
          stops: [
            [12, 10],
            [22, 30],
          ],
        },
        "circle-color": "#3887be",
        "circle-opacity": 0,
        "circle-stroke-width": 10,
        "circle-stroke-color": "orange",
      },
    });
    // register events for dragging using selected specimen layerId "point2"
    //console.log(specimen);

    let lastClick = 0;

    map.once("mousedown", "pointToDrag", (e) => {
      //console.log("mousedown");

      // preveent event being fired twice
      if (lastClick >= Date.now()) return;
      lastClick = Date.now();
      // Prevent the default map drag behavior.
      e.preventDefault();
      startingCoords = e.lngLat;
      //console.log("starting coords");
      //console.log(startingCoords);

      //canvas.style.cursor = 'grab';

      map.on("mousemove", onMove);
      map.on("mouseup", onUp);
    });

    map.on("touchstart", "pointToDrag", (e) => {
      //console.log("touchstart");
      if (e.points.length !== 1) return;

      // Prevent the default map drag behavior.
      e.preventDefault();

      const coords = e.lngLat;
      //console.log("starting coords");
      //console.log(coords);

      map.on("touchmove", onMove);
      map.on("touchend", onUp);
    });
  }
};

// mapboxgl events
const addMapEvents = () => {
  map.on('dragstart', () => {
    console.log('A dragstart event occurred.');
  });
  map.on("moveend", function (e) {
    let systemDark = window.matchMedia("(prefers-color-scheme: dark)");
    console.log("map moved");
    if (!store.state.showNearestSpecimenModal) {
      store.state.drawRadiusCircleOnMap();
      setTimeout(() => {
        const radiusCircleLayer = store.state.mapboxMap.getLayer("radiusCircleLayer");
        if (typeof radiusCircleLayer !== "undefined") {
          map.setPaintProperty('radiusCircleLayer', 'circle-stroke-opacity-transition', {
            duration: 250
          });
          map.setPaintProperty('radiusCircleLayer', 'circle-stroke-opacity', 0);
          setTimeout(() => {
            const radiusCircleLayer = store.state.mapboxMap.getLayer("radiusCircleLayer");
            if (typeof radiusCircleLayer !== "undefined") {
              store.state.mapboxMap.removeLayer("radiusCircleLayer");
            }
            const radiusCircleSourc = store.state.mapboxMap.getSource("radiusCircleSource");
            if (typeof radiusCircleSourc !== "undefined") {
              store.state.mapboxMap.removeSource("radiusCircleSource");
            }
          }, 250);
        }
      }, 1000)
    }
  });

  map.on("mouseenter", store.state.featuredProjectsList.layersWithMarkersForClickEvent, function (e) {
    // Change the cursor style as a UI indicator.
    map.getCanvas().style.cursor = "pointer";
  });
  map.on("mouseleave", store.state.featuredProjectsList.layersWithMarkersForClickEvent, function () {
    map.getCanvas().style.cursor = "";
    //  speciesPopup.remove();
  });
  map.on("click", store.state.featuredProjectsList.layersWithMarkersForClickEvent, function (e) {
    selectSpecimenOnMap(e);
  })

  map.on("click", "habitationsLayer", function (e) {
    //console.log(e.features[0]);
    const markerPlaceProperty = JSON.parse(e.features[0].properties.place);
    var coordinates = e.features[0].geometry.coordinates;
    var description =
      "<ion-item class='soverdi-projects-marker-popup' style='--background:white !important;--border-color:white !important;--border-width:3px !important;--border-radius:10px !important;--inner-border-width:0px !important;border-top:none'>" +
      "<ion-label class='ion-text-wrap' style='color:black !important;margin:0px'><b>" +
      markerPlaceProperty.habitationName +
      "</b><h4>" +
      markerPlaceProperty.habitationNumber +
      "</h4>" +
      "<h4>" +
      markerPlaceProperty.address +
      "</h4>" +
      "</ion-label></ion-item>";
    //  $scope.editProject('md', snapshot.val());

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.

    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    const popup = new mapboxgl.Popup()
      .setLngLat(coordinates)
      .setHTML(description);

    popup.addTo(map);
  });


  /* map.on("click", "soverdiProjectsMarkersLayer", function (e) {
    //console.log(e.features[0]);
    var coordinates = e.features[0].geometry.coordinates.slice();
 
    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }
    var markerProperties = e.features[0].properties;
    var projectName = markerProperties.displayName;
    var description =
      "<ion-item class='soverdi-projects-marker-popup' style='--background:white !important;--border-color:white !important;--border-width:3px !important;--border-radius:10px !important;--inner-border-width:0px !important;border-top:none'>" +
      "<ion-label class='ion-text-wrap' style='color:black !important;margin:0px'><b>" +
      projectName +
      "</b><h4>" +
      markerProperties.reseau +
      "</h4>" +
      "<h4>" +
      markerProperties.address +
      "</h4>" +
      "</ion-label></ion-item>";
 
    function soverdiProject_iwClick() {
      store.commit("setSideMenuDisabled", false);
      store.commit("setShowSideMenuForProject", true);
      //console.log(store.state.projectsList);
      store.commit(
        "setCurrentProjectID",
        store.state.projectsList[markerProperties.name]
      );
 
      // fetch full project infos
      const fetchedProject = firebaseService().getFirebaseDbData(
        "projects/" + markerProperties.name,
        "setFetchedProject"
      );
 
      // add to map
      fetchedProject.then((res) => {
        //console.log("project fetched");
        loadProjectOnMap(store.state.fetchedProject);
      });
    }
 
    // Populate the popup and set its coordinates
    // based on the feature found.
    soverdiProjectsPopup.setLngLat(coordinates).setHTML(description).addTo(map);
 
    const btn = document.querySelector(".soverdi-projects-marker-popup");
    btn.addEventListener("click", soverdiProject_iwClick);
  }); */

  document.addEventListener(
    "touchmove",
    function () {
      //
    },
    { passive: false, capture: true }
  );

  // inspect a cluster on click
  map.on("click", "clusters", function (e) {
    var features = map.queryRenderedFeatures(e.point, {
      layers: ["clusters"],
    });
    var clusterId = features[0].properties.cluster_id;
    map
      .getSource("fetchedSpecimens")
      .getClusterExpansionZoom(clusterId, function (err, zoom) {
        if (err) return;

        map.easeTo({
          center: features[0].geometry.coordinates,
          zoom: zoom,
        });
      });
  });

  map.on("mouseenter", "clusters", function () {
    map.getCanvas().style.cursor = "pointer";
  });
  map.on("mouseleave", "clusters", function () {
    map.getCanvas().style.cursor = "";
  });
};

var draw = new MapboxDraw({
  displayControlsDefault: true,
  /* controls: {
    polygon: true,
    trash: true
  } */
});

var currentProjectLayerID = null;
var currentArrondissementLayerID = null;

function clearPolygonFromMap() {
  if (currentArrondissementLayerID) {
    var currentArrondissementLayer = map.getLayer(currentArrondissementLayerID);
    if (typeof currentArrondissementLayer !== "undefined") {
      map.removeLayer(currentArrondissementLayerID);
      map.removeSource(currentArrondissementLayerID);
      currentArrondissementLayerID = null;
    }
    requestParameters.setPolygon(null);
    // remove dashed envelope
    var mapLayer = map.getLayer("extended");
    if (typeof mapLayer !== "undefined") {
      // Remove map layer & source.
      map.removeLayer("extended").removeSource("extended");
    }
  }
  if (map.getLayer("projectPolygon")) {
    map.removeLayer("projectPolygon").removeSource("projectPolygon");
  }
  if (projectLayersIDs.length) {
    projectLayersIDs.forEach(function (layerID) {
      if (map.getLayer(layerID)) {
        map.removeLayer(layerID);
      }
      if (map.getSource(layerID)) {
        map.removeSource(layerID);
      }
    });
  }
  if (map.getLayer("extended")) {
    map.removeLayer("extended").removeSource("extended");
  }
}

function loadCityPolygonOnMap(feature) {
  clearPolygonFromMap();
  currentArrondissementLayerID = feature.properties.NOM;
  map.addLayer({
    id: currentArrondissementLayerID,
    type: "line",
    source: {
      type: "geojson",
      data: feature,
    },
    layout: {},
    paint: {
      "line-color": "red",
      "line-opacity": 1,
      //   "line-dasharray": [10, 10]
    },
  });

  requestParameters.setPolygon({
    features: [feature],
    type: "FeatureCollection",
  });

  var bounds = bbox(feature);
  map.fitBounds(bounds, {
    padding: 200,
  });
}

function loadIlotsDeChaleurOnMap(remove) {
  //console.log("loadIlotsDeChaleurOnMap");

  var currentIlotsLayer = map.getLayer("ilotsDeChaleur");
  if (typeof currentIlotsLayer !== "undefined") {
    map.removeLayer("ilotsDeChaleur");
    map.removeSource("ilotsDeChaleur");
  } else {
    var fetchedSpecimensLayerSet = map.getLayer("fetchedSpecimens");
    if (typeof fetchedSpecimensLayerSet !== "undefined") {
      map.addLayer(
        {
          id: "ilotsDeChaleur",
          type: "raster",
          source: {
            type: "image",
            url: "https://firebasestorage.googleapis.com/v0/b/soverdiwebsite.appspot.com/o/accueil%2Ffeatured%2FilotsChaleurMontreal.png?alt=media&token=b4f79c86-023b-4ac6-a144-bbe959db9237",
            coordinates: [
              [-74.053, 45.7259],
              [-73.4373, 45.7579],
              [-73.399, 45.3658],
              [-74.011, 45.3345],
            ],
          },
          layout: {},
          paint: {
            "raster-opacity": 0.8,
          },
        },
        "fetchedSpecimens"
      );
    } else {
      map.addLayer({
        id: "ilotsDeChaleur",
        type: "raster",
        source: {
          type: "image",
          url: "https://firebasestorage.googleapis.com/v0/b/soverdiwebsite.appspot.com/o/accueil%2Ffeatured%2FilotsChaleurMontreal.png?alt=media&token=b4f79c86-023b-4ac6-a144-bbe959db9237",
          coordinates: [
            [-74.053, 45.7259],
            [-73.4373, 45.7579],
            [-73.399, 45.3658],
            [-74.011, 45.3345],
          ],
        },
        layout: {},
        paint: {
          "raster-opacity": 0.8,
        },
      });
    }
  }
}

function deselectMarker() {
  if (map.getSource("point2")) {
    map.removeLayer("point2");
    map.removeSource("point2");
  }
  // close popups
  speciesPopup.remove();
  soverdiProjectsPopup.remove();
}

var projectLayersIDs = [];

function loadProjectsMarkersOnMap(featureCollection) {
  // add markers
  if (map.getSource("soverdiProjectsMarkers")) {
    map
      .removeLayer("soverdiProjectsMarkersLayer")
      .removeSource("soverdiProjectsMarkers");
  }
  map.loadImage(
    "../assets/marker-icons/mapbox-marker-icon-20px-red.png",
    (error, image) => {
      if (error) throw error;
      if (!map.hasImage("soverdiProjectIcon"))
        map.addImage("soverdiProjectIcon", image);
    }
  );
  map.addSource("soverdiProjectsMarkers", {
    type: "geojson",
    data: featureCollection,
    /*  'data': {
       'type': 'FeatureCollection',
       'features': [
         {
           'type': 'Feature',
           'geometry': {
             'type': 'Point',
             'coordinates': [store.state.currentPosition.coords.longitude, store.state.currentPosition.coords.latitude] // icon position [lng, lat]
           }
         }
       ]
     } */
  });
  map.addLayer({
    id: "soverdiProjectsMarkersLayer",
    type: "symbol",
    source: "soverdiProjectsMarkers",
    layout: {
      "icon-image": "soverdiProjectIcon",
      "icon-allow-overlap": true,
    },
  });
  /* var bounds = bbox(featureCollection);
  map.fitBounds(bounds, {
    padding: 100,
  }); */
  //console.log("projectsMarkers loaded");
}

function loadProjectOnMap(chosenProject, noOffset) {
  //console.log("chosenProject");
  //console.log(chosenProject);
  //console.log(noOffset);
  if (!chosenProject) {
    //console.log("no project");
    return;
  }
  deselectMarker();
  //  currentProjectLayerID = JSON.parse(JSON.stringify(chosenProject.displayName));

  if (chosenProject.polygon && chosenProject.polygon.features) {
    //console.log(noOffset);

    // check if theres already a projectPolygon on map
    var mapProjectPolygonLayer = map.getLayer("projectPolygon");
    if (typeof mapProjectPolygonLayer !== "undefined") {
      // Remove map layer & source.
      map.removeLayer("projectPolygon").removeSource("projectPolygon");
    }
    map.addLayer({
      id: "projectPolygon",
      type: "line",
      source: {
        type: "geojson",
        data: chosenProject.polygon.features[0],
      },
      layout: {},
      paint: {
        "line-emissive-strength": 1,
        "line-color": "#00967d",
        "line-opacity": 1,
        "line-dasharray": [12, 4],
        "line-width": 4,
      },
    });
    var bounds = bbox(chosenProject.polygon);
    map.fitBounds(bounds, {
      padding: 100,
      offset: noOffset ? [0, 0] : [window.innerWidth / 7.5, 0],
    });
    //console.log("load project on map done");
  } else {
    //console.log("some property is missing");
  }
  /* else {
    // fetch project
    chosenProject = chosenProject.name ? chosenProject.name : chosenProject;
    const firebasedb = getDatabase();
    const chosenProjectRef = rtdbQuery(
      ref(firebasedb, "projects/" + chosenProject)
    );
    onValue(chosenProjectRef, function (snapshot) {
    //console.log(snapshot.val());
      if (
        !snapshot.val() ||
        !(
          snapshot.val() &&
          snapshot.val().polygon &&
          snapshot.val().polygon.features
        )
      ) {
        return;
      }
      loadProjectOnMap(snapshot.val(), noOffset);
    });
  } */

  //  return map;
}

function cleanUpMap() {
  // clean up previous sources and layers
  if (map.getLayer("clusters")) {
    map.removeLayer("clusters").removeLayer("cluster-count");
  }
  if (map.getLayer("fetchedSpecimensSoverdiIconLayer")) {
    map.removeLayer("fetchedSpecimensSoverdiIconLayer");
  }
  if (map.getLayer("fetchedSpecimensTextLayer")) {
    map.removeLayer("fetchedSpecimensTextLayer");
  }
  if (
    map.getSource("fetchedSpecimens") &&
    map.getLayer("fetchedSpecimensLayer")
  ) {
    map.removeLayer("fetchedSpecimensLayer").removeSource("fetchedSpecimens");
  }

  if (map.getLayer("layer-for-like-tags")) {
    map.removeLayer("layer-for-like-tags");
  }
  if (map.getSource("like-tags")) {
    map.removeSource("like-tags");
  }
  if (map.getLayer("layer-for-reports")) {
    map.removeLayer("layer-for-reports");
  }
  if (map.getSource("reports")) {
    map.removeSource("reports");
  }
  if (map.getLayer("layer-for-observations")) {
    map.removeLayer("layer-for-observations");
  }
  if (map.getSource("observations")) {
    map.removeSource("observations");
  }

  deselectMarker();
  store.state.draggingModeActivated = false;
  if (map.getSource("pointToDrag")) {
    map.removeLayer("pointToDrag");
    map.removeSource("pointToDrag");
  }
  draggingPopup.remove();
  store.commit("setChosenSpecimenMarkerFirestoreId", null);
  store.commit("setCurrentSegment", null);
  store.commit("setFormattedSpecimen", null);
}

function setEssenceNameFromDict(specimen) {
  if (!store.state.allEssencesDictionary[specimen.properties.essence]) {
    // no match found, check if specimen was identified using plantNet
    if (specimen.properties.plantNetIdentification) {
      // use plantNet's name
      return {
        fr: {
          name: (specimen.properties.plantNet.commonNames && specimen.properties.plantNet.commonNames.length > 0) ? specimen.properties.plantNet.commonNames.join() : specimen.properties.plantNet.scientificNameWithoutAuthor,
        },
        la: specimen.properties.plantNet.scientificNameWithoutAuthor,
      };
    }
  } else {
    var reducedEssenceCode = specimen.properties.essence;
    while (reducedEssenceCode.length) {
      var entry = store.state.allEssencesDictionary[reducedEssenceCode];
      if (entry) {
        return entry;
      } else {
        reducedEssenceCode = reducedEssenceCode.substr(
          0,
          reducedEssenceCode.length - 2
        );
      }
    }
    return {
      fr: {
        name: "inconnu",
      },
      la: "inconnu",
    };
  }
}

function putSpecimensOnMap(
  featureCollection,
  disableFitBounds,
  clusteringActivated,
  iconsOptions
) {
  clusteringActivated = false;
  console.log("trying to put fetchedSpecimens on mapbox map");
  console.log("disableFitBounds " + disableFitBounds);
  if (!iconsOptions) {
    iconsOptions = {
      trees: false,
    };
  }

  cleanUpMap();

  if (!featureCollection.features.length) {
    return;
  }
  if (
    !disableFitBounds &&
    !store.state.disableFitBounds &&
    map.getZoom() < 30 &&
    !store.state.showNearestSpecimenModal
  ) {
    //console.log(featureCollection);
    let featuresToIncludeInBoundingBox = {
      features: [],
      type: "FeatureCollection",
    };
    if (
      store.state.fetchedSpecimensProjectIDsListAsFeatureCollection &&
      store.state.fetchedSpecimensProjectIDsListAsFeatureCollection.features
        .length > 0
    ) {
      featuresToIncludeInBoundingBox.features =
        featureCollection.features.concat(
          store.state.fetchedSpecimensProjectIDsListAsFeatureCollection.features
        );
    } else {
      featuresToIncludeInBoundingBox.features = featureCollection.features;
    }
    let bounds;
    //console.log(store.state.sideMenuDisabled);
    if (
      store.state.sideMenuDisabled &&
      !store.state.showFetchedSpecimensStats
    ) {
      bounds = bbox(featuresToIncludeInBoundingBox);
      map.fitBounds(bounds, {
        padding: 45,
        offset: [0, 0],
      });
    } else if (!store.state.sideMenuDisabled) {
      bounds = bbox(store.state.fetchedProject.polygon);
      map.fitBounds(bounds, {
        padding: 25,
        offset: [90, 0],
      });
    } else {
      bounds = bbox(featuresToIncludeInBoundingBox);
      map.fitBounds(bounds, {
        padding: 25,
        offset: [0, 0],
      });
    }
  }

  if (clusteringActivated) {
    //console.log("adding new source to map");
    map.addSource("fetchedSpecimens", {
      type: "geojson",
      // Point to GeoJSON data.
      data: featureCollection,
      cluster: true,
      clusterMaxZoom: 14, // Max zoom to cluster points on
      clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
    });
  } else {
    map.addSource("fetchedSpecimens", {
      type: "geojson",
      // Point to GeoJSON data.
      data: featureCollection,
      cluster: false,
      //clusterMaxZoom: 14, // Max zoom to cluster points on
      // clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
    });
  }
  /*  else {
     map.getSource('fetchedSpecimens').setData(featureCollection);
   } */
  //console.log(map.getSource('fetchedSpecimens'));

  if (clusteringActivated) {
    if (!map.getLayer("clusters")) {
      map.addLayer({
        id: "clusters",
        type: "circle",
        source: "fetchedSpecimens",
        filter: ["has", "point_count"],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          "circle-emissive-strength": 1,
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#51bbd6",
            100,
            "#f1f075",
            750,
            "#f28cb1",
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      });
    }
    if (!map.getLayer("cluster-count")) {
      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "fetchedSpecimens",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
      });
    }
  } else {
    // clustering is deactivated
    if (map.getLayer("clusters")) {
      map.removeLayer("clusters");
    }
    if (map.getLayer("cluster-count")) {
      map.removeLayer("cluster-count");
    }
  }

  // add listener for missing treeIcons
  map.on("styleimagemissing", (e) => {

    console.log("loading missing image: " + e.id);
    if (e.id.length < 7) {
      map.loadImage("../assets/arbres/" + e.id + "@2x.png", (error, image) => {
        if (error) {
          //console.log(error);
        }
        if (!map.hasImage(e.id)) map.addImage(e.id, image);
        // map.getSource('markers').setData(url);
      });
    }
  });

  if (iconsOptions.trees || store.state.treeIconsOptions.trees) {
    // let speciesCodeForTreeIcons = specimensColor.getGenusColors();
    //   let speciesCodeForTreeIcons = ["AB", "AC", "AE", "AL", "AM", "ARBU", "BE", "CA", "CE", "CG", "CR","FR", "PI", "TI", "GL"];
    /* map.addLayer({
      id: "fetchedSpecimensSoverdiCircleLayer",
      type: "circle",
      source: "fetchedSpecimens",
      filter: ["has", "soverdiCircle"],
      paint: {
        'circle-color': 'yellow',
        'circle-radius': {
          'base': 2.75,
          'stops': [
            [12, 20],
            [22, 40]
          ]
        },
        "circle-stroke-width": 2,
        "circle-stroke-color": 'black',
        //    "text-color": "pink"
      }
    }); */
    if (!map.hasImage("soverdiIcon"))
      map.loadImage("../assets/arbres/soverdi_pill.png", (error, image) => {
        if (error) throw error;
        map.addImage("soverdiIcon", image);
      });
    // Add a symbol layer
    map.addLayer({
      id: "fetchedSpecimensSoverdiIconLayer",
      type: "symbol",
      source: "fetchedSpecimens",
      filter: ["has", "soverdiCircle"],
      layout: {
        "icon-image": "soverdiIcon",
        "icon-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          10,
          0.3,
          15,
          0.5,
          18,
          0.8,
          22,
          1,
        ],
        "icon-allow-overlap": true,
        "text-allow-overlap": true,
      },
    });

    // add tree icons resources to map if not there already

    // Add a symbol layer
    map.addLayer({
      id: "fetchedSpecimensLayer",
      type: "symbol",
      source: "fetchedSpecimens",
      layout: {
        "icon-image": ["get", "treeIcon"],
        "icon-size": [
          "interpolate",
          ["linear"],
          ["zoom"],
          10,
          0.3,
          15,
          0.5,
          18,
          0.8,
          22,
          1,
        ],
        "icon-allow-overlap": true,
        "text-allow-overlap": true,
      },
    });
  } else {
    map.addLayer({
      id: "fetchedSpecimensLayer",
      type: "circle",
      source: "fetchedSpecimens",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-emissive-strength": 1,
        "circle-color": ["get", "iconColor"],
        "circle-radius": {
          base: 1.75,
          stops: [
            [12, 10],
            [22, 30],
          ],
        },
        "circle-stroke-width": 2,
        "circle-stroke-color": ["get", "circleStrokeColor"],
        //    "text-color": "pink"
      },
    });
  }

  if (iconsOptions.showSpeciesCode) {
    map.addLayer({
      id: "fetchedSpecimensTextLayer",
      type: "symbol",
      source: "fetchedSpecimens",
      filter: ["!", ["has", "point_count"]],
      layout: {
        // get the title name from the source's "title" property
        //     'text-field': ['get', 'title'],
        "text-field": ["get", "essence"],
        "text-size": {
          base: 2,
          stops: [
            [12, 10],
            [22, 30],
          ],
        },
        "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
        "text-offset": [0, 1.5],
        "text-anchor": "top",
        "icon-allow-overlap": true,
        "text-allow-overlap": true,
      },
      paint: {
        "text-color": "white",
        "text-halo-width": 60,
        "text-halo-color": "black",
      },
    });
  }

  /*  if (!map.hasImage("pulsing-dot")) {
     map.addImage("pulsing-dot", pulsingDot, { pixelRatio: 2 });
   }
   if (map.getLayer("layer-with-pulsing-dot")) {
     map.removeLayer("layer-with-pulsing-dot");
   }
   if (map.getSource("dot-point")) {
     map.removeSource("dot-point");
   }
 
   map.addSource("dot-point", {
     type: "geojson",
     data: {
       type: "FeatureCollection",
       features: [
         {
           type: "Feature",
           geometry: {
             type: "Point",
             coordinates: [
               store.state.currentPosition.coords.longitude,
               store.state.currentPosition.coords.latitude,
             ], // icon position [lng, lat]
           },
         },
       ],
     },
   });
   map.addLayer({
     id: "layer-with-pulsing-dot",
     type: "symbol",
     source: "dot-point",
     layout: {
       "icon-image": "pulsing-dot",
       "icon-allow-overlap": true,
       "icon-rotate": store.state.compassDir
     },
   }); */
  addCollaborativeIcons();
  map.resize();
}

function addCollaborativeIcons(features) {
  console.log("add collaborative icons onto trees");
  const likeTagsInSelection = [];
  const reportsInSelection = [];
  const observationsInSelection = [];
  let items = null;
  if (features) {
    items = features;
  } else {
    items = store.state.collaborativeDataQueryObj;
  }
  items.forEach((collaborativeData) => {
    if (collaborativeData.has_likeTags) {
      let likeTag = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            collaborativeData.g.geopoint._long,
            collaborativeData.g.geopoint._lat,
          ], // icon position [lng, lat]
        },
      };
      likeTagsInSelection.push(likeTag);
    }
    if (collaborativeData.has_reports) {
      let report = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            collaborativeData.g.geopoint._long,
            collaborativeData.g.geopoint._lat,
          ], // icon position [lng, lat]
        },
      };
      reportsInSelection.push(report);
    }
    if (collaborativeData.has_likes) {
      let like = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [
            collaborativeData.g.geopoint._long,
            collaborativeData.g.geopoint._lat,
          ], // icon position [lng, lat]
        },
      };
      observationsInSelection.push(like);
    }
  });
  if (!map.hasImage("heart-icon"))
    map.loadImage("../assets/icon/heart-icon.png", (error, image) => {
      if (error) throw error;
      map.addImage("heart-icon", image);
    });
  if (map.getLayer("layer-for-like-tags")) {
    map.removeLayer("layer-for-like-tags");
  }
  if (map.getSource("like-tags")) {
    map.removeSource("like-tags");
  }
  map.addSource("like-tags", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: likeTagsInSelection,
    },
  });
  map.addLayer({
    id: "layer-for-like-tags",
    type: "symbol",
    source: "like-tags",
    layout: {
      "icon-image": "heart-icon",
      "icon-allow-overlap": true,
      "icon-size": [
        "interpolate",
        ["linear"],
        ["zoom"],
        10,
        0.3,
        15,
        0.5,
        18,
        0.8,
        22,
        1,
      ],
    },
  });

  if (!map.hasImage("reports-icon"))
    map.loadImage("../assets/icon/reports-icon.png", (error, image) => {
      if (error) throw error;
      map.addImage("reports-icon", image);
    });
  if (map.getLayer("layer-for-reports")) {
    map.removeLayer("layer-for-reports");
  }
  if (map.getSource("reports")) {
    map.removeSource("reports");
  }
  map.addSource("reports", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: reportsInSelection,
    },
  });
  map.addLayer({
    id: "layer-for-reports",
    type: "symbol",
    source: "reports",
    layout: {
      "icon-image": "reports-icon",
      "icon-allow-overlap": true,
      "icon-size": [
        "interpolate",
        ["linear"],
        ["zoom"],
        10,
        0.3,
        15,
        0.5,
        18,
        0.8,
        22,
        1,
      ],
    },
  });

  if (!map.hasImage("observations-icon"))
    map.loadImage("../assets/icon/observations-icon.png", (error, image) => {
      if (error) throw error;
      map.addImage("observations-icon", image);
    });
  if (map.getLayer("layer-for-observations")) {
    map.removeLayer("layer-for-observations");
  }
  if (map.getSource("observations")) {
    map.removeSource("observations");
  }
  map.addSource("observations", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features: observationsInSelection,
    },
  });
  map.addLayer({
    id: "layer-for-observations",
    type: "symbol",
    source: "observations",
    layout: {
      "icon-image": "observations-icon",
      "icon-allow-overlap": true,
      "icon-size": [
        "interpolate",
        ["linear"],
        ["zoom"],
        10,
        0.3,
        15,
        0.5,
        18,
        0.8,
        22,
        1,
      ],
    },
  });
}

function loadHabitationsOnMap(
  featureCollection,
  disableFitBounds,
  clusteringActivated
) {
  //console.log("trying to put habitations on mapbox map");

  if (disableFitBounds) {
    //console.log(featureCollection);
    var bounds = bbox(featureCollection);
    map.fitBounds(bounds, {
      padding: 250,
    });
  }

  if (map.getSource("habitations")) {
    map.removeLayer("habitationsLayer").removeSource("habitations");
  }

  map.addSource("habitations", {
    type: "geojson",
    // Point to GeoJSON data.
    data: featureCollection,
    cluster: false,
    //clusterMaxZoom: 14, // Max zoom to cluster points on
    // clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
  });

  /*  else {
 
     map.getSource('fetchedSpecimens').setData(featureCollection);
   } */

  //console.log(map.getSource('habitations'));
  if (!map.hasImage("soverdiProjectIcon"))
    map.loadImage(
      "../assets/marker-icons/mapbox-marker-icon-20px-red.png",
      (error, image) => {
        if (error) throw error;
        map.addImage("soverdiProjectIcon", image);
      }
    );

  if (!map.getLayer("habitationsLayer")) {
    map.addLayer({
      id: "habitationsLayer",
      type: "symbol",
      source: "habitations",
      layout: {
        "icon-image": "soverdiProjectIcon",
        "icon-allow-overlap": true,
      },
    });
  }
}

function fitBoundsToSelection(featureCollection) {
  const bounds = bbox(featureCollection);
  map.fitBounds(bounds, {
    padding: 100,
  });
}

function changeSpecimensColor(options) {
  //console.log(options);
  // //console.log(map.getStyle().layers);

  map.removeLayer("fetchedSpecimensLayer");
  var circeColorArray;
  if (options == "year") {
    circeColorArray = ["match", ["get", "yearPlanted"]];
    var year = 2014;
    specimensColor.getColorsForMapLayers().forEach(function (color) {
      // push in circeColorArray with year first (Mapbox GL JS expressions use a Lisp-like syntax, represented using JSON arrays)
      circeColorArray.push(year);
      circeColorArray.push(color);
      year++;
    });
    // add fallback color
    circeColorArray.push("#ccc");

    map.addLayer({
      id: "fetchedSpecimensLayer",
      type: "circle",
      source: "fetchedSpecimens",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-emissive-strength": 1,
        "circle-color": circeColorArray,
        "circle-radius": {
          base: 1.75,
          stops: [
            [12, 3],
            [22, 40],
          ],
        },
        "circle-stroke-width": 1,
        "circle-stroke-color": "black",
      },
    });
  } else if (options == "healthStatus") {
    circeColorArray = [
      "match",
      ["get", "healthStatusColor"],
      "Bon",
      "green",
      "Moyen",
      "yellow",
      "Faible",
      "orange",
      "Mort",
      "red",
      "Arbre introuvable",
      "grey",
    ];

    // add fallback color
    circeColorArray.push("#ccc");

    map.addLayer({
      id: "fetchedSpecimensLayer",
      type: "circle",
      source: "fetchedSpecimens",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-emissive-strength": 1,
        "circle-color": circeColorArray,
        "circle-radius": {
          base: 1.75,
          stops: [
            [12, 3],
            [22, 40],
          ],
        },
        "circle-stroke-width": 1,
        "circle-stroke-color": "black",
      },
    });
  } else {
    map.addLayer({
      id: "fetchedSpecimensLayer",
      type: "circle",
      source: "fetchedSpecimens",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-emissive-strength": 1,
        "circle-color": ["get", "iconColor"],
        "circle-radius": {
          base: 1.75,
          stops: [
            [12, 3],
            [22, 40],
          ],
        },
        "circle-stroke-width": 1,
        "circle-stroke-color": "black",
      },
    });
  }
}

const scale = new mapboxgl.ScaleControl({
  maxWidth: 80,
  unit: "metric",
});
function showScaleBar() {
  store.state.showScaleBar = true;
  //console.log("adding scale");
  map.addControl(scale, "bottom-left");

  //scale.setUnit('imperial');
}

function hideScaleBar() {
  store.state.showScaleBar = false;
  map.removeControl(scale);
  // }
  //scale.setUnit('imperial');
}

export default {
  animateUserLocationOnMap: function () {
    return animateUserLocationOnMap();
  },
  addCollaborativeIcons: function (features) {
    return addCollaborativeIcons(features);
  },
  showScaleBar: function () {
    return showScaleBar();
  },
  hideScaleBar: function () {
    return hideScaleBar();
  },
  getMap: function () {
    map = new mapboxgl.Map({
      container: "map",
      //style: "mapbox://styles/mapbox/light-v11",
      style: "mapbox://styles/tgl/clv5g3m8502k701pk7yyn2jd2",
      center: [-73.65, 45.55],
      zoom: 12,
      attributionControl: true,
      preserveDrawingBuffer: true,
      //   style: mapStyle
    });
    return map;
  },
  addMapEvents: function () {
    return addMapEvents();
  },
  fitBoundsToSelection: function (featureCollection) {
    return fitBoundsToSelection(featureCollection);
  },
  cleanUpMap: function () {
    return cleanUpMap();
  },
  deselectMarker: function () {
    return deselectMarker();
  },
  enableWatchPosition: function (centerMap) {
    return enableWatchPosition(centerMap);
  },
  getCurrentPosition: function (options) {
    return getCurrentPosition(options);
  },
  getDraw: function () {
    return draw;
  },
  setShowProjectsPolygonsOnMap: function (data) {
    showProjectsPolygonsOnMap = data;
  },

  getProjectLayersIDs: function () {
    return projectLayersIDs;
  },

  setProjectLayersIDs: function (data) {
    projectLayersIDs = data;
  },

  loadProjectOnMap: function (chosenProject, noOffset) {
    return loadProjectOnMap(chosenProject, noOffset);
  },
  loadProjectsMarkersOnMap: function (chosenProject) {
    return loadProjectsMarkersOnMap(chosenProject);
  },
  loadHabitationsOnMap: function (chosenProject) {
    return loadHabitationsOnMap(chosenProject);
  },

  loadCityPolygonOnMap: function (feature) {
    return loadCityPolygonOnMap(feature);
  },

  loadIlotsDeChaleurOnMap: function (remove) {
    return loadIlotsDeChaleurOnMap(remove);
  },

  clearPolygonFromMap: function () {
    return clearPolygonFromMap();
  },
  calculateDistanceBetweenTwoPoints: function (from, to) {
    return calculateDistanceBetweenTwoPoints(from, to);
  },
  putSpecimensOnMap: function (
    featureCollection,
    disableFitBounds,
    clusteringActivated,
    treeIcons
  ) {
    return putSpecimensOnMap(
      featureCollection,
      disableFitBounds,
      clusteringActivated,
      treeIcons
    );
  },
  changeSpecimensColor: function (options) {
    return changeSpecimensColor(options);
  },
  setEssenceNameFromDict: function (essenceCode) {
    return setEssenceNameFromDict(essenceCode);
  },
  setProjectsModalInstance: function (data) {
    projectsModalInstance = data;
  },
  clearGetCurrentPositionSetInterval: function () {
    return clearGetCurrentPositionSetInterval();
  },
  selectSpecimenOnMap: function (formattedSpecimen, doNotShowDetails) {
    return selectSpecimenOnMap(formattedSpecimen, doNotShowDetails);
  },
  setMapStyle: function (styleName) {
    function onStyleLoad() {
      store.state.mapboxMap.off('style.load', onStyleLoad);
      if (store.state.selectedFeaturedProject) {
        Object.values(store.state.featuredProjectsList[store.state.selectedFeaturedProject.name].mapLayers).forEach((layer) => {
          store.state.mapboxMap.setLayoutProperty(layer, 'visibility', 'visible');
        });
      }
      if (store.state.fetchedSpecimensAsFeatureCollection) {
        setTimeout(() => {
          putSpecimensOnMap(store.state.fetchedSpecimensAsFeatureCollection);
        }, 100);
      }
    }
    console.log(styleName);
    if (store.state.selectedFeaturedProject) {
      if (styleName.indexOf("light") > -1) {
        // light
        store.state.mapboxMap.setStyle(store.state.featuredProjectsList[store.state.selectedFeaturedProject.name].mapStyles.light);
      } else {
        store.state.mapboxMap.setStyle(store.state.featuredProjectsList[store.state.selectedFeaturedProject.name].mapStyles.dark);
      }
    } else {
      if (styleName.indexOf("light") > -1) {
        // light
        map.setStyle("mapbox://styles/tgl/clv5g3m8502k701pk7yyn2jd2");
      } else {
        map.setStyle("mapbox://styles/mapbox/" + styleName);
      }
    }
    if (store.state.mapboxMap)
      store.state.mapboxMap.on('style.load', onStyleLoad);
    //console.log(styleName);
    store.state.currentMapStyle = styleName;
    /* map.addLayer({
      'id': 'wms-test-layer',
      'type': 'raster',
      'source': {
      'type': 'raster',
      'tiles': [
      'https://img.nj.gov/imagerywms/Natural2015?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=Natural2015'
      ],
      'tileSize': 256
      },
      'paint': {}
      }); */
  },
  draggingSpecimen: function (feature) {
    return draggingSpecimen(feature);
  },
  /* addUserLocationControl: function (){
    return addUserLocationControl();
  } */
};
