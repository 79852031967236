/* eslint-disable no-prototype-builtins */

import store from "../store";

var colorsForMapLayers = [
  '#253494',
  '#2c7fb8',
  '#41b6c4',
  '#a1dab4',
  '#ffffcc',
  '#fed976',
  '#feb24c',
  '#fd8d3c',
  '#f03b20',
  '#bd0026'
];

var genusColors = {
  "AB": {
    "code": "AB",
    "color": "#E85EBE"
  },
  "AC": {
    "code": "AC",
    "color": "#000000"
  },
  "AE": {
    "code": "AE",
    "color": "#00FF00"
  },
  "AI": {
    "code": "AI",
    "color": "#0000FF"
  },
  "AL": {
    "code": "AL",
    "color": "#FF0000"
  },
  "AM": {
    "code": "AM",
    "color": "#01FFFE"
  },
  "AR": {
    "code": "AR",
    "color": "#FFA6FE"
  },
  "BE": {
    "code": "BE",
    "color": "#FFDB66"
  },
  "CA": {
    "code": "CA",
    "color": "#006401"
  },
  "CC": {
    "code": "CC",
    "color": "#010067"
  },
  "CD": {
    "code": "CD",
    "color": "#95003A"
  },
  "CE": {
    "code": "CE",
    "color": "#007DB5"
  },
  "CG": {
    "code": "CG",
    "color": "#FF00F6"
  },
  "CH": {
    "code": "CH",
    "color": "#FFEEE8"
  },
  "CI": {
    "code": "CI",
    "color": "#774D00"
  },
  "CL": {
    "code": "CL",
    "color": "#90FB92"
  },
  "CN": {
    "code": "CN",
    "color": "#0076FF"
  },
  "CO": {
    "code": "CO",
    "color": "#D5FF00"
  },
  "CR": {
    "code": "CR",
    "color": "#FF937E"
  },
  "CS": {
    "code": "CS",
    "color": "#6A826C"
  },
  "CT": {
    "code": "CT",
    "color": "#FF029D"
  },
  "EL": {
    "code": "EL",
    "color": "#FE8900"
  },
  "FA": {
    "code": "FA",
    "color": "#7A4782"
  },
  "FR": {
    "code": "FR",
    "color": "#7E2DD2"
  },
  "GI": {
    "code": "GI",
    "color": "#85A900"
  },
  "GL": {
    "code": "GL",
    "color": "#FF0056"
  },
  "GY": {
    "code": "GY",
    "color": "#A42400"
  },
  "JN": {
    "code": "JN",
    "color": "#00AE7E"
  },
  "JU": {
    "code": "JU",
    "color": "#683D3B"
  },
  "LA": {
    "code": "LA",
    "color": "#BDC6FF"
  },
  "LI": {
    "code": "LI",
    "color": "#263400"
  },
  "MA": {
    "code": "MA",
    "color": "#BDD393"
  },
  "MC": {
    "code": "MC",
    "color": "#00B917"
  },
  "ME": {
    "code": "ME",
    "color": "#9E008E"
  },
  "MG": {
    "code": "MG",
    "color": "#001544"
  },
  "MO": {
    "code": "MO",
    "color": "#C28C9F"
  },
  "NE": {
    "code": "NE",
    "color": "#FF74A3"
  },
  "NY": {
    "code": "NY",
    "color": "#01D0FF"
  },
  "OS": {
    "code": "OS",
    "color": "#004754"
  },
  "PH": {
    "code": "PH",
    "color": "#E56FFE"
  },
  "PI": {
    "code": "PI",
    "color": "#788231"
  },
  "PL": {
    "code": "PL",
    "color": "#0E4CA1"
  },
  "PN": {
    "code": "PN",
    "color": "#91D0CB"
  },
  "PO": {
    "code": "PO",
    "color": "#BE9970"
  },
  "PR": {
    "code": "PR",
    "color": "#968AE8"
  },
  "PS": {
    "code": "PS",
    "color": "#BB8800"
  },
  "PT": {
    "code": "PT",
    "color": "#43002C"
  },
  "PY": {
    "code": "PY",
    "color": "#DEFF74"
  },
  "QU": {
    "code": "QU",
    "color": "#00FFC6"
  },
  "RH": {
    "code": "RH",
    "color": "#FFE502"
  },
  "RO": {
    "code": "RO",
    "color": "#620E00"
  },
  "RR": {
    "code": "RR",
    "color": "#008F9C"
  },
  "RU": {
    "code": "RU",
    "color": "#98FF52"
  },
  "SA": {
    "code": "SA",
    "color": "#7544B1"
  },
  "SO": {
    "code": "SO",
    "color": "#B500FF"
  },
  "SY": {
    "code": "SY",
    "color": "#00FF78"
  },
  "TA": {
    "code": "TA",
    "color": "#FF6E41"
  },
  "TH": {
    "code": "TH",
    "color": "#005F39"
  },
  "TI": {
    "code": "TI",
    "color": "#6B6882"
  },
  "TS": {
    "code": "TS",
    "color": "#5FAD4E"
  },
  "UL": {
    "code": "UL",
    "color": "#A75740"
  },
  "VI": {
    "code": "VI",
    "color": "#A5FFD2"
  },
  "ZE": {
    "code": "ZE",
    "color": "#FFB167"
  }
};


var colorInstance = {};

function stringToColor(str) {
  //console.log(str);
  if (!colorInstance.hasOwnProperty("stringToColorHash")) {
    colorInstance.stringToColorHash = {};
    colorInstance.nextVeryDifferntColorIdx = 0;
    colorInstance.veryDifferentColors = ["#E85EBE", "#000000", "#00FF00", "#0000FF", "#FF0000", "#01FFFE", "#FFA6FE", "#FFDB66", "#006401", "#010067", "#95003A", "#007DB5", "#FF00F6", "#FFEEE8", "#774D00", "#90FB92", "#0076FF", "#D5FF00", "#FF937E", "#6A826C", "#FF029D", "#FE8900", "#7A4782", "#7E2DD2", "#85A900", "#FF0056", "#A42400", "#00AE7E", "#683D3B", "#BDC6FF", "#263400", "#BDD393", "#00B917", "#9E008E", "#001544", "#C28C9F", "#FF74A3", "#01D0FF", "#004754", "#E56FFE", "#788231", "#0E4CA1", "#91D0CB", "#BE9970", "#968AE8", "#BB8800", "#43002C", "#DEFF74", "#00FFC6", "#FFE502", "#620E00", "#008F9C", "#98FF52", "#7544B1", "#B500FF", "#00FF78", "#FF6E41", "#005F39", "#6B6882", "#5FAD4E", "#A75740", "#A5FFD2", "#FFB167", "#009BFF"];
  }

  if (!colorInstance.stringToColorHash[str]) {
    colorInstance.stringToColorHash[str] = {
      code: str,
      color: colorInstance.veryDifferentColors[colorInstance.nextVeryDifferntColorIdx++]
    };
  }

  return colorInstance.stringToColorHash[str];

}


/*   specimensColor.getGenusColors()[doc.data().geojsonFeature.properties.essence.slice(0, 2)] ? specimensColor.getGenusColors()[doc.data().geojsonFeature.properties.essence.slice(0, 2)].color : "white", */

export default {

  setSpecimenBorder: function (geojsonFeature) {
    if (geojsonFeature.properties.hasOwnProperty("groupSpecific") && geojsonFeature.properties.groupSpecific.hasOwnProperty(store.state.userGroupMembership) && geojsonFeature.properties.groupSpecific[store.state.userGroupMembership].hasOwnProperty("observations") && geojsonFeature.properties.groupSpecific[store.state.userGroupMembership].observations.hasOwnProperty("healthStatus") && geojsonFeature.properties.groupSpecific[store.state.userGroupMembership].observations.healthStatus.current.value === "Mort") {
      return "white";
    }
  },

  setSpecimenColor: function (geojsonFeature) {
    //console.log(geojsonFeature);
    // check if dead if store.state.userGroupMembership
    if (geojsonFeature.properties.hasOwnProperty("groupSpecific") && geojsonFeature.properties.groupSpecific.hasOwnProperty(store.state.userGroupMembership) && geojsonFeature.properties.groupSpecific[store.state.userGroupMembership].hasOwnProperty("observations") && geojsonFeature.properties.groupSpecific[store.state.userGroupMembership].observations.hasOwnProperty("healthStatus") && geojsonFeature.properties.groupSpecific[store.state.userGroupMembership].observations.healthStatus.current.value === "Mort") {
      return "white";
    }
    return genusColors[geojsonFeature.properties.essence.slice(0, 2)] ? genusColors[geojsonFeature.properties.essence.slice(0, 2)].color : "gray";
    // check if dead
    //   if (geojsonFeature.properties.)
  },

  getGenusColors: function () {
    return genusColors;
  },
  getColorsForMapLayers: function () {
    return colorsForMapLayers;
  }
};


