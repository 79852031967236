<template>
  <div id="map" class="map" :class="{
    activated: !$store.state.sideMenuDisabled,
    deactivated: $store.state.sideMenuDisabled,
  }" ref="mapDivRef">
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { IonChip, IonLabel, IonIcon, isPlatform } from "@ionic/vue";
import "mapbox-gl/dist/mapbox-gl.css";
import mapService from "../services/mapService";
import { useStore } from "vuex";
import arbresIcons from "../constants/arbresIcons";


console.log("loading mapboxGL component");

export default defineComponent({
  name: "MapboxGL",
  props: {
    name: String,
    mapTypeId: String,
    zoom: Number,
    disableDefaultUI: Boolean,
    center: { lat: Number, lng: Number },
    markers: Array,
    mapDidLoad: Function,
  },
  emits: ["fetchSpecimens"],
  setup(props, ctx) {
    const title = ref("Unchanged Popup Title");
    const store = useStore();
    const showMap = ref(false);
    onMounted(() => {
      const map = mapService.getMap();
      map.on("load", () => {
        store.state.currentMapStyle = "light-v11";
        // check if darkmode is enabled
        let systemDark = window.matchMedia("(prefers-color-scheme: dark)");
        if (systemDark.matches) {
          // choose darker map
          mapService.setMapStyle('dark-v11');
          store.state.currentMapStyle = "dark-v11";
          //map.setConfigProperty("dark-v11", "lightPreset", "night");
        }
        // map.setConfigProperty(store.state.currentMapStyle, "showPointOfInterestLabels", false);
        setTimeout(() => {
          store.commit("setMapboxMap", map);
          // let everyone know the map is loaded and ready, return map and access to its services
          props.mapDidLoad && props.mapDidLoad(map, "map loaded!");
          // preload all symbol images
          var arbesIconsList = arbresIcons.arbresIcons().list;
          arbesIconsList.forEach((species) => {
            //console.log("../assets/arbres/" + species.code + "@2x.png");
            map.loadImage(
              //  'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
              "../assets/arbres/" + species.code + "@2x.png",
              (error, image) => {
                if (error) throw error;
                //   if (!map.hasImage(species.code)) map.addImage(species.code, image);
                // adding a time out because of an issue displaying tree icons on safari https://github.com/mapbox/mapbox-gl-js/issues/11053
                if (!map.hasImage(species.code)) {
                  //console.log("isPlatform(ios)");
                  //console.log(isPlatform("ios"));
                  
                    map.addImage(species.code, image);
                  
                  // Add a GeoJSON source with 2 points
                }
              }
            );
          });
          console.log("map resize on load");
          if (map)
            map.resize();
        }, 100);


        // getthe user position and fetch trees automatically
        mapService
          .getCurrentPosition({ centerMap: true, timeOut: 15000 })
          .then(() => {
            console.log("after getCurrentPosition in MapboxGL.vue");
            function moveendCallback() {
              map.off("moveend", moveendCallback);
              mapService.animateUserLocationOnMap();
              ctx.emit("fetchSpecimens", "fitBounds");
              // turn on nearest specimen by default on map load
              store.commit("setShowNearestSpecimenModal", true);
            }
            map.on("moveend", moveendCallback);
          });
      });
    });
    return { title, showMap, IonChip, IonLabel, IonIcon };
  },
});
</script>