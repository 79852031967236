<template>
  <ion-content>
    <!--  the side menu toolbar -->

    <ion-toolbar
      style="
        height: 60px;
        width: 60px;
        right: 0px;
        position: absolute;
        --background: transparent;
      "
    >
      <ion-button
        slot="end"
        fill="clear"
        @click="closeSideMenu()"
        class="side-menu-close-button"
      >
        <ion-icon :icon="closeCircle"></ion-icon>
      </ion-button>
      <!-- <ion-segment
          v-show="$store.state.currentSegment != 'project'"
            :value="$store.state.currentSegment"
            @ionChange="segmentChanged($event)"
            class="ion-segment-side-menu"
          >
            <ion-segment-button
              value="statistiques"
              @click="$store.commit('setCurrentSegment', 'statistiques')"
            >
              <ion-label>Statistiques</ion-label>
            </ion-segment-button>

            <ion-segment-button
              value="specimen"
              @click="$store.commit('setCurrentSegment', 'specimen')"
            >
              <ion-label>Spécimen</ion-label>
            </ion-segment-button>
            
          </ion-segment> -->
    </ion-toolbar>

    <!-- subView 1 -->
    <div
      v-show="$store.state.currentSegment == 'statistiques'"
      class="side-menu-segment-content"
    >
    </div>

    <!-- subview 2 option 2-->
    <div class="side-menu-segment-content">
      <side-menu-project></side-menu-project>
    </div>
  </ion-content>
</template>

<script>
import { IonToolbar, IonContent, IonButton, IonIcon } from "@ionic/vue";
import {
  personOutline,
  personCircle,
  search,
  ellipsisHorizontal,
  locate,
  closeCircle,
} from "ionicons/icons";
/* import sideMenuProject from "@/components/sideMenuProject.vue";
 */
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "SideMenu",
  components: {
/*     sideMenuProject,
 */
    IonToolbar,
    /*     IonHeader,
     */ IonContent,
    IonButton,
    IonIcon,
  },
  setup() {
    //console.log("setup function reached");
    // This is the equivalent of retrieving this.$store within a component using the Option API
    const store = useStore();
    const currentSegment = store.state.currentSegment;
    const sideMenuDisabled = ref(false);

    const segmentChanged = (ev) => {
      //console.log("Segment changed", ev);
      //  currentSegment.value = ev.detail.value;
    };

    const closeSideMenu = () => {
      store.state.sideMenuDisabled = true;
/*       store.commit("setCurrentProjectID", null);
 */      //console.log(store.state.currentProjectID);
/*       if (store.state.toggleProjectQueryFilter) {
        store.state.queryOptions.radius = 300;
        store.state.toggleProjectQueryFilter = false;
        // remove projet query filter
        for (
          let index = 0;
          index < store.state.queryOptions.filters.length;
          index++
        ) {
          const filter = store.state.queryOptions.filters[index];
          if (filter.displayName == "Projet") {
            store.state.queryOptions.filters.splice(index, 1);
            break;
          }
        }
      } */
      /* store.state.mapboxMap.flyTo({
            center: store.state.currentProjectID.absoluteCenter.geometry.coordinates,
            offset: [0, 0],
            speed: 0.5,
            zoom: 16,
            essential: true, // this animation is considered essential with respect to prefers-reduced-motion
          }); */
    };

    return {
      personOutline,
      personCircle,
      search,
      ellipsisHorizontal,
      segmentChanged,
      currentSegment,
      sideMenuDisabled,
      locate,
      closeCircle,
      closeSideMenu,
    };
  },
});
</script>
<style lang="css" scoped>
.side-menu-segment-content {
  overflow: scroll;
}
</style>
