/* eslint-disable no-prototype-builtins */
//import { firestore } from "@nativescript/firebase/app";
import store from "../store";
import encodeGeohash from "./encodeGeohash";
/* import ActivePlantationService from "./ActivePlantationService";
 */import {
  getFirestore,
  writeBatch,
  collection,
  collectionGroup,
  doc,
  query,
  where,
  getDoc,
  getDocs,
  GeoPoint,
} from "firebase/firestore";
import { equal } from "../services/isEqual";
import firebaseService from "./firebaseService";
import mapService from "./mapService";
import { usePhoto } from "@/composables/usePhoto";
import specimensColor from "../services/specimensColor";

const { deletePicture } = usePhoto();

console.log("specimenService");

var firestoreDb = getFirestore();
//connectFirestoreEmulator(firestoreDb, 'localhost', 8080);
var groupName;
const specimensCountersCollectionRef = collection(firestoreDb, "counters");
const specimensCountersCollection = getDocs(specimensCountersCollectionRef);
specimensCountersCollection.then((res) => {
  res.docs.map((doc) => {
    //console.log(doc.id);
    if (doc.id == "specimens" || doc.id == "specimens_villeMontreal") {
      //console.log(doc.data());
      let fetchedDoc = doc.data();
      store.state.specimensCounter.total += fetchedDoc.counter;
      if (doc.id == "specimens_villeMontreal") {
        store.state.specimensCounter.fromPublicData += fetchedDoc.counter;
      }
      if (doc.id == "specimens") {
        store.state.specimensCounter.addedUsingBranche = fetchedDoc.counter;
        store.state.specimensCounter.aproposText = fetchedDoc.aproposText;
      }
    }
    //console.log(store.state.specimensCounter);
  });
});

/* var userGeneratedSpecimensCounterRef = doc(
  firestoreDb,
  "counters",
  "specimens"
);

const userGeneratedSpecimensCounterDoc = getDoc(
  userGeneratedSpecimensCounterRef
);

userGeneratedSpecimensCounterDoc.then(function (specimensCounter) {
//console.log("specimens total:");
//console.log(specimensCounter.data());
  store.state.specimensCounter = specimensCounter.data().counter;
}); */

function removeSpecialCharacters(str) {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[\W_]/g, "_");
}
var formatObservations = function (
  selectedSpecimen,
  diagnostics,
  selectedSpecimenHistory
) {
  //console.log("formatting observation for server");
  //console.log(diagnostics.observations);
  Object.keys(diagnostics.observations).forEach(function (keyName) {
    //console.log("keyName");
    //console.log(keyName);
    //console.log("diagnostics.observations[keyName]");
    //console.log(diagnostics.observations[keyName]);

    var observation = diagnostics.observations[keyName];
    //console.log("observation");
    //console.log(JSON.stringify(observation));
    if (
      keyName !== "defauts" &&
      observation.current.value !== null &&
      observation.current.value !== undefined
    ) {
      //console.log("observation.current.value !== null");
      //console.log(observation.current.value);
      // add time and recordedby
      // if ()
      observation.current.recordedBy = {
        name: store.state.user ? store.state.user.email : "anonyme",
        time: new Date().toISOString(),
      };

      if (!observation.hasOwnProperty("history")) {
        observation.history = [];
      } else {
        //console.log("history already present ");
        //console.log(JSON.stringify(observation.history));
      }

      if (keyName === "dhp") {
        console.log("keyname === dhp");
        console.log(observation.current.value);
        var dhpTotal = 0;
        if (!observation.current.value) {
          observation.current.value = [];
        }
        if (store.state.userGroupMembership == "singleUser") {
          // save as string
          // observation.current.value = dhpRealNumbersOnly;
          observation.current.dhpTotal = observation.current.value;
        } else {
          // return only numbers from dhp field
          const numberPattern = /\d+/g;
          var dhpNumbersOnly = Array.isArray(observation.current.value)
            ? observation.current.value
            : observation.current.value.match(numberPattern);
          var dhpRealNumbersOnly = [];
          if (dhpNumbersOnly) {
            dhpNumbersOnly.forEach(function (number) {
              dhpTotal = dhpTotal + parseInt(number);
              dhpRealNumbersOnly.push(parseInt(number));
            });
          }
          observation.current.value = dhpRealNumbersOnly;
          observation.current.dhpTotal = dhpTotal;
        }

        /* if (selectedSpecimen.groupSpecific.hasOwnProperty('villeMontreal')) {
                    //console.log("selectedSpecimen groupSpecific === villeMontreal");

                    if (selectedSpecimen.groupSpecific.villeMontreal.observations.hasOwnProperty(keyName)) {
                        selectedSpecimen.groupSpecific.villeMontreal.observations[keyName].current = observation.current;
                    } else {
                        selectedSpecimen.groupSpecific.villeMontreal.observations[keyName] = {
                            current: observation.current,
                            history: []
                        };
                    }
                } else { */
        if (
          selectedSpecimen.groupSpecific[groupName].observations.hasOwnProperty(
            keyName
          )
        ) {
          selectedSpecimen.groupSpecific[groupName].observations[
            keyName
          ].current = observation.current;
        } else {
          selectedSpecimen.groupSpecific[groupName].observations[keyName] = {
            current: observation.current,
            history: [],
          };
        }
        //  }
        // push current value in history if not already there
        //console.log("reaching dhp history formatting");

        if (
          (observation.current.dhpTotal > 0 &&
            observation.history.length === 0) ||
          (observation.current.dhpTotal > 0 &&
            observation.current.dhpTotal !== observation.history[0].dhpTotal)
        ) {
          observation.history.splice(0, 0, observation.current);
        }
      } else if (keyName === "leafVolume") {
        //console.log("keyname === leafvolume");

        if (observation.current.value) {
          // return only numbers from leafVolume field
          const numberPattern = /\d+/g;
          var numbersOnly = observation.current.value.match(numberPattern);
          if (numbersOnly) {
            // we only need first number from string
            observation.current.searchValue = parseInt(numbersOnly[0]);
          }
        }
        if (
          selectedSpecimen.groupSpecific[groupName].observations.hasOwnProperty(
            keyName
          )
        ) {
          selectedSpecimen.groupSpecific[groupName].observations[
            keyName
          ].current = observation.current;
        } else {
          selectedSpecimen.groupSpecific[groupName].observations[keyName] = {
            current: observation.current,
            history: [],
          };
        }
        // push current value in history if not already there
        if (
          observation.history.length === 0 ||
          observation.current.value !== observation.history[0].value
        ) {
          observation.history.splice(0, 0, observation.current);
        }
      } else if (keyName === "healthStatus") {
        if (!observation.current.value) {
          observation.current.value = "Bon";
        }
        if (observation.current.value.indexOf("Mort") > -1) {
          observation.current.searchValue = 0;
        }
        if (observation.current.value.indexOf("Faible") > -1) {
          observation.current.searchValue = 10;
        }
        if (observation.current.value.indexOf("Moyen") > -1) {
          observation.current.searchValue = 20;
        }
        if (observation.current.value.indexOf("Bon") > -1) {
          observation.current.searchValue = 30;
        }
        if (observation.current.value.indexOf("Arbre introuvable") > -1) {
          observation.current.searchValue = 40;
        }
        // check if we need to remove "cod" property
        if (
          observation.current.value !== "Mort" &&
          observation.current.hasOwnProperty("cod")
        ) {
          delete observation.current.cod;
        }
        if (
          selectedSpecimen.groupSpecific[groupName].observations.hasOwnProperty(
            keyName
          )
        ) {
          selectedSpecimen.groupSpecific[groupName].observations[
            keyName
          ].current = observation.current;
        } else {
          selectedSpecimen.groupSpecific[groupName].observations[keyName] = {
            current: observation.current,
            history: [],
          };
        }
        // push current value in history if not already there
        if (
          observation.history.length === 0 ||
          observation.current.value !== observation.history[0].value
        ) {
          observation.history.splice(0, 0, observation.current);
        }
      } else if (keyName === "mauvaisEmplacement") {
        /* if (!observation.current.value) {
                    observation.current.value = null;
                } */
        if (observation.current.value.indexOf("Clôture") > -1) {
          observation.current.searchValue = 0;
        }
        if (observation.current.value.indexOf("Fils électriques") > -1) {
          observation.current.searchValue = 10;
        }
        if (observation.current.value.indexOf("Fondation") > -1) {
          observation.current.searchValue = 20;
        }
        if (
          selectedSpecimen.groupSpecific[groupName].observations.hasOwnProperty(
            keyName
          )
        ) {
          selectedSpecimen.groupSpecific[groupName].observations[
            keyName
          ].current = observation.current;
        } else {
          selectedSpecimen.groupSpecific[groupName].observations[keyName] = {
            current: observation.current,
            history: [],
          };
        }
        // push current value in history if not already there
        if (
          observation.history.length === 0 ||
          observation.current.value !== observation.history[0].value
        ) {
          observation.history.splice(0, 0, observation.current);
        }
      }
    } else if (
      keyName === "defauts" &&
      (observation.list.length > 0 ||
        selectedSpecimen.groupSpecific[groupName].observations.hasOwnProperty(
          "defauts"
        ))
    ) {
      //console.log("entering defauts formatting");
      //console.log(JSON.stringify(diagnostics.observations.defauts.list));
      // turn back into object for firestore querying
      var tempObj = {};
      var tempObjForHistory = {};
      if (Array.isArray(diagnostics.observations.defauts.list)) {
        //console.log("entering isArray");
        diagnostics.observations.defauts.list.forEach(function (item) {
          //console.log(item);

          tempObj[removeSpecialCharacters(item.defaut.name)] = {
            recordedBy: item.recordedBy,
            dateDone:
              item.dateDone instanceof Date
                ? item.dateDone.toISOString()
                : item.dateDone,
            dateDoneSegmented: {
              year:
                item.dateDone instanceof Date
                  ? item.dateDone.getUTCFullYear()
                  : new Date(item.dateDone).getUTCFullYear(),
              month:
                item.dateDone instanceof Date
                  ? item.dateDone.getUTCMonth()
                  : new Date(item.dateDone).getUTCMonth(),
              date:
                item.dateDone instanceof Date
                  ? item.dateDone.getUTCDate()
                  : new Date(item.dateDone).getUTCDate(),
            },
            defaut: {
              localisation: item.defaut.localisation,
              name: item.defaut.name,
              type: item.defaut.type,
            },
          };

          //console.log(JSON.stringify(tempObj));
          //console.log(JSON.stringify(observation.history));

          tempObjForHistory[removeSpecialCharacters(item.defaut.name)] = {
            defaut: {
              localisation: item.defaut.localisation,
              name: item.defaut.name,
              type: item.defaut.type,
            },
          };
        });
      }

      selectedSpecimen.groupSpecific[groupName].observations[keyName] = {
        list: tempObj,
        history: diagnostics.observations.defauts.list.history
          ? diagnostics.observations.defauts.list.history
          : [],
      };

      /*  selectedSpecimen.groupSpecific[store.state.userGroupMembership].observations = {
                 defauts: {
                     list: tempObj,
                     // history: diagnostics.observations.defauts.list.history ? diagnostics.observations.defauts.list.history : []
                 }
             }; */
      //console.log("entering defauts formatting-2");
      //   //console.log(observation.history[0]);

      /*  // check for specimens that may have a null value in array
             if (observation.history[0] && observation.history[0] === null) {
                 observation.history.splice(0,1);
             }
              */

      // push current value in history if not already there
      if (observation.history.length === 0) {
        observation.history.push(tempObjForHistory);
      } else {
        //console.log("entering defauts formatting-3");
        var formattedHistoryForComparison = JSON.parse(
          JSON.stringify(observation.history[0])
        );
        //console.log("entering defauts formatting-4");
        if (
          formattedHistoryForComparison &&
          formattedHistoryForComparison.hasOwnProperty("archivedOn")
        ) {
          delete formattedHistoryForComparison.archivedOn;
        }
        //console.log("entering defauts formatting-5");
        //console.log(JSON.stringify(formattedHistoryForComparison));
        //console.log(JSON.stringify(tempObjForHistory));
        if (!equal(formattedHistoryForComparison, tempObjForHistory)) {
          //console.log("NOT EQUAL!!!!!!!!!!");
          observation.history.splice(0, 0, tempObjForHistory);
        }
      }

      //console.log("selectedSpecimen.groupSpecific[groupName].observations.defauts.list");
      //console.log(selectedSpecimen.groupSpecific[groupName].observations.defauts.list);
    }
    // handle history.
    //    angular.forEach(selectedSpecimen.groupSpecific[store.state.userGroupMembership].observations, function (observation) {
    if (
      observation.hasOwnProperty("history") &&
      observation.history.length > 0
    ) {
      //console.log("entering history formatting");

      //console.log(JSON.stringify(selectedSpecimenHistory));

      if (!selectedSpecimenHistory) {
        selectedSpecimenHistory = {};
      }

      if (!selectedSpecimenHistory.hasOwnProperty("_id")) {
        selectedSpecimenHistory._id = selectedSpecimen._id;
      }
      if (!selectedSpecimenHistory.hasOwnProperty("observations")) {
        selectedSpecimenHistory.observations = {};
      }
      selectedSpecimenHistory.observations[keyName] = JSON.parse(
        JSON.stringify(observation.history)
      );
      //console.log("entering history formatting-2");

      //console.log(JSON.stringify(selectedSpecimenHistory.observations));

      selectedSpecimenHistory.observations[keyName][0].archivedOn = {
        name: store.state.user ? store.state.user.email : "anonyme",
        time: new Date().toISOString(),
      };
      //console.log("entering history formatting-3 ");

      /*                  // add time and recordedby in new format and delete property dateDone
                             var previousRecordedByInHistory = JSON.parse(JSON.stringify(item.recordedBy));
                             item.recordedBy = {
                               name: previousRecordedByInHistory,
                               time: item.dateDone ? new Date(item.dateDone) : null
                             };
                             delete item.dateDone; */
    }
    //console.log("forEach loop end");

    //  });
  });

  return {
    selectedSpecimen: selectedSpecimen,
    selectedSpecimenHistory: selectedSpecimenHistory,
  };
};
var formatTasks = function (diagnostics, specimenTempObj) {
  //console.log("tasks reached");
  // //console.log(JSON.stringify(diagnostics.tasks));

  //console.log(JSON.stringify(specimenTempObj));
  var selectedSpecimen = specimenTempObj.selectedSpecimen;
  var selectedSpecimenHistory = specimenTempObj.selectedSpecimenHistory;

  if (selectedSpecimen.groupSpecific.hasOwnProperty("villeMontreal")) {
    // skip task
    return {
      selectedSpecimen: selectedSpecimen,
      selectedSpecimenHistory: selectedSpecimenHistory,
      diagnostics: diagnostics,
    };
  }

  if (Array.isArray(diagnostics.tasks)) {
    diagnostics.tasks.forEach(function (task, i) {
      //console.log(task);
      //console.log("task index");
      //console.log(i);
      if (!task.hasOwnProperty("history")) {
        //console.log("history not already present for task");
        task.history = [];
      } else {
        //console.log("history already present for task");
        //console.log(JSON.stringify(task.history));
      }
      if (
        !(!task.suivi.dateToDo && !task.suivi.dateDone) ||
        (task.suivi.dateToDo === "" && task.suivi.dateDone === "")
      ) {
        // set segmentedDate
        //console.log("task1");

        if (task.suivi.dateToDo) {
          //console.log(task.suivi.dateToDo);
          task.suivi.dateToDoSegmented = {
            year: new Date(task.suivi.dateToDo).getUTCFullYear(),
            month: new Date(task.suivi.dateToDo).getUTCMonth(),
            date: new Date(task.suivi.dateToDo).getUTCDate(),
          };
          // make sure all dates are in ISO format before putting back on firestore
          task.suivi.dateToDo = new Date(task.suivi.dateToDo).toISOString();
        } else {
          //console.log(task.suivi);
          delete task.suivi.dateToDoSegmented;
        }
        //console.log("task2");

        if (task.suivi.dateDone) {
          task.suivi.dateDoneSegmented = {
            year: new Date(task.suivi.dateDone).getUTCFullYear(),
            month: new Date(task.suivi.dateDone).getUTCMonth(),
            date: new Date(task.suivi.dateDone).getUTCDate(),
          };
          // make sure all dates are in ISO format before putting back on firestore
          task.suivi.dateDone = new Date(task.suivi.dateDone).toISOString();
        } else {
          delete task.suivi.dateDoneSegmented;
        }
        //console.log("task3");
        //console.log("task3");
        //console.log(JSON.stringify(selectedSpecimen));
        //console.log(JSON.stringify(task.suivi));
        //console.log(JSON.stringify(task.name));
        //console.log(store.state.userGroupMembership);

        // check if tasks are presemt
        if (
          !selectedSpecimen.groupSpecific[
            store.state.userGroupMembership
          ].hasOwnProperty("tasks")
        ) {
          selectedSpecimen.groupSpecific[
            store.state.userGroupMembership
          ].tasks = {};
        }

        if (
          selectedSpecimen.groupSpecific[
            store.state.userGroupMembership
          ].tasks.hasOwnProperty(removeSpecialCharacters(task.name))
        ) {
          //console.log("task3b");

          selectedSpecimen.groupSpecific[store.state.userGroupMembership].tasks[
            removeSpecialCharacters(task.name)
          ].suivi = task.suivi;
        } else {
          //console.log("task3bb");
          selectedSpecimen.groupSpecific[store.state.userGroupMembership].tasks[
            removeSpecialCharacters(task.name)
          ] = {
            suivi: task.suivi,
          };
          //console.log("task3c");
        }
        //console.log("task4");

        // add time and recordedby
        selectedSpecimen.groupSpecific[store.state.userGroupMembership].tasks[
          removeSpecialCharacters(task.name)
        ].suivi.recordedBy = {
          name: store.state.user.email,
          time: new Date().toISOString(),
        };
        //console.log("task5");

        // clean up tasks
        delete task.suivi.showDatePickerForDateToDo;
        delete task.suivi.showDatePickerForDateDone;
        delete task.suivi.datePickerForDateToDo;
        delete task.suivi.datePickerForDateDone;
        //console.log("task6");
        //console.log("task history");
        //console.log(task.history);
        //console.log("task suivi");
        //console.log(task.suivi);

        // all task properties are not empty, we might need to push to history
        if (!task.history[0]) {
          // there is nothing in history so we can push right away at position 0
          task.history.splice(0, 0, task.suivi);
        } else {
          if (
            task.history[0] &&
            ((task.suivi.dateToDo &&
              new Date(task.suivi.dateToDo).toISOString() ===
              task.history[0].dateToDo) ||
              (task.suivi.dateDone &&
                new Date(task.suivi.dateDone).toISOString() ===
                task.history[0].dateDone))
          ) {
            //console.log("already in history or array was empty ");
          } else {
            task.history.splice(0, 0, task.suivi);
            //console.log("added to history!");
          }
        }
        //console.log("task7");

        // add history to firestore object
        if (!selectedSpecimenHistory.hasOwnProperty("_id")) {
          selectedSpecimenHistory._id = selectedSpecimen.data._id;
        }
        if (!selectedSpecimenHistory.hasOwnProperty("tasks")) {
          selectedSpecimenHistory.tasks = {};
        }
        //console.log("task8");

        selectedSpecimenHistory.tasks[removeSpecialCharacters(task.name)] =
          JSON.parse(JSON.stringify(task.history));
        selectedSpecimenHistory.tasks[
          removeSpecialCharacters(task.name)
        ][0].archivedOn = {
          name: store.state.user.email,
          time: new Date().toISOString(),
        };
      } else {
        //console.log("task is empty!");
        // dateToDone and dateDone are both empty/null in the ui model, lets check if we need to remove previous tasks from the server-fetched specimen
        //console.log(selectedSpecimen.groupSpecific[groupName].tasks);
        //console.log("removeSpecialCharacters(task.name)");
        //console.log(removeSpecialCharacters(task.name));
        if (
          selectedSpecimen.groupSpecific[groupName].hasOwnProperty("tasks") &&
          selectedSpecimen.groupSpecific[groupName].tasks.hasOwnProperty(
            removeSpecialCharacters(task.name)
          )
        ) {
          delete selectedSpecimen.groupSpecific[groupName].tasks[
            removeSpecialCharacters(task.name)
          ];
        }
      }
    });
  }
  //console.log("returning tasks");
  return {
    selectedSpecimen: selectedSpecimen,
    selectedSpecimenHistory: selectedSpecimenHistory,
    diagnostics: diagnostics,
  };
};
var formatFullSpecimen = function (specimenTempObj, formattedSpecimen) {
  var selectedSpecimen = specimenTempObj.selectedSpecimen;
  var selectedSpecimenHistory = specimenTempObj.selectedSpecimenHistory;
  var diagnostics = specimenTempObj.diagnostics;

  selectedSpecimen.groupSpecific[groupName].comments = diagnostics.comments
    ? diagnostics.comments
    : "";

  /* if (groupName == "omhm" && diagnostics.new_treeTag) {
        selectedSpecimen.groupSpecific[groupName].new_treeTag = diagnostics.new_treeTag;
    } */

  //console.log("selectedSpecimen before full formating ");
  //console.log(selectedSpecimen);
  //console.log("selectedSpecimenHistory");
  //console.log(JSON.stringify(selectedSpecimenHistory));
  //console.log(selectedSpecimen.essence);
  //console.log("formattedSpecimen");
  //console.log(formattedSpecimen);

  // reformat for firestore
  var reformated = {};
  reformated = JSON.parse(JSON.stringify(formattedSpecimen));
  //reformated.groupSpecific = {};
  reformated.groupSpecific[groupName] =
    selectedSpecimen.groupSpecific[groupName];
  // remove history
  Object.keys(reformated.groupSpecific[groupName].observations).forEach(
    function (keyname) {
      //console.log(keyname);
      let observation =
        reformated.groupSpecific[groupName].observations[keyname];
      delete observation.history;
      if (keyname !== "defauts" && !observation.current.value) {
        delete reformated.groupSpecific[groupName].observations[observation];
      } else if (keyname === "defauts" && observation.list.length === 0) {
        delete reformated.groupSpecific[groupName].observations[observation];
      }
    }
  );
  var tasksObj = {};
  //console.log(reformated.groupSpecific[groupName]);
  //console.log(reformated.groupSpecific[groupName].tasks);
  if (reformated.groupSpecific[groupName].hasOwnProperty("tasks")) {
    const tasksKeynames = Object.keys(
      reformated.groupSpecific[groupName].tasks
    );
    tasksKeynames.forEach((taskKeyname) => {
      //exclude keynames that are numbers.  its a leftover from the ui bindings
      if (!parseInt(taskKeyname) && taskKeyname != "0") {
        let task = reformated.groupSpecific[groupName].tasks[taskKeyname];
        delete task.history;
        if (task.suivi.dateToDo || task.suivi.dateDone) {
          tasksObj[removeSpecialCharacters(taskKeyname)] = task;
        }
      }
    });
  }
  reformated.groupSpecific[groupName].tasks = tasksObj;
  reformated.groupSpecific[groupName].place = formattedSpecimen.groupSpecific[
    groupName
  ].place
    ? formattedSpecimen.groupSpecific[groupName].place
    : {};
  reformated.firestoreId = store.state.chosenSpecimenMarkerFirestoreId
    ? store.state.chosenSpecimenMarkerFirestoreId
    : "";
  if (reformated.hasOwnProperty("photos") && reformated.photos.length > 0) {
    reformated.hasPhoto = true;
  }
  /*   delete reformated.showOnMap;
      delete reformated.treeIcon;
      delete reformated.essenceName;
      delete reformated.nameFr;
      delete reformated.nameLa; */

  // add time and recordedby
  reformated.groupSpecific[groupName].lastModifiedBy = {
    name: store.state.user ? store.state.user.email : "anonyme",
    time: new Date().toISOString(),
  };
  //console.log("returnng reformated specimen");
  return {
    reformated: reformated,
    selectedSpecimenHistory: selectedSpecimenHistory,
  };
};
var createHistoryForNewSpecimen = function (newSpecimen) {
  var newHistoryModel = {
    _id: newSpecimen.geojsonFeature.properties._id,
    firestoreId: newSpecimen.geojsonFeature.properties.firestoreId,
    observations: {},
    tasks: {},
  };

  var observations =
    newSpecimen.geojsonFeature.properties.groupSpecific[
      store.state.userGroupMembership
    ].observations;

  var observationsKeynames = Object.keys(observations);

  observationsKeynames.forEach(function (keyname) {
    var recordedObservation = observations[keyname].current;
    if (recordedObservation) {
      newHistoryModel.observations[keyname] = [recordedObservation];
    }
  });

  var tasks =
    newSpecimen.geojsonFeature.properties.groupSpecific[
      store.state.userGroupMembership
    ].tasks;
  if (!tasks) {
    tasks = {};
  }

  var tasksKeynames = Object.keys(tasks);

  tasksKeynames.forEach(function (keyname) {
    var recordedTask = tasks[keyname].suivi;
    newHistoryModel.tasks[keyname] = [recordedTask];
    if (recordedTask.dateDone) {
      if (!newHistoryModel.hasOwnProperty("doneSummary")) {
        newHistoryModel.doneSummary = {};
      }
      if (
        !newHistoryModel.doneSummary.hasOwnProperty(
          removeSpecialCharacters(keyname)
        )
      ) {
        newHistoryModel.doneSummary[removeSpecialCharacters(keyname)] = {};
      }
      newHistoryModel.doneSummary[removeSpecialCharacters(keyname)] = {
        amountOfDones: 1,
        done1: recordedTask.dateDoneSegmented,
      };
    }
  });

  return newHistoryModel;
};

export default {
  getNewFormattedSpecimen(speciesCode) {
    return new Promise(function (resolve, reject) {
      //console.log("getNewFormattedSpecimen function accessed in services");
      /* if (store.state.userGroupMembership === 'omhm') {
                //console.log("store.state.omhmAddresses");

                store.state.omhmAddresses.features.forEach(function (item) {
                    //console.log(JSON.stringify(item.properties.place.habitationName));
                })
                //console.log(store.state.omhmAddresses);
            } */

      store.commit("setCreateOrModifySpecimen", "create");

      //console.log("store.state.currentPosition");
      //console.log(store.state.currentPosition);

      groupName = store.state.userGroupMembership;

      var specimen = {
        _id: 0,
        author_uid: store.state.user.uid,
        essence: "",
        owner: "",
        photos: [],
        hasPhoto: false,
        plant_date: "",
        owning_group: store.state.userGroupMembership,
        groupSpecific: {
          [store.state.userGroupMembership]: {
            observations: {
              healthStatus: {
                current: {},
              },
              dhp: {
                current: {},
              },
              leafVolume: {
                current: {},
              },
            },
            tasks: {},
            comments: "",
          },
        },
      };

      var emptyGeojsonFeature = {
        properties: specimen,
      };

      // set empty model in fetchedSpecimen
      store.commit("setFetchedSpecimen", emptyGeojsonFeature);

      // set closest omhm habitation in place property
      //  if (store.state.omhmAdresses)

      let formattedSpecimen = {};
      var groupSpecificTreeModel = {};
      var selectedSpecimenHistory = {};
      var historyCollection;
      var propertyAnnotations = [];
      var tasks = [];
      var taskNames;

      // flatten objects to build csv headers
      var merge = function (objects) {
        var out = {};
        for (var i = 0; i < objects.length; i++) {
          for (var p in objects[i]) {
            out[p] = objects[i][p];
          }
        }
        return out;
      };

      var flatten = function (obj, name, stem) {
        var out = {};
        var newStem =
          typeof stem !== "undefined" && stem !== "" ? stem + "_" + name : name;
        if (typeof obj !== "object") {
          out[newStem] = obj;
          return out;
        }
        for (var p in obj) {
          if (p !== "groupSpecific") {
            var prop = flatten(obj[p], p, newStem);
            out = merge([out, prop]);
          }
        }
        return out;
      };

      function makeTasks(selectedSpecimen) {
        //console.log("tasssssks");
        // return $q(function (resolve, reject) {

        taskNames = groupSpecificTreeModel.tasks;
        var taskIndex = 0;
        for (var taskName of taskNames) {
          taskIndex++;
          var normalizedTaskName = removeSpecialCharacters(taskName);
          // //console.log(normalizedTaskName);
          var tasksModel = {
            [normalizedTaskName]: {
              name: taskName,
              suivi: {
                toDo: false,
                dateToDo: null,
                dateToDoSegmented: null,
                dateDone: null,
                dateDoneSegmented: null,
                dateDoneChecked: "",
                recordedBy: null,
                showDatePickerForDateToDo: false,
                showDatePickerForDateDone: false,
              },
              showHistory: false,
              //   history: selectedSpecimenHistory.hasOwnProperty("tasks") && selectedSpecimenHistory.tasks[normalizedTaskName] ? selectedSpecimenHistory.tasks[normalizedTaskName] : []
            },
          };
          if (
            !selectedSpecimen.groupSpecific[
              store.state.userGroupMembership
            ].hasOwnProperty("tasks")
          ) {
            selectedSpecimen.groupSpecific[
              store.state.userGroupMembership
            ].tasks = {};
          }
          if (
            selectedSpecimen.groupSpecific[
              store.state.userGroupMembership
            ].tasks.hasOwnProperty(normalizedTaskName)
          ) {
            // already present on current tree.  Push in ui array
            Object.keys(tasksModel[normalizedTaskName].suivi).forEach(function (
              propName
            ) {
              if (
                selectedSpecimen.groupSpecific[
                  store.state.userGroupMembership
                ].tasks[normalizedTaskName].suivi.hasOwnProperty(propName)
              ) {
                tasksModel[normalizedTaskName].suivi[propName] =
                  selectedSpecimen.groupSpecific[
                    store.state.userGroupMembership
                  ].tasks[normalizedTaskName].suivi[propName];
                if (propName === "dateToDo" || propName === "dateDone") {
                  // turn into Date object
                  if (
                    tasksModel[normalizedTaskName].suivi[propName] &&
                    tasksModel[normalizedTaskName].suivi[
                      propName
                    ].hasOwnProperty("seconds")
                  ) {
                    tasksModel[normalizedTaskName].suivi[propName] =
                      tasksModel[normalizedTaskName].suivi[propName].seconds !==
                        0
                        ? new Date(
                          tasksModel[normalizedTaskName].suivi[propName]
                            .seconds * 1000
                        )
                        : null;
                  } else {
                    tasksModel[normalizedTaskName].suivi[propName] = tasksModel[
                      normalizedTaskName
                    ].suivi[propName]
                      ? new Date(tasksModel[normalizedTaskName].suivi[propName])
                      : null;
                  }
                }
              } else {
                tasksModel[normalizedTaskName].suivi[propName] = null;
              }
              /*    // add to form object
                               if (propName === 'dateToDo' || propName === 'dateDone' || propName === 'recordedBy') {

                                   var formObjProp = "task_" + normalizedTaskName + "_" + propName;
                                   formattedSpecimen[formObjProp] = tasksModel[normalizedTaskName].suivi[propName];
                                   var propertyAnnotation = {
                                       name: formObjProp,
                                       displayName: propName === 'dateToDo' ? taskName + " à faire" : taskName + " fait",
                                       required: false,
                                       groupName: "Tâches",
                                       index: taskIndex,
                                       readOnly: propName === 'recordedBy'
                                   }
                                   if (formObjProp.indexOf('date') > -1) {
                                       propertyAnnotation.editor = "DatePicker"
                                   }
                                   propertyAnnotations.push(propertyAnnotation);
                               } */
            });
            tasks.push(tasksModel[normalizedTaskName]);
          } else {
            /*  // add to form object
                         Object.keys(tasksModel[normalizedTaskName].suivi).forEach(function (propName) {
                             // add to form object
                             if (propName === 'dateToDo' || propName === 'dateDone') {
                                 var formObjProp = "task_" + normalizedTaskName + "_" + propName;
                                 formattedSpecimen[formObjProp] = tasksModel[normalizedTaskName].suivi[propName];
                                 var propertyAnnotation = {
                                     name: formObjProp,
                                     displayName: propName === 'dateToDo' ? taskName + " à faire" : taskName + " fait",
                                     required: false,
                                     groupName: "Tâches",
                                     index: taskIndex,
                                     readOnly: propName === 'recordedBy'
                                 }
                                 if (formObjProp.indexOf('date') > -1) {
                                     propertyAnnotation.editor = "DatePicker"
                                 }
                                 propertyAnnotations.push(propertyAnnotation);
                             }
                         }) */
            //push empty task in ui array
            tasks.push(tasksModel[normalizedTaskName]);
          }

          /* propertyAnnotations.push({
                        name: normalizedTaskName,
                        displayName: normalizedTaskName,
                        required: false,
                        groupName: "Tâches"
                    }); */
        }

        return tasks;
        //    resolve(tasks);

        // })
      }

      // get specific treeModel
      groupSpecificTreeModel = store.state.groupSpecificTreeModel;
      var selectedSpecimen = JSON.parse(JSON.stringify(specimen));
      //console.log("selectedSpecimen");
      //console.log(selectedSpecimen);

      /* var readOnlyInfos = {};
            readOnlyInfos._id = selectedSpecimen._id;
            readOnlyInfos.owner = selectedSpecimen.owner;
            readOnlyInfos.plant_date = selectedSpecimen.plant_date;
            readOnlyInfos.photos = selectedSpecimen.photos;
            readOnlyInfos.essence = selectedSpecimen.essence; */

      selectedSpecimen.nameFr = "";
      selectedSpecimen.photos = [];

      selectedSpecimen.groupSpecific[
        store.state.userGroupMembership
      ].observations = JSON.parse(
        JSON.stringify(groupSpecificTreeModel.observations)
      );
      selectedSpecimen.groupSpecific[store.state.userGroupMembership].tasks =
        makeTasks(selectedSpecimen);

      // add properties for collaborativeForm
      selectedSpecimen.groupSpecific.collaborative = {};
      selectedSpecimen.groupSpecific.collaborative.reports = {};
      selectedSpecimen.groupSpecific.collaborative.likes = {};
      selectedSpecimen.groupSpecific.collaborative.likeTags = {};

      if (store.state.userGroupMembership === "omhm") {
        //console.log("store.state.nearestOmhmAddress.place ");
        //console.log(JSON.stringify(store.state.nearestOmhmAddress));
        if (store.state.nearestOmhmAddress) {
          //console.log(store.state.nearestOmhmAddress.properties);
          selectedSpecimen.groupSpecific[
            store.state.userGroupMembership
          ].place = store.state.nearestOmhmAddress.properties.place;
          if (
            store.state.nearestOmhmAddress.properties.place.hasOwnProperty(
              "habitationName"
            )
          ) {
            selectedSpecimen.groupSpecific[
              store.state.userGroupMembership
            ].place.name =
              store.state.nearestOmhmAddress.properties.place.habitationName;
          } else {
            selectedSpecimen.groupSpecific[
              store.state.userGroupMembership
            ].place.name = "";
          }
          delete selectedSpecimen.groupSpecific[store.state.userGroupMembership]
            .place.habitationName;
        } else {
          selectedSpecimen.groupSpecific[
            store.state.userGroupMembership
          ].place = { name: "" };
        }
        // also édit owner properties because omhm wants to see the user name
        selectedSpecimen.owner = store.state.user.displayName;
      }
      store.commit("setFormattedSpecimen", selectedSpecimen);
      resolve();
    });
  },
  getFormattedSpecimen(specimen) {
    //console.log("accessing getFormattedSpecimen in service");
    return new Promise(function (resolve, reject) {
      //console.log("getFormattedSpecimen function  accessed in services");
      store.commit("setCreateOrModifySpecimen", "modify");

      /* if (!specimen) {
                specimen = store.state.fetchedSpecimen.geojsonFeature.properties;
            } */

      //console.log(JSON.stringify(specimen));

      let formattedSpecimen = {};
      var groupSpecificTreeModel = {};
      var selectedSpecimenHistory = {};
      var historyCollection;
      var propertyAnnotations = [];
      var tasks = [];
      var taskNames;

      /* 
                        // flatten objects to build csv headers
                        var merge = function (objects) {
                            var out = {};
                            for (var i = 0; i < objects.length; i++) {
                                for (var p in objects[i]) {
                                    out[p] = objects[i][p];
                                }
                            }
                            return out;
                        };
            
                        var flatten = function (obj, name, stem) {
                            var out = {};
                            var newStem = (typeof stem !== "undefined" && stem !== "") ? stem + "_" + name : name;
                            if (typeof obj !== "object") {
                                out[newStem] = obj;
                                return out;
                            }
                            for (var p in obj) {
                                if (p !== "groupSpecific") {
                                    var prop = flatten(obj[p], p, newStem);
                                    out = merge([out, prop]);
                                }
                            }
                            return out;
                        };
             */

      function makeTasks(selectedSpecimen) {
        //  //console.log("history: " + JSON.stringify(store.state.selectedSpecimenHistory));

        /*     if (selectedSpecimen.data.essence.match('ARBU--') || selectedSpecimen.data.essence.match('PLATEBANDE')) {
                      taskNames = ['Éclaircissage', "Rabattage", "Recépage", "Taille de contrôle",
                        "Rajeunissement", "Entretien", "Formation", "Tonte", "Taille annuelle des rosiers", "Taille d'entretien estivale des rosiers", "Taille de rejets et des drageons des rosiers", "Taille de formation des rosiers grimpants", "Taille de la flèche des conifères", "Remplacement", "Taille bordure", "Amendement du sol – compost", "Épandage de paillis"
                      ];
                    } else if (selectedSpecimen.data.essence.match('NEWTREELOCATION')) { // tasks for shrubs
                      taskNames = ['Plantation'];
                    } else { // tasks for trees
                      taskNames = ['abattage', 'vaccination', 'enlever le tuteur', 'rehaussement couronne',
                        'dégagement lampadaire', 'dégagement bâtiment', 'dégagement signalisation',
                        'élagage sécuritaire', 'taille de formation', "Rond d'arbre, compost et paillis", "élagage d'assainissement", "réduction", "inspection détaillée"
                      ];
                    } */
        // return $q(function (resolve, reject) {

        taskNames = groupSpecificTreeModel.tasks;
        if (!taskNames) {
          return tasks;
        }
        var taskIndex = 0;
        for (var taskName of taskNames) {
          taskIndex++;
          var normalizedTaskName = removeSpecialCharacters(taskName);
          // //console.log(normalizedTaskName);
          var tasksModel = {
            [normalizedTaskName]: {
              name: taskName,
              suivi: {
                toDo: false,
                dateToDo: null,
                dateToDoSegmented: null,
                dateDone: null,
                dateDoneSegmented: null,
                dateDoneChecked: "",
                recordedBy: null,
                showDatePickerForDateToDo: false,
                showDatePickerForDateDone: false,
              },
              showHistory: false,
              history:
                selectedSpecimenHistory.hasOwnProperty("tasks") &&
                  selectedSpecimenHistory.tasks[normalizedTaskName] &&
                  selectedSpecimenHistory.tasks[normalizedTaskName].length > 0
                  ? selectedSpecimenHistory.tasks[normalizedTaskName]
                  : [],
            },
          };
          if (
            !selectedSpecimen.groupSpecific[groupName].hasOwnProperty("tasks")
          ) {
            selectedSpecimen.groupSpecific[groupName].tasks = {};
          }
          if (
            selectedSpecimen.groupSpecific[groupName].tasks.hasOwnProperty(
              normalizedTaskName
            )
          ) {
            // already present on current tree.  Push in ui array
            Object.keys(tasksModel[normalizedTaskName].suivi).forEach(function (
              propName
            ) {
              if (
                selectedSpecimen.groupSpecific[groupName].tasks[
                  normalizedTaskName
                ].suivi.hasOwnProperty(propName)
              ) {
                tasksModel[normalizedTaskName].suivi[propName] =
                  selectedSpecimen.groupSpecific[groupName].tasks[
                    normalizedTaskName
                  ].suivi[propName];
                if (propName === "dateToDo" || propName === "dateDone") {
                  // turn into Date object
                  if (
                    tasksModel[normalizedTaskName].suivi[propName] &&
                    tasksModel[normalizedTaskName].suivi[
                      propName
                    ].hasOwnProperty("seconds")
                  ) {
                    tasksModel[normalizedTaskName].suivi[propName] =
                      tasksModel[normalizedTaskName].suivi[propName].seconds !==
                        0
                        ? new Date(
                          tasksModel[normalizedTaskName].suivi[propName]
                            .seconds * 1000
                        )
                        : null;
                  } else {
                    tasksModel[normalizedTaskName].suivi[propName] = tasksModel[
                      normalizedTaskName
                    ].suivi[propName]
                      ? new Date(tasksModel[normalizedTaskName].suivi[propName])
                      : null;
                  }
                }
              } else {
                tasksModel[normalizedTaskName].suivi[propName] = null;
              }
              /*    // add to form object
                               if (propName === 'dateToDo' || propName === 'dateDone' || propName === 'recordedBy') {

                                   var formObjProp = "task_" + normalizedTaskName + "_" + propName;
                                   formattedSpecimen[formObjProp] = tasksModel[normalizedTaskName].suivi[propName];
                                   var propertyAnnotation = {
                                       name: formObjProp,
                                       displayName: propName === 'dateToDo' ? taskName + " à faire" : taskName + " fait",
                                       required: false,
                                       groupName: "Tâches",
                                       index: taskIndex,
                                       readOnly: propName === 'recordedBy'
                                   }
                                   if (formObjProp.indexOf('date') > -1) {
                                       propertyAnnotation.editor = "DatePicker"
                                   }
                                   propertyAnnotations.push(propertyAnnotation);
                               } */
            });
            tasks.push(tasksModel[normalizedTaskName]);
          } else {
            /*  // add to form object
                         Object.keys(tasksModel[normalizedTaskName].suivi).forEach(function (propName) {
                             // add to form object
                             if (propName === 'dateToDo' || propName === 'dateDone') {
                                 var formObjProp = "task_" + normalizedTaskName + "_" + propName;
                                 formattedSpecimen[formObjProp] = tasksModel[normalizedTaskName].suivi[propName];
                                 var propertyAnnotation = {
                                     name: formObjProp,
                                     displayName: propName === 'dateToDo' ? taskName + " à faire" : taskName + " fait",
                                     required: false,
                                     groupName: "Tâches",
                                     index: taskIndex,
                                     readOnly: propName === 'recordedBy'
                                 }
                                 if (formObjProp.indexOf('date') > -1) {
                                     propertyAnnotation.editor = "DatePicker"
                                 }
                                 propertyAnnotations.push(propertyAnnotation);
                             }
                         }) */
            //push empty task in ui array
            tasks.push(tasksModel[normalizedTaskName]);
          }

          /*     if (selectedSpecimenHistory.hasOwnProperty("tasks") && selectedSpecimenHistory.tasks.hasOwnProperty(normalizedTaskName) && selectedSpecimenHistory.tasks[normalizedTaskName].length > 0) {
                            tasks.forEach(function(task){
                                //console.log("taskName in add task history");
                                //console.log(taskName);
                                if(task.name == taskName){
                                    //console.log("match found in add task history");
                                    task.history = selectedSpecimenHistory.tasks[normalizedTaskName];
                                }
                            })
                        }  */
          /* propertyAnnotations.push({
                        name: normalizedTaskName,
                        displayName: normalizedTaskName,
                        required: false,
                        groupName: "Tâches"
                    }); */
        }

        return tasks;
        //    resolve(tasks);

        // })
      }

      function makeObservations(selectedSpecimen) {
        if (
          selectedSpecimen.essence.match("ARBU--OFF") ||
          selectedSpecimen.essence.match("PLATEBANDE-OFF")
        ) {
          //console.log('ARBU OR PLATEBANDE');
          // observations for shrubs
          return {
            length: {
              current: {
                dateDone: null,
                recordedBy: null,
                value: null,
              },
              history: [],
            },
            width: {
              current: {
                dateDone: null,
                recordedBy: null,
                value: null,
              },
              history: [],
            },
            healthStatus: {
              current: {
                dateDone: null,
                recordedBy: null,
                value: null,
              },
              history: [],
            },
          };
        } else {
          var observations = Object.keys(groupSpecificTreeModel.observations);
          var observationsObjModel = {};
          observationsObjModel = JSON.parse(
            JSON.stringify(groupSpecificTreeModel.observations)
          );
          if (
            !selectedSpecimen.groupSpecific[groupName].hasOwnProperty(
              "observations"
            )
          ) {
            selectedSpecimen.groupSpecific[groupName].observations = {};
          }
          observations.forEach(function (observation, index) {
            if (
              selectedSpecimen.groupSpecific[
                groupName
              ].observations.hasOwnProperty(observation)
            ) {
              // an observation has already been recorded.  Put it into ui model
              if (observation === "defauts") {
                // convert into array for ng-repeat
                var defautsAsArray = [];
                Object.keys(
                  selectedSpecimen.groupSpecific[groupName].observations.defauts
                    .list
                ).forEach(function (value, index) {
                  defautsAsArray.push(
                    selectedSpecimen.groupSpecific[groupName].observations
                      .defauts.list[value]
                  );

                  /*   // add to form object
                                      var formObjProp = "defaut_" + observation + "_" + value;
                                      formattedSpecimen[formObjProp] = selectedSpecimen.groupSpecific[store.state.userGroupMembership].observations.defauts.list[value];

                                      var propertyAnnotation = {
                                          name: formObjProp,
                                          displayName: propName === 'dateToDo' ? taskName + " à faire" : taskName + " fait",
                                          required: false,
                                          groupName: "Défaut observés",
                                          index: index,
                                          //         hidden: propName === 'recordedBy'
                                      }
                                      if (formObjProp.indexOf('date') > -1) {
                                          propertyAnnotation.editor = "DatePicker"
                                      }
                                      propertyAnnotations.push(propertyAnnotation); */
                });
                /*  angular.forEach(selectedSpecimen.groupSpecific[store.state.userGroupMembership].observations.defauts.list, function (defaut) {
                                     //console.log(defaut);
                                     defautsAsArray.push(defaut);
                                 }) */
                observationsObjModel.defauts.list = defautsAsArray;
              } else {
                Object.keys(observationsObjModel[observation].current).forEach(
                  function (currentProperty) {
                    if (
                      selectedSpecimen.groupSpecific[groupName].observations[
                        observation
                      ].current.hasOwnProperty(currentProperty)
                    ) {
                      observationsObjModel[observation].current[
                        currentProperty
                      ] =
                        selectedSpecimen.groupSpecific[groupName].observations[
                          observation
                        ].current[currentProperty];
                    }
                    if (currentProperty === "value") {
                      /*  // add to form object
                                         var formObjProp = "observation_" + observation + "_" + currentProperty;
                                         formattedSpecimen[formObjProp] = observationsObjModel[observation].current[currentProperty];
                                         var propertyAnnotation = {
                                             name: formObjProp,
                                             displayName: observation + "_" + currentProperty,
                                             required: false,
                                             groupName: "Observations",
                                             index: index,
                                         }
                                         if (formObjProp.indexOf('healthStatus_value') > -1) {
                                             propertyAnnotation.editor = "Picker";
                                             propertyAnnotation.displayName = "État de santé";
                                             propertyAnnotation.valuesProvider = ["Bon", "Moyen", "Faible", "Mort"]
                                         }
                                         if (formObjProp.indexOf('dhp_value') > -1) {
                                             propertyAnnotation.editor = "Number";
                                             propertyAnnotation.displayName = "DHP";

                                         }
                                         if (formObjProp.indexOf('leafVolume_value') > -1) {
                                             propertyAnnotation.editor = "Picker";
                                             propertyAnnotation.displayName = "Défoliation";
                                             propertyAnnotation.valuesProvider = ["0 à 10%", "10 à 20%", "50 à 75%", "75 à 100%", "100%"]
                                         }
                                         propertyAnnotations.push(propertyAnnotation); */
                    }
                  }
                );
              }
              if (
                selectedSpecimenHistory.hasOwnProperty("observations") &&
                selectedSpecimenHistory.observations.hasOwnProperty(
                  observation
                ) &&
                selectedSpecimenHistory.observations[observation].length > 0
              ) {
                observationsObjModel[observation].history =
                  selectedSpecimenHistory.observations[observation];
              } else {
                observationsObjModel[observation].history = [];
              }
            } else {
              // this observation is not on selectedSpecimen.  Fill the ui model with an empty observation object
              if (observation === "defauts") {
                // add empty list array
                observationsObjModel[observation].list = [];

                /*  Object.keys(observationsObjModel[observation].list).forEach(function (value, index) {
                                     // add to form object
                                     var formObjProp = "defaut_" + observation + "_" + value;
                                     formattedSpecimen[formObjProp] = null;
                                     propertyAnnotations.push({
                                         name: formObjProp,
                                         displayName: observation,
                                         required: false,
                                         groupName: "Défauts observés",
                                         index: index
                                     });
                                 }) */
              } else {
                /* Object.keys(observationsObjModel[observation].current).forEach(function (currentProperty) {
                                    // add to form object
                                    var formObjProp = "observation_" + observation + "_" + currentProperty;
                                    formattedSpecimen[formObjProp] = observationsObjModel[observation].current[currentProperty];
                                    propertyAnnotations.push({
                                        name: formObjProp,
                                        displayName: observation,
                                        required: false,
                                        groupName: "Observations",
                                        index: index
                                    });
                                }) */
              }
            }
            /* propertyAnnotations.push({
                            name: observation + "-" + currentProperty,
                            displayName: observation + "-" + currentProperty,
                            required: false,
                            groupName: "Observations"
                        }); */
          });
          return observationsObjModel;
        }
      }
      /* if (specimen.groupSpecific.hasOwnProperty('villeMontreal')) {
                            groupName = 'villeMontreal';
                        } else { */

      //  }

      // get specific treeModel
      groupSpecificTreeModel = store.state.groupSpecificTreeModel;
      /*  if (groupName === "villeMontreal") {
                 groupSpecificTreeModel = {
                     comments: "",
                     observations: {
                         dhp: {
                             current: {
                                 dhpTotal: null,
                                 recordedBy: {},
                                 value: []
                             }
                         }
                     },
                     place: {
                         address: "",
                         arrondissement: ""
                     },
                     tasks: ["abattage", "vaccination"]
                 }
             } */
      var selectedSpecimen = JSON.parse(JSON.stringify(specimen));

      // add author_uid if missing
      if (!selectedSpecimen.hasOwnProperty("author_uid")) {
        selectedSpecimen.author_uid = null;
      }

      function isEmpty(obj) {
        for (var key in obj) {
          if (obj.hasOwnProperty(key)) return false;
        }
        return true;
      }

      const fetchCollaborativeForm = async () => {
        //console.log("trying to fetchCollaborativeForm");
        try {
          const collaborativeDataForCurrentSpecimenRef = query(
            collectionGroup(firestoreDb, "collaborativeSpecimenUIDs"),
            where("specimen_uid", "==", selectedSpecimen.firestoreId)
          );
          const collaborativeDataForCurrentSpecimen = await getDocs(
            collaborativeDataForCurrentSpecimenRef,
            (err) => {
              //console.log(err);
            }
          );
          collaborativeDataForCurrentSpecimen.forEach((doc) => {
            // every user collaborative data for that specimen
            //console.log(doc.id, " => ", doc.data());
            if (doc.data().reports) {
              // userReports = Object.values(doc.data().reports);
              selectedSpecimen.groupSpecific.collaborative.reports = {
                ...selectedSpecimen.groupSpecific.collaborative.reports,
                ...doc.data().reports,
              };
            }
            if (doc.data().likes) {
              selectedSpecimen.groupSpecific.collaborative.likes = {
                ...selectedSpecimen.groupSpecific.collaborative.likes,
                ...doc.data().likes,
              };
            }
            if (doc.data().likeTags) {
              selectedSpecimen.groupSpecific.collaborative.likeTags = {
                ...selectedSpecimen.groupSpecific.collaborative.likeTags,
                ...doc.data().likeTags,
              };
            }
          });
          if (
            /*  store.state.userGroupMembership !== "soverdi" && */
            store.state.userGroupMembership !== "omhm"
          ) {
            store.commit("setFormattedSpecimen", selectedSpecimen);
            store.commit("setSelectedSpecimenHistory", null);
            // resolve
          }
        } catch (e) {
          //console.log(e);
          // errors.value.push(e);
        }
      };

      if (store.state.allEssencesDictionary[selectedSpecimen.essence]) {
        selectedSpecimen.nameFr =
          store.state.allEssencesDictionary[selectedSpecimen.essence].fr.name;
        selectedSpecimen.nameLa =
          store.state.allEssencesDictionary[selectedSpecimen.essence].la;
      } else {
        if (
          specimen.plantNetIdentification &&
          specimen.plantNet.speciesWasNotFoundInBranche
        ) {
          // apply plantNet's identifiication
          selectedSpecimen.nameFr =
            (specimen.plantNet.commonNames && specimen.plantNet.commonNames.length > 0) ? specimen.plantNet.commonNames.join() : specimen.plantNet.scientificNameWithoutAuthor;
          selectedSpecimen.nameLa =
            specimen.plantNet.scientificNameWithoutAuthor;
        } else {
          // fallback on genus if available and add (sp)
          if (
            store.state.allEssencesDictionary[
            selectedSpecimen.essence.slice(0, 2)
            ]
          ) {
            selectedSpecimen.nameFr =
              store.state.allEssencesDictionary[
                selectedSpecimen.essence.slice(0, 2)
              ].fr.name + " (sp)";
            selectedSpecimen.nameLa =
              store.state.allEssencesDictionary[
                selectedSpecimen.essence.slice(0, 2)
              ].la + " (sp)";
          } else {
            // set as no avail
            selectedSpecimen.nameFr = "N/A";
            selectedSpecimen.nameLa = "N/A";
          }
        }
      }

      groupName = store.state.userGroupMembership;

      selectedSpecimen.groupSpecific.collaborative = JSON.parse(
        JSON.stringify(store.state.collaborativeFormObjModel.collaborative)
      );
      //     selectedSpecimen.groupSpecific[groupName] = store.state.collaborativeFormObjModel.collaborative;
      fetchCollaborativeForm().then(() => {
        //console.log("fetchCollaborativeForm done");

        /*  collaborativeDataForCurrentSpecimen.then((res)=>{
                     //console.log(res);
                     res.forEach((doc) => {
                         //console.log(doc.id, ' => ', doc.data());
                     });
                 }) */
        /* const collaborativeDataRef = doc(firestoreDb, "collaborative" + selectedSpecimen.firestoreId + "/contributions");
                const collaborativeData = firebaseService().getCollectionDoc({ docRef: collaborativeDataRef });
                collaborativeData.then((res) => {
                    //console.log("res for collaborativeData");
                    //console.log(res);
                    if (!res) {
                        // no collaborative data found for this specimen, set empty model
                        ///     selectedSpecimen.groupSpecific[groupName] = store.state.groupSpecificTreeModel.collaborative;
                    } else {
                        selectedSpecimen.groupSpecific[groupName] = res;
                    }
                }) */

        //  //console.log(selectedSpecimen.groupSpecific);

        /* if (!selectedSpecimen.groupSpecific.hasOwnProperty("villeMontreal") && selectedSpecimen.groupSpecific.hasOwnProperty(groupName) && isEmpty(selectedSpecimen.groupSpecific[groupName].comments)) {
                    // Object is empty 
                    selectedSpecimen.groupSpecific[groupName].comments = "";
    
                } */

        /*             //console.log("step1");
                
                
                            selectedSpecimen.nameFr = store.state.allEssencesDictionary[
                                selectedSpecimen.essence
                            ] ?
                                store.state.allEssencesDictionary[
                                    selectedSpecimen.essence
                                ].fr.name :
                                "N/A";
                            selectedSpecimen.nameLa = store.state.allEssencesDictionary[
                                selectedSpecimen.essence
                            ] ?
                                store.state.allEssencesDictionary[
                                    selectedSpecimen.essence
                                ].la :
                                "N/A";
                            //console.log("selectedSpecimen.photos")
                            //console.log(selectedSpecimen.photos)
                            if (store.state.userGroupMembership === 'omhm') {
                                //console.log("selectedSpecimen.place")
                                // //console.log(selectedSpecimen.groupSpecific[store.state.userGroupMembership].place)
                            } */
        /*    function returnEssenceName(essenceCode) {
                       //console.log("ESSENCES NAMES!!!!")
                       return store.state.allEssencesDictionary[essenceCode].fr.name;
                   }
    
                   class MovieConverter {
    
                       constructor(_movies) {}
    
                       convertFrom(id) {
                           //console.log(_movies);
                           return this._movies.filter((movie) => movie.code === id)[0].la;
                       }
    
                       convertTo(name) {
                           //console.log(_movies);
                           return this._movies.filter((movie) => movie.la === name)[0].code;
                       }
                   } */

        //   formattedSpecimen.name_editor = null;
        /*  //console.log(store.state.allEssencesDictionary);
                 //console.log(new MovieConverter(store.state.allEssencesDictionaryAsArray));
                 propertyAnnotations.push({
                     name: "name",
                     displayName: "edit species",
                     required: false,
                     //groupName: "Général",
                     index: 0,
                     editor: "Picker",
                     valuesProvider: store.state.allEssencesDictionaryAsArray.map(function (essence) {
                         return essence.fr.name
                     }),
                     converter: new MovieConverter(store.state.allEssencesDictionaryAsArray)
                 });
                 propertyAnnotations.push({
                     name: "name",
                     displayName: "Classification",
                     required: false,
                     //groupName: "Général",
                     index: 0,
                     //  editor: "Picker",
                     readOnly: true
                     // converter: returnEssenceName(index)
                 });
                 formattedSpecimen.essence = selectedSpecimen.essence;
                 propertyAnnotations.push({
                     name: "essence",
                     displayName: "Code",
                     required: false,
                     readOnly: true,
                     //groupName: "Général",
                     index: 1
                 });
                 formattedSpecimen._id = selectedSpecimen._id;
                 propertyAnnotations.push({
                     name: "_id",
                     displayName: "ID",
                     required: false,
                     readOnly: true,
                     //groupName: "Général",
                     index: 2
                 });
                 formattedSpecimen.owner = selectedSpecimen.owner;
                 propertyAnnotations.push({
                     name: "owner",
                     displayName: "Identifié par",
                     required: false,
                     readOnly: true,
                     //groupName: "Général",
                     index: 3
                 });
                 formattedSpecimen.plant_date = selectedSpecimen.plant_date;
                 propertyAnnotations.push({
                     name: "plant_date",
                     displayName: "Identifié le",
                     required: false,
                     readOnly: true,
                     //groupName: "Général",
                     index: 4
                 });
                 formattedSpecimen.photos = selectedSpecimen.photos;
                 propertyAnnotations.push({
                     name: "photos",
                     displayName: "Photos",
                     required: false,
                     readOnly: true,
                     //groupName: "Général",
                     index: 5
                 }); */

        //selectedSpecimen.groupSpecific = typeof selectedSpecimen.groupSpecific === "string" ? JSON.parse(selectedSpecimen.groupSpecific) : selectedSpecimen.groupSpecific;

        //       //console.log("before checking for group match");
        //       //console.log(JSON.stringify(selectedSpecimen.owning_group));
        /* if (!selectedSpecimen.owning_group.hasOwnProperty(store.state.userGroupMembership)) {
                    //console.log("this specimen does not belong to the user's group");
                    // use singleUser form
                    selectedSpecimen.groupSpecific.singleUser.observations = makeObservations(selectedSpecimen);
                    selectedSpecimen.groupSpecific.singleUser.tasks = makeTasks(selectedSpecimen);
                    // no need to format group specific properties for specimen form
                    store.commit("setFormattedSpecimen", selectedSpecimen);
                    //  store.commit("setSelectedSpecimenHistory", selectedSpecimenHistory);
                    resolve();
                } else { */

        // check if theres an history to fetch
        if (
          (selectedSpecimen.owning_group != "singleUser" &&
            selectedSpecimen.owning_group == groupName) ||
          (selectedSpecimen.owning_group == "singleUser" &&
            selectedSpecimen.author_uid == store.state.user.uid)
        ) {
          //console.log("checking for history");
          //console.log(JSON.stringify(selectedSpecimen._id));
          historyCollection = "history_" + groupName + "2";
          /* if (selectedSpecimen.groupSpecific.hasOwnProperty('villeMontreal')) {
                    //console.log("villeMontreal!");
                    historyCollection = 'history_villeMontreal';
                } */
          const specimenHistoryRef = doc(
            firestoreDb,
            historyCollection,
            selectedSpecimen._id
          );
          const specimenHistory = getDoc(specimenHistoryRef);
          specimenHistory
            .then((res) => {
              if (res.exists()) {
                //console.log("Document data:", res.data());
                selectedSpecimenHistory = res.data();
                //console.log(JSON.stringify(selectedSpecimen.groupSpecific));
                selectedSpecimenHistory.firestoreId = res.id;
                //console.log("specimen history: " + JSON.stringify(selectedSpecimenHistory));
                /*  if (selectedSpecimen.groupSpecific.hasOwnProperty('villeMontreal')) {
                             selectedSpecimen.groupSpecific.villeMontreal.observations = makeObservations(selectedSpecimen);
                             //    selectedSpecimen.groupSpecific.villeMontreal.tasks = makeTasks(selectedSpecimen);
                         } else { */
                selectedSpecimen.groupSpecific[groupName].observations =
                  makeObservations(selectedSpecimen);
                selectedSpecimen.groupSpecific[groupName].tasks =
                  makeTasks(selectedSpecimen);
                //   }
                //console.log("observations and tasks donee");
                store.commit("setFormattedSpecimen", selectedSpecimen);
                store.commit(
                  "setSelectedSpecimenHistory",
                  selectedSpecimenHistory
                );
                resolve();
              } else {
                // doc.data() will be undefined in this case
                //console.log("No such document!");
                //console.log("Could not get specimen history. Returning specimen with an empty history.");
                //console.log(selectedSpecimen);
                //console.log("observations and tasks donee no history");

                selectedSpecimen.groupSpecific[groupName].observations =
                  makeObservations(selectedSpecimen);
                selectedSpecimen.groupSpecific[groupName].tasks =
                  makeTasks(selectedSpecimen);
                store.commit("setFormattedSpecimen", selectedSpecimen);
                store.commit(
                  "setSelectedSpecimenHistory",
                  selectedSpecimenHistory
                );
                resolve();
              }
            })
            .catch((err) => {
              //console.log(err);
            });
        } else {
          store.commit("setFormattedSpecimen", selectedSpecimen);
          resolve();
        }
        //  }
      });
    });
  },
  formatSpecimenForServer(formattedSpecimen, collaborativeForm) {
    //console.log("fetchedSpecimen");
    //console.log(store.state.fetchedSpecimen);
    //console.log("formattedSpecimen");
    //console.log(formattedSpecimen);
    //console.log("selectedSpecimenHistory");
    //console.log(store.state.selectedSpecimenHistory);
    //console.log("store.state.currentPosition ");
    //console.log(store.state.currentPosition);

    if (!formattedSpecimen) {
      formattedSpecimen = store.state.formattedSpecimen;
    }

    return new Promise(function (resolve, reject) {
      var specimenCollection;

      //console.log("formatSpecimenForServer function accessed in services ");
      //console.log("step1");
      //console.log(store.state.fetchedSpecimen);
      var specimenObjReadyToPutOnServer = {};
      if (formattedSpecimen.groupSpecific.collaborative) {
        // set collaborative data
        specimenObjReadyToPutOnServer.reformated = formattedSpecimen;
      }

      if (
        formattedSpecimen.owning_group == groupName &&
        (formattedSpecimen.owning_group != "singleUser" ||
          formattedSpecimen.author_uid == store.state.user.uid)
      ) {
        // user has ownership over that specimen
        var returnedObj = formatObservations(
          formattedSpecimen,
          formattedSpecimen.groupSpecific[groupName],
          store.state.selectedSpecimenHistory
        );
        //console.log(returnedObj.selectedSpecimen);
        //console.log(returnedObj.selectedSpecimenHistory);
        var returnedObj2 = formatTasks(
          formattedSpecimen.groupSpecific[groupName],
          returnedObj
        );
        //console.log("returnedObj2");
        //console.log(returnedObj2);
        specimenObjReadyToPutOnServer = formatFullSpecimen(
          returnedObj2,
          formattedSpecimen
        );
      }

      //console.log(specimenObjReadyToPutOnServer);

      var addNewSpecimensOnRemote = function (newSpecimensBatch, history) {
        //console.log("addNewSpecimensOnRemote from SpecimenService");
        var doneCounter = 0;

        var specimensUIDCounterRef = doc(
          firestoreDb,
          "counters",
          "specimensUID"
        );
        //console.log("specimensUIDCounterRef ");
        //console.log(specimensUIDCounterRef);
        //console.log("newSpecimensBatch");
        //console.log(newSpecimensBatch);

        function segmentDate(isoDate) {
          // reformat date for firesore querying
          var dateOBJ = new Date(isoDate);
          //   //console.log(dateOBJ);
          var segmentedDate = {
            year: dateOBJ.getFullYear(),
            month: dateOBJ.getMonth(),
            date: dateOBJ.getDate(),
          };
          return segmentedDate;
        }

        function segmentSpecies(essenceCode) {
          var classification = {
            genus:
              essenceCode.slice(0, 6) === "ARBU--"
                ? essenceCode.slice(0, 8)
                : essenceCode.slice(0, 2),
          };
          if (essenceCode.length >= 4) {
            classification.species =
              essenceCode.slice(0, 6) === "ARBU--"
                ? essenceCode.slice(0, 10)
                : essenceCode.slice(0, 4);
          }
          if (essenceCode.length >= 6) {
            classification.cultivar =
              essenceCode.slice(0, 6) === "ARBU--"
                ? essenceCode.slice(0, 12)
                : essenceCode.slice(0, 6);
          }
          return classification;
        }

        const specimensUIDCounter = getDoc(specimensUIDCounterRef);

        specimensUIDCounter.then(function (specimensUIDCounter) {
          if (!specimensUIDCounter.exists) {
            throw "specimensUIDCounter does not exist!";
          }
          var newSpecimensUIDCounter = specimensUIDCounter.data().d.counter + 1;
          //console.log("newSpecimensUIDCounter");
          //console.log(newSpecimensUIDCounter);

          newSpecimensBatch.forEach(function (newSpecimenProperties) {
            var geoHash = encodeGeohash.encodeGeohash({
              latitude: store.state.currentPosition.coords.latitude,
              longitude: store.state.currentPosition.coords.longitude,
            });
            //console.log(GeoPoint);
            // put missing basic properties in properly formatted object
            var newSpecimen = {
              coordinates: new GeoPoint(
                store.state.currentPosition.coords.latitude,
                store.state.currentPosition.coords.longitude
              ),
              g: {
                geohash: geoHash,
                geopoint: new GeoPoint(
                  store.state.currentPosition.coords.latitude,
                  store.state.currentPosition.coords.longitude
                ),
              },
              geojsonFeature: {
                type: "Feature",
                properties: newSpecimenProperties,
                geometry: {
                  coordinates: [
                    store.state.currentPosition.coords.longitude,
                    store.state.currentPosition.coords.latitude,
                  ],
                  type: "Point",
                },
              },
            };

            // add unique ID
            newSpecimen.geojsonFeature.properties._id =
              newSpecimensUIDCounter.toString();
            // add author uid to determine ownership
            newSpecimen.geojsonFeature.properties.author_uid =
              store.state.user.uid;
            if (newSpecimen.geojsonFeature.properties.plantNet && newSpecimen.geojsonFeature.properties.plantNet.speciesWasNotFoundInBranche) {
              // use plantNet infos
              newSpecimen.geojsonFeature.properties.classification = {
                genus: newSpecimen.geojsonFeature.properties.plantNet.genus,
                species: newSpecimen.geojsonFeature.properties.plantNet.scientificNameWithoutAuthor
              }
            } else {
              newSpecimen.geojsonFeature.properties.classification =
                segmentSpecies(newSpecimen.geojsonFeature.properties.essence);
            }
            newSpecimen.geojsonFeature.properties.plant_date =
              new Date().toISOString();
            newSpecimen.geojsonFeature.properties.createdOn = segmentDate(
              newSpecimen.geojsonFeature.properties.plant_date
            );
            newSpecimen.geojsonFeature.properties.owning_group =
              store.state.userGroupMembership;
            newSpecimen.geojsonFeature.properties.type =
              newSpecimen.geojsonFeature.properties.essence.indexOf("ARBU--") >
                -1 ||
                newSpecimen.geojsonFeature.properties.essence.indexOf(
                  "PLATEBANDE"
                ) > -1
                ? "shrub"
                : "tree";

            //console.log("newSpecimen");
            //console.log(JSON.stringify(newSpecimen));
            // remove firestoreId property because its a new specimen, it cannot have one yet
            delete newSpecimen.geojsonFeature.properties.firestoreId;

            const currentDataOwning_group =
              newSpecimen.geojsonFeature.properties.owning_group;

            var newSpecimenRef = doc(
              collection(
                firestoreDb,
                "groups/" + currentDataOwning_group + "/specimens"
              )
            );

            // add firestore Id to specimen
            newSpecimen.geojsonFeature.properties.firestoreId =
              newSpecimenRef.id;
            var batch = writeBatch(firestoreDb);

            batch.set(newSpecimenRef, newSpecimen);
            batch.update(specimensUIDCounterRef, {
              d: {
                counter: newSpecimensUIDCounter,
              },
            });

            // history
            //console.log("trying to set history for new specimen");
            var newHistory = createHistoryForNewSpecimen(newSpecimen);

            /*                         var historyCollection = firestoreDb.collection("history_" + groupName + "2");
                                                //console.log("trying to create history2");
                        
                                                var specimenHistoryRef = historyCollection.doc(newSpecimen.geojsonFeature.properties._id); */

            var specimenHistoryRef = doc(
              firestoreDb,
              "history_" + groupName + "2",
              newSpecimen.geojsonFeature.properties._id
            );

            batch.set(specimenHistoryRef, newHistory);
            //console.log("trying to create history 4");
            //console.log("specimenHistoryRef");
            //console.log(specimenHistoryRef);

            // Commit the batch
            batch.commit().then(
              function () {
                // put new specimen on map
                const fetchedNewSpecimen = firebaseService().getCollectionDoc({
                  docRef: newSpecimenRef,
                });
                fetchedNewSpecimen.then((res) => {
                  //console.log("res for fetchedNewSpecimen");
                  //console.log(res);
                  // check if there are specimens on the map already
                  if (
                    !store.state.mapboxMap.getLayer("fetchedSpecimensLayer")
                  ) {
                    // no specimens layer found.
                  } else {
                    // add specimen to current selection
                    store.state.fetchedSpecimensObj[
                      res.geojsonFeature.properties.firestoreId
                    ] = res;
                    const specimen =
                      firebaseService().addMissingPropertiesToFetchedSpecimen(
                        res
                      );
                    store.state.fetchedSpecimensAsFeatureCollection.features.push(
                      specimen
                    );
                    mapService.putSpecimensOnMap(
                      store.state.fetchedSpecimensAsFeatureCollection,
                      true
                    );
                    mapService.selectSpecimenOnMap(res.geojsonFeature, false);
                    if (!collaborativeForm) {
                      store.commit("setCurrentSegment", null);
                      const modalRef = document.querySelector(
                        ".modal-content-specimen-details"
                      );
                      modalRef.setCurrentBreakpoint(0.001);
                      store.commit("setShowNewSpecimenModal", false);
                      store.commit("setShowRefreshMap", true);
                      store.commit(
                        "setActivatedTabQuerySelector",
                        ".fetch-specimens"
                      );
                    }
                  }
                  resolve("adding new specimen on remote done!");
                });
                /* specimensUIDCounterRef.get().then(function (specimensUIDCounter) {
                                if (!specimensUIDCounter.exists) {
                                    throw "specimensUIDCounter does not exist!";
                                }
                                //     var newSpecimensUIDCounter = specimensUIDCounter.data().d.counter + 1;
                                //console.log("specimensUIDCounter");
                                //console.log(specimensUIDCounter.data().d.counter);
                                store.commit("setNewSpecimenCounterId", specimensUIDCounter.data().d.counter.toString());
                            }) */
              },
              function (error) {
                //console.log("saving new specimen FAILED");
                //console.log(error);
                store.commit("setShowNewSpecimenModal", false);
                store.commit("setShowRefreshMap", true);
                store.commit(
                  "setActivatedTabQuerySelector",
                  ".fetch-specimens"
                );
                alert("Échec de la sauvegarde du nouveau spécimen.");
              }
            );
          });
        });
      };

      var repositionSpecimen = function () {
        //console.log("reposition function reached in specimen service");
        var specimenRef = specimenCollection.doc(formattedSpecimen.firestoreId);

        var geoHash = encodeGeohash.encodeGeohash({
          latitude: store.state.currentUserLocation[1],
          longitude: store.state.currentUserLocation[0],
        });

        // Get a new write batch
        var batch = firestoreDb.batch();
        batch.update(specimenRef, {
          "d.coordinates": new firestoreDb.GeoPoint(
            store.state.currentUserLocation[1],
            store.state.currentUserLocation[0]
          ),
          "d.geojsonFeature.geometry.coordinates":
            store.state.currentUserLocation,
          g: geoHash,
          g5: geoHash.slice(0, 5),
          g6: geoHash.slice(0, 6),
          g7: geoHash.slice(0, 7),
          g8: geoHash.slice(0, 8),
          l: new firestoreDb.GeoPoint(
            store.state.currentUserLocation[1],
            store.state.currentUserLocation[0]
          ),
        });
        // Commit the batch
        batch.commit().then(
          function () {
            //console.log("batch commit done, specimen location updated");
            resolve();
            /* {
                                                properties: currentData,
                                                history: history
                                            } */
          },
          function (error) {
            //console.log("FAILED");
            //console.log(error);
          }
        );
      };

      // put onto firestore
      var batchUpdateSpecimenInfoOnRemote = function (currentData, history) {
        if (history && history.hasOwnProperty("doneSummary")) {
          var doneSummaryKeys = Object.keys(history.doneSummary);
          doneSummaryKeys.forEach(function (key) {
            if (history.doneSummary[key].hasOwnProperty("dates")) {
              delete history.doneSummary[key].dates;
            }
          });
        }
        //console.log(JSON.stringify(history));
        //console.log("trying to batch update");
        //console.log("currentData.firestoreId");
        //console.log(currentData);
        //console.log(store.state.queryOptions.collectionName);
        const currentDataOwning_group = currentData.owning_group;
        let collectionPath;
        let specimenRef;
        // Get a new write batch
        var batch = writeBatch(firestoreDb);

        if (
          currentDataOwning_group == "singleUser" &&
          currentData.author_uid == store.state.user.uid && currentData.essence != 'deleted'
        ) {
          // specimen created by current user.  Full write will be allowed
          collectionPath = "groups/" + currentDataOwning_group + "/specimens";
          specimenRef = doc(
            firestoreDb,
            collectionPath,
            currentData.firestoreId
          );
          batch.update(specimenRef, {
            "geojsonFeature.properties": currentData,
          });
        } else if (
          currentDataOwning_group != "singleUser" &&
          currentDataOwning_group == store.state.userGroupMembership && currentData.essence != 'deleted'
        ) {
          // specimen belongs to the user group. Full write will be allowed
          collectionPath = "groups/" + currentDataOwning_group + "/specimens";
          specimenRef = doc(
            firestoreDb,
            collectionPath,
            currentData.firestoreId
          );
          batch.update(specimenRef, {
            "geojsonFeature.properties": currentData,
          });
        }

        if (currentData.groupSpecific.collaborative) {
          // Write groupSpecific.collaborative data in the collaborative collection, using user's uid as keyname to allow for user based edition privileges and multiple users entries on the same specimen
          // start by creating a collaborativeDocument for the specimen if it doesnt exist yet
          collectionPath =
            "collaborative/" +
            store.state.user.uid +
            "/collaborativeSpecimenUIDs";

          specimenRef = doc(
            firestoreDb,
            collectionPath,
            currentData.firestoreId
          );
          const collaborativeModel = JSON.parse(
            JSON.stringify(store.state.collaborativeFormObjModel.collaborative)
          );
          // add original tree ids to be able to retrieve it using a groupCollection query
          collaborativeModel.specimen_uid = currentData.firestoreId;
          collaborativeModel.specimen_id = currentData._id;
          // add author_uid to be able to retrieve all collaborative data for a user over a collectionGroup (in user stats in login page)
          collaborativeModel.author_uid = store.state.user.uid;
          console.log(store.state.fetchedSpecimensObj[
            store.state.chosenSpecimenMarkerFirestoreId
          ]);
          //add geospatial data to be able to make a query based on user location
          collaborativeModel.g =
            store.state.fetchedSpecimensObj[
              store.state.chosenSpecimenMarkerFirestoreId
            ].g;

          // find back user's own data in arrays
          Object.keys(collaborativeModel).forEach(
            (collaborativeSectionKeyname) => {
              if (
                collaborativeSectionKeyname != "specimen_uid" &&
                collaborativeSectionKeyname != "specimen_id" &&
                collaborativeSectionKeyname != "author_uid" &&
                collaborativeSectionKeyname != "g"
              ) {
                const collaborativeType = Object.values(
                  currentData.groupSpecific.collaborative[
                  collaborativeSectionKeyname
                  ]
                );
                if (!collaborativeType.length) {
                  // no data for this type
                  // set a boolean property for query purposes
                  collaborativeModel[
                    "has_" + collaborativeSectionKeyname
                  ] = false;
                } else {
                  collaborativeModel[
                    "has_" + collaborativeSectionKeyname
                  ] = true;
                  collaborativeType.forEach((item) => {
                    if (item.author_uid == store.state.user.uid) {
                      if (item.like_id) {
                        collaborativeModel[collaborativeSectionKeyname][
                          item.like_id
                        ] = item;
                      } else if (item.report_id) {
                        collaborativeModel[collaborativeSectionKeyname][
                          item.report_id
                        ] = item;
                      } else {
                        collaborativeModel[collaborativeSectionKeyname][
                          item.likeTag_id
                        ] = item;
                      }
                    }
                  });
                }
              }
            }
          );
          //console.log("collaborativeModel " + collaborativeModel);
          //     const data = currentData.groupSpecific.collaborative[store.state.user.uid];
          batch.set(specimenRef, collaborativeModel, { merge: false });
        }

        // history
        if (history) {
          //console.log("trying to batch update history");
          // update amountOfDones
          if (!history.hasOwnProperty("doneSummary")) {
            history.doneSummary = {};
          }
          if (history.tasks) {
            var taskNames = Object.keys(history.tasks);

            taskNames.forEach(function (key) {
              //console.log("calculate amount of dones");
              //console.log(key);

              if (
                !history.doneSummary.hasOwnProperty(
                  removeSpecialCharacters(key)
                )
              ) {
                history.doneSummary[removeSpecialCharacters(key)] = {
                  amountOfDones: 0,
                };
              }
              var taskDoneCount = 0;

              var tempTasksNames = [...history.tasks[key]];
              tempTasksNames.reverse();
              // calculate amount of dones
              tempTasksNames.forEach(function (item) {
                //console.log("trying to batch update history3");
                //console.log(key);
                //console.log(item);
                if (item.dateDone) {
                  taskDoneCount++;
                  //console.log("trying to batch update history4");

                  history.doneSummary[removeSpecialCharacters(key)][
                    "done" + taskDoneCount
                  ] = item.dateDoneSegmented;
                  //console.log("trying to batch  update history5");
                }
              });

              //   history.tasks[key].reverse();

              history.doneSummary[removeSpecialCharacters(key)].amountOfDones =
                taskDoneCount;

              //console.log("trying to batch update history6");
            });
          }
          /* var historyCollection = collection(firestoreDb, "history_" + groupName + "2");
                    //console.log("trying to batch update history2"); */

          var specimenHistoryRef = doc(
            firestoreDb,
            "history_" + groupName + "2",
            history._id
          );

          //console.log("trying to batch update history3");

          batch.set(specimenHistoryRef, history, { merge: true });
          //console.log("trying to batch update history4");
        } else {
          history = null;
        }

        //console.log(JSON.stringify(store.state.user));

        // Commit the batch
        batch.commit().then(
          function () {
            //console.log("batch commit  done");
            // update current specimen in ui and local specimens list
            // update color in case the specimen went from a species to another or from alive to dead
            currentData.iconColor = specimensColor.setSpecimenColor({
              properties: currentData,
            });
            store.state.fetchedSpecimensObj[
              currentData.firestoreId
            ].geojsonFeature.properties = currentData;
            store.commit(
              "setFetchedSpecimen",
              store.state.fetchedSpecimensObj[
              store.state.chosenSpecimenMarkerFirestoreId
              ]
            );
            store
              .dispatch(
                "formatFetchedSpecimen",
                store.state.fetchedSpecimensObj[
                  store.state.chosenSpecimenMarkerFirestoreId
                ].geojsonFeature.properties
              )
              .then(function () {
                // get specimen object model
                //console.log(store.state.formattedSpecimen);
                //console.log(store.state.chosenSpecimenMarkerFirestoreId);
                // close specimen sheet modal
                let currentSpecimenIndex = null;
                if (!collaborativeForm) {
                  /*       store.commit("setCurrentSegment", null);
                        const modalRef = document.querySelector(
                          ".modal-content-specimen-details"
                        );
                        modalRef.setCurrentBreakpoint(0.001); */
                  for (
                    let index = 0;
                    index <
                    store.state.fetchedSpecimensAsFeatureCollection.features
                      .length;
                    index++
                  ) {
                    if (
                      store.state.fetchedSpecimensAsFeatureCollection.features[
                        index
                      ].properties.firestoreId ==
                      store.state.formattedSpecimen.firestoreId
                    ) {
                      store.state.fetchedSpecimensAsFeatureCollection.features[
                        index
                      ].properties = store.state.formattedSpecimen;
                      currentSpecimenIndex = index;
                    }
                  }
                  /*  mapService.putSpecimensOnMap(
                    store.state.fetchedSpecimensAsFeatureCollection,
                    true
                  ); */
                  mapService.selectSpecimenOnMap(
                    store.state.fetchedSpecimensAsFeatureCollection.features[
                    currentSpecimenIndex
                    ],
                    true
                  );
                }
              });
            resolve({
              properties: currentData,
              history: history,
            });
          },
          function (error) {
            //console.log("FAILED");
            //console.log(error);
            reject();
            alert(error + " Échec de la sauvegarde serveur.");
          }
        );
      };

      //console.log("store.state.repositionSpecimen  " + store.state.repositionSpecimen);
      if (specimenObjReadyToPutOnServer.reformated._id === 0) {
        //console.log("addNewSpecimensOnRemote ");
        addNewSpecimensOnRemote(
          [specimenObjReadyToPutOnServer.reformated],
          specimenObjReadyToPutOnServer.selectedSpecimenHistory
        );
      } else if (store.state.repositionSpecimen) {
        repositionSpecimen();
      } else {
        batchUpdateSpecimenInfoOnRemote(
          specimenObjReadyToPutOnServer.reformated,
          specimenObjReadyToPutOnServer.selectedSpecimenHistory
        );
      }
    });
  },
  batchDeleteSpecimenInfoOnRemote(specimen, history) {
    return new Promise(function (resolve, reject) {
      // delete specimen's pictures if there are any
      if (store.state.formattedSpecimen.photos.length > 0) {
        // remove pictures from storage
        store.state.formattedSpecimen.photos.forEach((picture) => {
          deletePicture(picture, store);
        });
      }
      // Get a new write batch
      var batch = writeBatch(firestoreDb);
      const currentDataOwning_group = specimen.owning_group;
      let collectionPath = "groups/" + currentDataOwning_group + "/specimens";
      let specimenRef = doc(
        firestoreDb,
        collectionPath,
        specimen.firestoreId
      );
      batch.delete(specimenRef);
      // history
      //console.log("trying to batch update history");
      if (!history) {
        history = {};
      }
      store.state.fetchedSpecimensObj[
        specimen.firestoreId
      ].geojsonFeature.properties = specimen;
      const fullSpecimen =
        store.state.fetchedSpecimensObj[specimen.firestoreId];

      history.deletedSpecimen = {
        archivedOn: {
          name: store.state.user.email,
          time: new Date().toISOString(),
        },
        specimen: fullSpecimen,
      };
      //console.log(history.deletedSpecimen);
      var specimenHistoryRef;
      if (history.firestoreId) {
        // the document exists in the history, use existing firestoreId
        specimenHistoryRef = doc(
          firestoreDb,
          "history_" + store.state.userGroupMembership + "2",
          history.firestoreId
        );
      } else {
        specimenHistoryRef = doc(
          firestoreDb,
          "history_" + store.state.userGroupMembership + "2",
          specimen._id
        );
      }
      //console.log("trying to batch update history3");
      batch.set(specimenHistoryRef, history, { merge: true });
      // Commit the batch
      batch.commit().then(
        function () {
          //console.log("batch commit for delete operation done");
          const firestoreId = JSON.parse(
            JSON.stringify(specimen.firestoreId)
          );


          if (
            Object.keys(
              store.state.formattedSpecimen.groupSpecific.collaborative
                .likeTags
            ).length < 1
          ) {
            // remove collaborative icon
            for (
              let index = 0;
              index < store.state.collaborativeDataQueryObj.length;
              index++
            ) {
              const collaborativeData =
                store.state.collaborativeDataQueryObj[index];
              if (
                collaborativeData.specimen_id ==
                store.state.fetchedSpecimen.geojsonFeature.properties
                  ._id &&
                collaborativeData.has_likeTags
              ) {
                console.log(collaborativeData);
                if (!collaborativeData.likeTags) {
                  collaborativeData.has_likeTags = false;
                  break;
                } else if (
                  Object.keys(collaborativeData.likeTags).length < 2
                ) {
                  collaborativeData.has_likeTags = false;
                  break;
                }
              }
            }
          }


          // remove specimen from current selection
          delete store.state.fetchedSpecimensObj[firestoreId];
          for (
            let index = 0;
            index <
            store.state.fetchedSpecimensAsFeatureCollection.features.length;
            index++
          ) {
            if (
              store.state.fetchedSpecimensAsFeatureCollection.features[index]
                .properties.firestoreId == firestoreId
            ) {
              store.state.fetchedSpecimensAsFeatureCollection.features.splice(
                index,
                1
              );
              break
            }
          }
          // remove collaborativeData, use a reverse loop because more than one item 
          store.state.collaborativeDataQueryObj
          for (
            let index = store.state.collaborativeDataQueryObj.length - 1;
            index >= 0;
            index--
          ) {
            const collaborativeData = store.state.collaborativeDataQueryObj[index];
            //console.log(store.state.fetchedSpecimensObj);
            if (!store.state.fetchedSpecimensObj[collaborativeData.specimen_uid]) {
              store.state.collaborativeDataQueryObj.splice(index, 1);
            }
          }

          mapService.putSpecimensOnMap(
            store.state.fetchedSpecimensAsFeatureCollection,
            true
          );
          store.commit("setShowNewSpecimenModal", false);
          store.commit("setShowRefreshMap", true);
          store.commit("setActivatedTabQuerySelector", ".fetch-specimens");
          mapService.deselectMarker();
          //     store.commit("setCurrentSegment", null);
          const modalRef = document.querySelector(
            ".modal-content-specimen-details"
          );
          modalRef.setCurrentBreakpoint(0.001);
          store.commit("setFormattedSpecimen", null);

          //  store.commit("setSideMenuDisabled", true);
          resolve("done!");
        },
        function (error) {
          console.log("FAILED");
          console.log(error);
        }
      );

    });
  },
};
