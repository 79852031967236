/* eslint-disable no-prototype-builtins */
<template>
  <div class="specimen-form">
    <span v-if="$store.state.formattedSpecimen._id">
      <ion-item>
        <h3>ID {{ $store.state.formattedSpecimen._id }}</h3>
      </ion-item>
      <ion-item>
        <ion-label v-if="$store.state.formattedSpecimen.groupSpecific.omhm.lastModifiedBy" class="ion-text-wrap">
          Dernière modif: <b>{{ $store.state.formattedSpecimen.groupSpecific.omhm.lastModifiedBy.time.slice(0, 10) }}</b>
          par {{ $store.state.formattedSpecimen.groupSpecific.omhm.lastModifiedBy.name }} </ion-label>
        <ion-label v-else>Dernière modif: N/A</ion-label>
      </ion-item>
      <ion-item>
<!--         <ion-label>Déplacer spécimen</ion-label> -->
        <ion-toggle @click="activateDraggingMode()" v-model="$store.state.draggingModeActivated">Déplacer spécimen</ion-toggle>
      </ion-item>
      <ion-item color="danger" v-if="$store.state.draggingWarning">{{
          $store.state.draggingWarning
      }}</ion-item>
    </span>

    <photos-component></photos-component>
    <ion-item-divider>
      <ion-label class="form-divider-title">Classification</ion-label>
    </ion-item-divider>
    <search-species-component usedIn="specimenForm"></search-species-component>
    <ion-item>
      <!-- <ion-label>Plantation</ion-label> -->
      <ion-toggle name="observations.plantation" v-model="$store.state.formattedSpecimen.new_tree">Plantation</ion-toggle>
    </ion-item>
    <ion-item v-show="$store.state.formattedSpecimen.new_tree">
      <!-- <ion-label slot="end">Tag #</ion-label> -->
      <ion-input label="Tag #" slot="end" v-model="$store.state.formattedSpecimen.groupSpecific.omhm.new_treeTag" type="number">
      </ion-input>
    </ion-item>

    <habitations-component></habitations-component>
    <observations-component :title="'test-title'" groupSpecific="omhm"></observations-component>
    <ion-item-divider>
      <ion-label class="form-divider-title">Commentaires</ion-label>
    </ion-item-divider>
    <ion-item>
      <ion-textarea auto-grow="true" v-model="$store.state.formattedSpecimen.groupSpecific.omhm.comments">
      </ion-textarea>
    </ion-item>
    <defauts-component ></defauts-component>
    <tasks-component :title="'test-title'"></tasks-component>
    <ion-item>
      <ion-button @click="checkBeforeSaving()">Sauvegarder</ion-button>
      <ion-button @click="confirmDelete()" fill="outline" color="danger" slot="end">Effacer</ion-button>
    </ion-item>
  </div>

  <ion-modal :is-open="$store.state.showDateTime" @didDismiss="dateTimeModalDismissed">
    <ion-content>
      <ion-header>
        <ion-toolbar>
          <ion-title>{{ $store.state.editingTask.name }}
            <b>{{ $store.state.editingTask.prop }} </b>
          </ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-datetime @ionChange="setDateTime" size="cover" showDefaultButtons="true" showClearButton="true"
        presentation="date" locale="fr-CA">
      </ion-datetime>
    </ion-content>
  </ion-modal>

  <ion-action-sheet :is-open="showCauseOfDeathOptions" header="Veuillez préciser la cause de la mortalité svp."
    css-class="action-sheet" :buttons="codOptions" @didDismiss="showCauseOfDeathOptions = false">
  </ion-action-sheet>
</template>

<script>
import firebaseService from "@/services/firebaseService";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonLabel,
  IonItem,
  IonItemDivider,
  IonList,
  IonModal,
  IonDatetime,
  alertController,
  IonToggle,
  IonButton,
  IonTextarea,
  IonActionSheet,
  IonInput,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import ObservationsComponent from "@/components/ObservationsComponent";
import TasksComponent from "@/components/TasksComponent";
import { useStore } from "vuex";
import DefautsComponent from "./DefautsComponent.vue";
import HabitationsComponent from "./HabitationsComponent.vue";
import PhotosComponent from "./PhotosComponent.vue";
import searchSpeciesComponent from "./searchSpeciesComponent.vue";
import mapService from "@/services/mapService";
let handleInput;
export default defineComponent({
  name: "specimenPageOmhm",
  inheritAttrs: false,
  props: {
    name: String,
    mapTypeId: String,
    zoom: Number,
    createOrModify: String,
  },
  setup(props) {
    const store = useStore();
    const { errors, loading } =
      firebaseService();
    const specimen = ref();
    const formType = ref();
    const showCauseOfDeathOptions = ref(false);
    const codOptions = [
      {
        text: "sécheresse",
        handler: () => {
        //console.log("sécheresse clicked");
          addCauseOfDeath("sécheresse");
        },
      },
      {
        text: "déneigement",
        handler: () => {
        //console.log("déneigement clicked");
          addCauseOfDeath("déneigement");
        },
      },
      {
        text: "tondeuse ou coupe bordure",
        handler: () => {
        //console.log("tondeuse clicked");
          addCauseOfDeath("tondeuse ou coupe bordure");
        },
      },
      {
        text: "vandalisme ou vol",
        handler: () => {
        //console.log("vandalisme clicked");
          addCauseOfDeath("vandalisme ou vol");
        },
      },
      {
        text: "animaux",
        handler: () => {
        //console.log("animaux clicked");
          addCauseOfDeath("animaux");
        },
      },
      {
        text: "inconnue",
        handler: () => {
        //console.log("inconnue clicked");
          addCauseOfDeath("inconnue");
        },
      },
      {
        text: "Cancel",
        role: "cancel",
        handler: () => {
        //console.log("Cancel clicked");
        },
      },
    ];
    let formattedSpecimen;
    if (store.state.createOrModifySpecimen == "create") {
      formType.value = "create";
      //console.log(formType.value);
      store.dispatch("formatNewSpecimen").then(function () {
        // get specimen object model
        //console.log(store.state.formattedSpecimen);
        formattedSpecimen = store.state.formattedSpecimen;
      });
    } else {
      formType.value = "modify";
      //console.log("modify specimen");
      /* store.dispatch("formatFetchedSpecimen",specimen.value).then(function () {
        // get specimen object model
        //console.log(store.state.formattedSpecimen);
        formattedSpecimen = store.state.formattedSpecimen;
      }); */
    }

    const setPlantation = (ev) => {
      //console.log(ev.detail.checked);
      formattedSpecimen.new_tree = ev.detail.checked;
      store.commit("setFormattedSpecimen", formattedSpecimen);
    };

    /*     onMounted(() => {
      
      watch(
        () => store.state.formattedSpecimen,
        () => {
          //console.log("store.state.formattedSpecimen changed");
        }
      );
    }); */

    const dateTimeModalDismissed = () => {
      //console.log("dateTimeModalDismissed");
      store.commit("setShowDateTime", false);
    };
    const setDateTime = (ev) => {
      //console.log(ev.detail.value);
      // set on task
      const array =
        store.state.formattedSpecimen.groupSpecific[
          store.state.userGroupMembership
        ].tasks;
      array.forEach((task) => {
        if (task.name === store.state.editingTask.name) {
          const prop = store.state.editingTask.prop;
          // only one can be set at a time
          if (prop == "dateToDo") {
            task.suivi.dateDone = null;
            task.suivi[prop] = ev.detail.value;
          } else {
            task.suivi.dateToDo = null;
            task.suivi[prop] = ev.detail.value;
          }
        }
      });
    };
    let validationErrors = [];

    const checkForm = (e) => {
    //console.log("checking form");
      validationErrors = [];
      formattedSpecimen = store.state.formattedSpecimen;
      // eslint-disable-next-line no-prototype-builtins
      if (!formattedSpecimen.groupSpecific.hasOwnProperty("omhm")) {
        if (formattedSpecimen.essence) {
          return true;
        } else {
          if (!formattedSpecimen.essence) {
            validationErrors.push("Espèce");
          }
        }
      } else {
        if (
          formattedSpecimen.essence &&
          formattedSpecimen.photos.length > 0 &&
          formattedSpecimen.photos[0]._id &&
          formattedSpecimen.groupSpecific[store.state.userGroupMembership]
            .observations.healthStatus.current.value &&
          formattedSpecimen.groupSpecific[store.state.userGroupMembership]
            .observations.leafVolume.current.value &&
          formattedSpecimen.groupSpecific[store.state.userGroupMembership]
            .observations.dhp.current.value.length > 0
        ) {
          return true;
        }

        if (!formattedSpecimen.essence) {
          validationErrors.push("Espèce");
        }
        if (
          !formattedSpecimen.groupSpecific[store.state.userGroupMembership]
            .observations.healthStatus.current.value
        ) {
          validationErrors.push("État de santé");
        }
        if (
          !formattedSpecimen.groupSpecific[store.state.userGroupMembership]
            .observations.leafVolume.current.value
        ) {
          validationErrors.push("Défoliation");
        }
        if (
          formattedSpecimen.groupSpecific[store.state.userGroupMembership]
            .observations.dhp.current.value.length === 0
        ) {
          validationErrors.push("DHP");
        }
        if (!formattedSpecimen.photos.length > 0) {
          validationErrors.push("Photo");
        }
      }
    };
    const addCauseOfDeath = (cod) => {
      // add cause of death to healthStatus property
      formattedSpecimen.groupSpecific.omhm.observations.healthStatus.current.cod =
        cod;

      // store.commit("setSavedSpecimen", formattedSpecimen);
      store.commit("setSavedSpecimen", formattedSpecimen);
      saveSpecimen();
    };

    const updateSpecimenLocation = () => {
      //
    };
    const activateDraggingMode = () => {
      mapService.draggingSpecimen(store.state.fetchedSpecimen);
    };
    const checkBeforeSaving = () => {
      function checkIfSetToDead() {
        // check if the specimen was set to dead
        if (
          formattedSpecimen.groupSpecific.omhm &&
          formattedSpecimen.groupSpecific.omhm.observations.healthStatus &&
          formattedSpecimen.groupSpecific.omhm.observations.healthStatus.current
            .value === "Mort" &&
          !formattedSpecimen.groupSpecific.omhm.observations.healthStatus
            .current.cod
        ) {
          // show cod options
          showCauseOfDeathOptions.value = true;
        } else if (
          formattedSpecimen.groupSpecific.omhm &&
          formattedSpecimen.groupSpecific.omhm.observations.healthStatus &&
          formattedSpecimen.groupSpecific.omhm.observations.healthStatus.current
            .value !== "Mort" &&
          formattedSpecimen.groupSpecific.omhm.observations.healthStatus.current
            .cod
        ) {
          // remove cod, the specimen was set back to not dead
          delete formattedSpecimen.groupSpecific.omhm.observations.healthStatus
            .current.cod;
          store.commit("setSavedSpecimen", formattedSpecimen);
          saveSpecimen();
        } else {
          store.commit("setSavedSpecimen", formattedSpecimen);
          saveSpecimen();
        }
      }
      if (checkForm()) {
        /* const tre = true;
      if (tre) { */
        //console.log("checkForm done, tryin to save");
        // warn if specimen was set to abattu
        if (
          formattedSpecimen.groupSpecific &&
          formattedSpecimen.groupSpecific.omhm &&
          formattedSpecimen.groupSpecific.omhm.tasks &&
          formattedSpecimen.groupSpecific.omhm.tasks[0] &&
          formattedSpecimen.groupSpecific.omhm.tasks[0].suivi &&
          formattedSpecimen.groupSpecific.omhm.tasks[0].suivi.dateDone
        ) {
          if (
            window.confirm(
              "Vous avez déclaré que l'abattage de cet arbre a été complété.  Si vous poursuivez, ce spécimen ne sera plus affiché sur la carte, sauf si vous effectuez une requête spécifiquement pour afficher les arbres abattus."
            )
          ) {
            checkIfSetToDead();
          } else {
            // user canceled the operation at confirm stage
            return;
          }
        } else {
          // specimen was not set as abattu
          checkIfSetToDead();
        }
      } else {
        alert(
          "Les éléments suivants sont manquants: " +
          JSON.stringify(validationErrors)
        );
      }
    };
    const saveSpecimen = () => {
      //in case we updated profile pic and returned to this page
      // formattedSpecimen.specimen_pic = store.state.profile.specimen_pic;

      store
        .dispatch("formatSpecimenForServer", formattedSpecimen)
        .then(function (result) {
        //console.log("specimen  saved on server");
        //console.log("store.state.formattedSpecimen");
        //console.log(store.state.formattedSpecimen);
          //console.log(result);
          //    loader.hide();
          store.commit("setRepositionSpecimen", false);
        })
        .catch(function (error) {
          alert("La sauvegarde n'a pas été complétée. Veuillez réessayer plus tard svp.");
          //     loader.hide();
          console.error("Error writing firestore document: ", error);
        });
      // }
    };
    const confirmDelete = async () => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Souhaitez-vous vraiment effacer ce spécimen?",
        message: "Cette action est irréversible!!!",
        buttons: [
          {
            text: "Annuler",
            role: "cancel",
            cssClass: "secondary",
            id: "cancel-button",
            handler: (blah) => {
              //console.log("Confirm Cancel:", blah);
            },
          },
          {
            text: "Effacer",
            id: "confirm-button",
            handler: () => {
              //console.log("Confirm Okay");
              deleteSpecimen();
            },
          },
        ],
      });
      return alert.present();
    };
    const deleteSpecimen = () => {
      store
        .dispatch(
          "batchDeleteSpecimenInfoOnRemote",
          store.state.formattedSpecimen
        )
        .then(function (result) {
          //console.log("specimen archived on server");
          //console.log(result);
          // loader.hide();
        })
        .catch(function (error) {
          alert("L'opération n'a pas été complétée. Veuillez réessayer plus tard svp.");
          console.error(" Error writing firestore document: ", error);
        });
    };

    return {
      specimen,
      errors,
      loading,
      formType,
      handleInput,
      IonList,
      dateTimeModalDismissed,
      setDateTime,
      setPlantation,
      formattedSpecimen,
      checkBeforeSaving,
      confirmDelete,
      showCauseOfDeathOptions,
      codOptions,
      activateDraggingMode,
    };
  },
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonLabel,
    IonItem,
    TasksComponent,
    ObservationsComponent,
    IonModal,
    IonDatetime,
    IonContent,
    DefautsComponent,
    IonToggle,
    IonItemDivider,
    IonButton,
    HabitationsComponent,
    PhotosComponent,
    searchSpeciesComponent,
    IonTextarea,
    IonActionSheet,
    IonInput,
  },
});
</script>