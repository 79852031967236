<template >
  <ion-item-divider>
    <ion-label class="form-divider-title">Défauts</ion-label>
  </ion-item-divider>
  <ion-list v-if="$store.state.formattedSpecimen">
    <span v-if="$store.state.formattedSpecimen.groupSpecific[
        $store.state.userGroupMembership
      ].observations.defauts">
    <ion-item
      v-for="defaut in $store.state.formattedSpecimen.groupSpecific[
        $store.state.userGroupMembership
      ].observations.defauts.list"
      :key="defaut"
      style="margin-bottom: 12px"
    >
     
     <table>
       <tr> <b>défaut:</b> {{ defaut.defaut.name }}</tr> 
     <tr> <b>localisation:</b> {{ defaut.defaut.localisation }} </tr> 
     <tr> <b>type:</b> {{ defaut.defaut.type }}</tr> 
     </table>
      <ion-button
        @click="removeFromDefautsList(defaut)"
        fill="none"
        style="margin-left: 0px"
        slot="end"
        ><ion-icon :icon="closeCircle" color="primary"></ion-icon
      ></ion-button>
    </ion-item></span>
    <ion-item >
      <label style="color:var(--primary)">Ajouter défaut</label>
      <ion-button
        fill="outline"
        @click="
          openPicker(
            2,
            structure.options[1].length,
            structure.options,
            structure.prefix
          )
        "
        >Structure</ion-button
      >
      <!--     <p v-if="picked.animal">picked: {{ picked.animal.text }}</p>
 -->
      <ion-button
        fill="outline"
        slot="end"
        @click="
          openPicker(2, phyto.options[1].length, phyto.options, phyto.prefix)
        "
        >Phytosanitaire</ion-button
      >
      <!--     <p v-if="picked.animal">picked: {{ picked.animal.text }}</p>
 -->
    </ion-item>
  </ion-list>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonItemDivider,
  IonButton,
  pickerController,
  IonList,
  IonIcon
} from "@ionic/vue";
import { defineComponent } from "vue";

import { bookOutline, closeCircle } from "ionicons/icons";
import { useStore } from "vuex";

export default defineComponent({
  name: "DefautsComponent",
  props: {
    title: { type: String, default: "Défauts" },
  },
  setup() {
    const store = useStore();
    const defaultColumnOptions = [
      ["One", "Two", "Three", "Four", "Five"],
    ];

    const structure = {
      options: [
        store.state.groupSpecificTreeModel.defautsLists.defautsLocList,
        store.state.groupSpecificTreeModel.defautsLists.defautsStructureList,
      ],
      prefix: ["Localisation", "Structure"],
    };

    const phyto = {
      options: [
        store.state.groupSpecificTreeModel.defautsLists.defautsLocList,
        store.state.groupSpecificTreeModel.defautsLists.defautsPhytoList,
      ],
      prefix: ["Localisation", "Phytosanitaire"],
    };

    const removeFromDefautsList = (defaut) => {
    //console.log(defaut);
  /*   //console.log(store.state.formattedSpecimen.groupSpecific[
          store.state.userGroupMembership
        ].observations.defauts.list); */
      /* if (
        store.state.formattedSpecimen.groupSpecific[
          store.state.userGroupMembership
        ].observations.defauts.list[defaut.defaut.name]
      ) {
        delete store.state.formattedSpecimen.groupSpecific[
          store.state.userGroupMembership
        ].observations.defauts.list[defaut.defaut.name];
      } */
      for (
        let index = 0;
        index <
        store.state.formattedSpecimen.groupSpecific[
          store.state.userGroupMembership
        ].observations.defauts.list.length;
        index++
      ) {
        const currDefaut =
          store.state.formattedSpecimen.groupSpecific[
            store.state.userGroupMembership
          ].observations.defauts.list[index];
        if (currDefaut.defaut.name == defaut.defaut.name) {
          store.state.formattedSpecimen.groupSpecific[
            store.state.userGroupMembership
          ].observations.defauts.list.splice(index, 1);
          break;
        }
      }
    };

    function getColumns(numColumns, numOptions, columnOptions, prefix) {
      let columns = [];
      for (let i = 0; i < numColumns; i++) {
        columns.push({
          name: `col-${i}`,
          options: getColumnOptions(i, numOptions, columnOptions),
          prefix: prefix[i],
          optionsWidth: "3",
          prefixWidth: "1",
          // suffix: "suffix",
        });
      }

      return columns;
    }

    function getColumnOptions(columnIndex, numOptions, columnOptions) {
      let options = [];
      for (let i = 0; i < numOptions; i++) {
        options.push({
          text: columnOptions[columnIndex][i % numOptions],
          value: i,
        });
      }

      return options;
    }

    async function openPicker(
      numColumns = 1,
      numOptions = 5,
      columnOptions = defaultColumnOptions,
      prefix = "prefix"
    ) {
      //console.log(columnOptions);
      const picker = await pickerController.create({
        cssClass: "ion-picker-modified",
        columns: getColumns(numColumns, numOptions, columnOptions, prefix),
        buttons: [
          {
            text: "Cancel",
            role: "cancel",
          },
          {
            text: "Confirm",
            handler: (value) => {
              //console.log(`Got Value ${JSON.stringify(value)}`);
              let newDefaut = {
                recordedBy: store.state.user.email,
                dateDone: new Date(),
                defaut: {
                  localisation: value["col-0"].text,
                  name: value["col-1"].text,
                  type: prefix[1],
                },
              };
              store.state.formattedSpecimen.groupSpecific[
                store.state.userGroupMembership
              ].observations.defauts.list.push(newDefaut);
            },
          },
        ],
      });

      await picker.present();
    }

    return {
      // store,
      bookOutline,
      IonButton,
      openPicker,
      defaultColumnOptions,
      structure,
      phyto,
      closeCircle,
      removeFromDefautsList,
    };
  },
  components: {
    IonLabel,
    IonItemDivider,
    IonItem,
    IonButton,
    IonList,
    IonIcon
  },
});
</script>