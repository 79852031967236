<template>
  <ion-page>
    <ion-content style="--overflow: hidden;">
      <ion-header v-if="!embedded">
        <ion-toolbar>
          <ion-buttons slot="end">
            <ion-button @click="closeModal">FERMER</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <swiper :modules="modules" :zoom="true" :autoplay="false" :keyboard="true" :pagination="true" :scrollbar="false"
        :initial-slide="$store.state.picturesToSlideInitialSlideIndex" style="height: calc(100% - 72px);">
        <div v-for="item in $store.state.picturesToSlide" :key="item" style="position:absolute;bottom:48px;width:100%;background-color: var(--ion-background-color);height: 180px;">
          <div v-if="item.plantNetResult"> 
              <ion-item lines="none">              
                <ion-label>
                  <b>{{item.plantNetResult.species.scientificNameWithoutAuthor}}</b>
                </ion-label>
                <ion-label slot="end">Certitude: {{Math.round(item.plantNetResult.score * 100)}}%</ion-label>
              </ion-item>
             <!--  <ion-item v-show="vernacularNamesInFrench(item.plantNetResult.gbif.vernacular)" lines="none">
                <ion-label>{{ vernacularNamesInFrench(item.plantNetResult.gbif.vernacular) }}</ion-label>
              </ion-item> -->
              <ion-item lines="none">
                <ion-label style="margin-top:0px;margin-bottom:4px">{{ item.plantNetResult.species.commonNames.join() }}</ion-label>
              </ion-item>
              <ion-item lines="none">                
                <ion-label><i style="font-size: smaller;">{{item.citation}}</i></ion-label>        
                <ion-button size="large" slot="end" color="primary" @click="$emit('choosePlantNetResult',item.plantNetResult);$store.state.showPicturesSlider = false;">Sélectionner</ion-button>
              </ion-item>
            </div>
          </div>
        <swiper-slide v-for="item in $store.state.picturesToSlide" :key="item">
          <div class="swiper-zoom-container" style="height:calc(100vh - 280px)">
            <img :src="item.path">
          </div>
          <div v-if="item.id && $store.state.formattedSpecimen && $store.state.formattedSpecimen.author_uid == $store.state.user.uid " style="position: absolute;bottom:48px;right:12px">
              <ion-button color="danger" @click="confirmDelete(item.id)">Effacer</ion-button>
            </div>
        </swiper-slide>
        
      </swiper>
    </ion-content>
  </ion-page>
</template>

<script>

import { defineComponent } from "vue";
import { useStore } from "vuex";

import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { IonContent, IonPage, IonicSlides, alertController, modalController } from '@ionic/vue';


import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/zoom';

import { usePhoto } from "@/composables/usePhoto";


//console.log("newSpecimen view loaded");
export default defineComponent({
  name: "PicturesSlider",
  props: ["embedded"],
  emits: ["choosePlantNetResult"],
  setup(props, ctx) {
    console.log(props.embedded);
    const store = useStore();
    const {
      deletePicture,
    } = usePhoto();
    const vernacularNamesInFrench = (items) => {
      if(items && items.filter((name)=> name.language == 'fra')[0]){
      console.log(items.filter((name)=> name.language == 'fra')[0]);
      return items.filter((name)=> name.language == 'fra')[0].vernacularName + " (fra)";} else {
        return null
      }
};
const vernacularNamesInEnglish = (items) => {
  if(items && items.filter((name)=> name.language == 'eng')[0]){
  console.log(items.filter((name)=> name.language == 'eng')[0]);
      return items.filter((name)=> name.language == 'eng')[0].vernacularName + " (eng)";}
};
    const closeModal = (plantNetResult) => {
      console.log("closeMOdal");
      console.log(plantNetResult);
      store.state.showPicturesSlider = false;
      if (plantNetResult.species) {
        setTimeout(() => {
          console.log("mmm")
     /*  ctx.emit('choosePlantNetResult',plantNetResult)*/
        }, 2000); 
      } else {
        console.log("ehben");
      }
    };
    const confirmDelete = async (chosenPhoto) => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: "Souhaitez-vous vraiment effacer cette image?",
        message: "Cette action est irréversible!!!",
        buttons: [
          {
            text: "Annuler",
            role: "cancel",
            cssClass: "secondary",
            id: "cancel-button",
            handler: () => {
              //console.log("Confirm Cancel:");
            },
          },
          {
            text: "Effacer",
            id: "confirm-button",
            handler: () => {
              //console.log("Confirm Okay");
              deletePicture(chosenPhoto);
              store.state.showImgModal = false;
              store.state.showPicturesSlider = false;
            },
          },
        ],
      });
      return alert.present();
    };
    console.log("Swiper");
    console.log(Swiper);
    const modules = [Autoplay, Keyboard, Pagination, Scrollbar, Zoom, IonicSlides];

    return {
      modules, closeModal, confirmDelete, vernacularNamesInEnglish, vernacularNamesInFrench
    };
  },
  components: {
    Swiper, SwiperSlide, IonPage, IonContent
  },
});
</script>
